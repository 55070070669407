.related-docs {
    font-size: $size-6;
    padding: $size-5;
    padding-left: $size-2;
    padding-right: 4.5rem;
    background-image: url(../img/card-descarrega-bg.png);
    background-repeat: no-repeat;
    background-position: right 20px top 20px;
    background-color: $white;
    .card-icons & {
        background-image: none;
        padding-left: $size-5;
    }
    .collapsible & {
        background-color: map-get($custom-colors, dark-lightest);
    }

    > * + * {
        margin-top: $size-7;
    }
    li::marker {
        color: transparent;
    }
    a {
        text-decoration: none;
        position: relative;
        &:hover {
            text-decoration: underline;
        }

        &::before {
            /*content: "";
            display: inline-block;
            background-image: url("../img/sprites-svg/doc.svg");
            vertical-align: sub;
            width: 20px;
            height: 22px;
            margin-right: 0.5rem;
            background: no-repeat left center;*/
        }
        &:before {
            content: '';
            display: block;
            position:absolute;
            width: 18px;
            height: 18px;
            left: -22px;
            background-image: url("../img/sprites-svg/link.svg");
            background-repeat: no-repeat;

        }
        &[href$=".pdf"] {
            &::before {
                background-image: url("../img/sprites-svg/pdf.svg");
            }
        }
        &[href$=".doc"],
        &[href$=".docx"] {
            background-image: url("../img/sprites-svg/docu.svg");
        }
        // &[href$=".zip"] {

        // }
    }
}
