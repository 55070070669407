.collapsible {
    .js & {
        .collapsible__title {
            font-size: $size-5;

            button {
                all: inherit;
                border: 0;
                width: 100%;
                margin-top: 0;
                margin-bottom: 0.3125rem;
                font-family: $family-secondary;
                font-size: $size-5;
                font-weight: 600;
                background-color: map-get($custom-colors, green-bg);
                cursor: pointer;

                &:hover,
                &:active,
                &[aria-expanded="true"] {
                    background-color: map-get($custom-colors, darker);
                    color: $white;
                }
                &:focus {
                    outline: 2px dotted map-get($custom-colors, dark) !important;
                    outline-offset: 1px;
                }

                // &:focus {
                //     outline-style: solid;
                //     outline-color: transparent;
                //       box-shadow: 0 0 0 4px $focus-color;
                // }
                svg {
                    margin-top: 5px;
                    display: block;
                }
            }

            + div {
                margin-top: -0.3125rem !important;
                margin-bottom: 0.3125rem;
                padding: $spacing-2;

                & > * + * {
                    margin-top: #{get-size("2")};
                }

                &:not(hidden) {
                    background-color: $white;
                }
            }
        }
    }

    &[data-state="reversed"] {
        .js & {
            .collapsible__title {
                button {
                    background-color: map-get($custom-colors, darker);
                    color: $white;

                    &:hover,
                    &:active,
                    &[aria-expanded="true"] {
                        background-color: map-get($custom-colors, green-bg);
                        color: map-get($custom-colors, darker);
                    }
                }
            }
        }

        button svg {
            width: $size-6;
            margin-left: $size-2;
            display: initial;
        }
    }
    [aria-expanded="true"] svg {
        transform: rotate(0.5turn);
        margin-right: 0;
        margin-left: $size-2;
    }
}
