.scrolltop {
  opacity: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  background: map-get($custom-colors, green-bg);
  width: 12rem;
  display: flex;
  justify-content: center;
  transition: opacity 400ms ease-in-out;
  
  &.visible {
    opacity: 1;
  }
  &:focus,
  &:focus-visible {
    outline-color: #fff !important;
  }
}
.footer-dark {
  padding-bottom: 110px !important;
  @include tablet {
    padding-bottom: $spacing-6 !important; 
  }
}
