.download-doc {
    display: inline-block;
    position: relative;
    font-size: $size-6;
    text-decoration: none;
    color: map-get($custom-colors, darker);
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover span {
      text-decoration: underline;
    }
    .icon {
      position: relative;
      top: -.13rem
    }
    &[data-state="reversed"] {
      font-family: $family-secondary;
      font-size: .875rem;
      font-weight: 600;
      .icon {
        position: relative;
        top: -.25rem
      }
    }
    a {
        text-decoration: none;
        position:relative;
        padding-left: $spacing-4;
        &:before {
                content: '';
                display: block;
                position:absolute;
                width: 18px;
                height: 18px;
                background-image: url("../img/sprites-svg/pdf.svg");
                background-repeat: no-repeat;

        }
    }
}
