.info-centre {
    &.clic:hover {
        .info-title {
            opacity: 0.8;
        }
    }
    .info-title {
        height: 10.5rem;
        position: relative;
        //background-image: url(../img/info-alt-penedes.jpg);
        background-size: cover;
        background-color: #666;

        /*div {
            position: absolute;
            font-size: 1.5rem;
            font-family: $family-secondary;
            color: $white;
            top: 0.875rem;
            left: 1.125rem;
            line-height: 1.3;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

            span {
                display: block;
                font-weight: 700;
            }
        }*/

        & + div {
            font-size: 1.625rem;
            font-weight: 600;
            padding-top: 0.625rem;
        }
        a {
            position: absolute;
            width: 100%;
            height: 100%;
            text-decoration: none;

            font-size: 1.5rem;
            font-family: $family-secondary;
            color: $white;
            padding-top: 0.875rem;
            padding-left: 1.125rem;
            line-height: 1.3;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
            span {
                font-weight: 700;
            }
        }
    }
    ul {
        padding-bottom: 2.25rem;
    }
}
