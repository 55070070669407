.seccio {
    li {
        margin-bottom: $spacing-2;
        h3 {
            margin: $spacing-6 0 $spacing-4 0;
        }
        img {
            float: left;
            margin-right: $spacing-5;
            margin-bottom: $spacing-4;
        }
    }
}