.file.is-fullwidth {
  width: 100%;
  .file-cta {
    width: 100%;
    justify-content: center;
    height: 3rem;
    border: .125rem solid;
    background: transparent;
    .file-label {
      width: auto;
      text-transform: uppercase;
      font-family: $family-secondary;
      font-size: .875rem;
      font-weight: bold;
      padding-right: .5rem;
    }
  }
}