.submenu-home {
  h2 {
    background-color: map-get($custom-colors, darker);
    background-repeat: no-repeat;
    background-position: 96% center;
    color: $white;
    padding: 1.125rem 1.625rem;
    @include desktop {
      background-image: url(../img/que-necessites.png);
      background-size: 26px auto;
    }
    @include widescreen {
      background-size: 36px auto;
    }
    @media (max-width: 1200px) and (min-width: 769px) {
      font-size: 1.25rem!important;
    }
  }
  .column + .column h2 {
    @include desktop {
      background-image: url(../img/logo-inici.png);
    }
  }
  @include desktop {
    position: relative;
    z-index: 10;
  }
  @media (max-width: 769px) {
    .column.has-background-white {
      margin: 0 .75rem;
    }
  }
}

.home .submenu-home {
  @include desktop {
    margin-top: -5.5rem;
    margin-bottom: 6.875rem!important;
  }
}