.cal {
    width: 100%;
    border-spacing: 7px;
    @include mobile {
        border-spacing: 3px;
    }
    border-collapse: separate;
    tbody a,
    .today {
        width: $size-3;
        height: $size-3;

    }
    .today {
        border: 1px solid #fff;
        margin-top: 2px;
        a {
            position: absolute;
            margin-left: -4px;
        }
    }
    tbody a:hover {
        background: map-get($custom-colors, green-light) !important;
    }
    .has-text-dark-light {
        opacity:.6;
    }
}
