.card-agenda {
    //   padding: .375rem .375rem 1rem .375rem;
    padding: 0.375rem;
    .card-agenda-text {
        padding-left: 2.125rem;
        & > div:first-child {
            font-size: 4.375rem;
            font-weight: 500;
            line-height: 1.2;
            & + p {
                line-height: 1.2;
                padding-bottom: 2.4375rem;
            }
        }
    }
}
