.card-icons {
  padding: 1.375rem;
  background-image: url(../img/card-descarrega-bg.png);
  background-repeat: no-repeat;
  background-position: right 20px top 20px;
  background-color: $white;
  &[data-state="alt"] {
    background-color: map-get($custom-colors, dark-lightest);
  }
  &[data-state="ubicacio"] {
    background-image: url(../img/card-ubicacio-bg.png);
    background-position: right 3px top 12px;
  }
  &[data-state="equip"] {
    background-image: url(../img/card-equip.png);
    background-position: right 10px top 15px;
  }
}