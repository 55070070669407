.navigation {
    flex-grow: 1;
    @include tablet {
        flex-grow: initial;
    }
}

.main-nav {
    padding-left: 0;
    font-family: $family-secondary;
    font-weight: 500;
    font-size: 0.875rem;
    li {
        // margin-right: $spacing-1;
        @include tablet {
            margin-right: $spacing-2;
        }
    }
    a {
        text-decoration: none;
    }

    @include tablet {
        display: flex;
        flex-wrap: wrap;
    }

    @include widescreen {
        font-size: $size-6;
    }

    > li + li {
        @include tablet {
            margin-left: 0;
        }

        @include desktop {
            margin-left: 0;
        }
        @include widescreen {
            margin-left: 1rem;
        }
    }

    > li > a {
        @include tablet {
            padding-bottom: $spacing-3;
            color: map-get($custom-colors, darker);
            position: relative;

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0.875rem;
                height: 0;
                width: 0;
                background-color: map-get($custom-colors, blue-bg-1);
                transition: all 200ms ease-in-out;
                .green-header & {
                    background-color: #fff;
                }
            }

            &:hover::after {
                height: 0.25rem;
                width: 100%;
            }
        }
        .green-header & {
            color: #fff;
        }
    }
}

[aria-current="page"] {
    font-weight: bold;
    // color: map-get($custom-colors, blue-bg-1) !important;
    &::after {
        height: 0.25rem !important;
        width: 100% !important;
        background-color: map-get($custom-colors, darker) !important;
        .green-header & {
            background-color: #fff !important;
        }
    }
}

// mobile nav
@include mobile {
    .navigation {
        margin-left: -$spacing-4;
        margin-right: -$spacing-4;
    }
    .main-nav {
        > li {
            padding-right: 0 !important;
            > a {
                font-size: $size-5;
                font-weight: 600;
                display: block;
                background-color: map-get($custom-colors, dark-lightest);
                border-bottom: 1px solid map-get($custom-colors, dark-light);
                padding: $spacing-3 $spacing-4;
                .green-header & {
                    background-color: #98B726;
                    border-bottom: 1px solid #fff;
                }
                + ul {
                    margin-top: 0 !important;
                    margin-bottom: 0;
                    padding: 0 !important;

                    a {
                        display: block;
                        padding: $spacing-3 $spacing-4;
                        font-weight: normal;
                        font-size: $size-7;
                    }
                }
            }
        }
    }
}
