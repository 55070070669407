.site-title {
    display: inline-block;
}
.header {
    &__tools {
        @include touch {
            justify-content: center !important;
            flex-direction: column;
            > * {
                padding: 0 !important;
                margin: .25rem;
            }
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS styles */
            align-items: flex-end;
       }
    }
}
.connected-toggles {
    @include mobile {
        margin: 0 auto;
    }
}