.fluid-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  &[data-state="fixed-height"] {
    padding-top: 28.125rem;
  }
}