.header-media {
  white-space: nowrap;
  
  @include mobile {
    text-align: center;
    margin-bottom: $spacing-2;
  }
  @include tablet {
    border-right: 1px solid map-get($custom-colors, dark-lightest);
    padding-right: .75rem;
    margin-right: .5rem;
  }
  a {
    text-decoration: none;
    .icon {
      width: 20px;
      height: 20px;
    }
  }
  // @include tablet {
  //   display: none;
  // }
  // @include desktop {
  //   display: block;
  // }
  .header-xxss {
    list-style: none;
      li {
        display: inline-block;
      }
  }
}
