/* Override Bulma icon margin styles */
.button .icon:first-child {
    margin: 0 0.5em 0 0 !important;
}

.button {
    font-family: $family-secondary;
    font-size: 0.875rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    background-color: transparent;
    height: 3rem;
    border: 0.125rem solid map-get($custom-colors, darker);
    border-radius: 0;
    &:hover {
        color: $white;
        background-color: map-get($custom-colors, darker);
        border-color: map-get($custom-colors, darker);
    }
    .icon:last-child:not(:first-child) {
        margin-left: 0.5rem;
        margin-right: -0.25rem;
    }
    &.is-secondary {
        text-transform: initial;
        height: 2.375rem;
        padding: 0.25rem 0.875rem;
    }
    &.is-search {
        background-color: $white;
        border-color: #dbdbdb;
        border-width: 1px 1px 1px 0;
    }

    &.fletxa{
        &::after{
            content:'';
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMSAyMCI+CiAgICAgICAgICAgIDxkZWZzIC8+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik05LjMzMDIgMS44MjNjLjIzNC0uMjA4NC40NTQ4LS4yMDg0LjY2MjcgMGw4LjE0NzEgOC4yMDNjLjIzMzkuMjA4NC4yMzM5LjQxNjcgMCAuNjI1bC04LjE0NyA4LjIwMzJjLS4yMDguMjA4My0uNDI4OS4yMDgzLS42NjI4IDBsLS43Nzk2LS43ODEzYy0uMDc4LS4wNzgxLS4xMTctLjE4MjMtLjExNy0uMzEyNXMuMDM5LS4yNDc0LjExNy0uMzUxNWw2LjA0MjEtNi4wNTQ3SDEuM2MtLjMxMTggMC0uNDY3Ny0uMTU2My0uNDY3Ny0uNDY4OFY5Ljc5MTdjMC0uMzEyNS4xNTU5LS40Njg4LjQ2NzctLjQ2ODhoMTMuMjkyN0w4LjU1MDYgMy4yNjgyYy0uMjA3OS0uMjM0My0uMjA3OS0uNDU1NyAwLS42NjRsLjc3OTYtLjc4MTN6IiAvPgo8L3N2Zz4K');
            height: 16px;
            width: 16px;
            position: absolute;
            right: 8px;
        }
        padding-right: 2rem;
        &:hover{
            &::after{
                filter: brightness(0) invert(1);
            }

        }
    }
    a {
        text-decoration: none;
    }
}
