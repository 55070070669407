.footer-media {
  white-space: nowrap;
  padding: 1.25rem 0 2.5625rem 0;
  a {
    text-decoration: none;
    .icon {
      width: 20px;
      height: 20px;
    }
  }
  .footer-xxss {
    padding-left:0;
    list-style: none;
      li {
        display: inline-block;
        padding-right: $spacing-1;
      }
  }
}
