%resetbox {
    border-radius: 0;
    //   border: none;
    box-shadow: none;
    border-width: 1px;
    border-color: #b5b5b5;
    &:hover {
        border-color: #ddd;
    }
}
%resetheight {
    height: 3rem;
}
fieldset {
    margin-bottom: $spacing-2;
}
.label {
    font-family: $family-secondary;
    font-size: 0.875rem;
    margin-bottom: 0.125rem;
    &:not(:last-child) {
        margin: 0.125rem;
    }
}
.input {
    @extend %resetbox;
    @extend %resetheight;
}
.select {
    width: 100%;
    select {
        @extend %resetbox;
        @extend %resetheight;
        width: 100%;
    }
}
.textarea {
    @extend %resetbox;
}
.file-cta {
    border-radius: 0;
    background-color: $white;
    white-space: normal;
    height: auto;
    align-items: first baseline;
    border-color: #b5b5b5;
    &:hover {
        border-color: #ddd;
    }
}
.file-name {
    border-radius: 0;
}

ul.field {
    list-style: none;
    padding-left: 0;
}

.is-checkradio + label::before {
    background-color: #fff;

}
.is-checkradio[type=checkbox]+label::before {
    border-color: #b5b5b5;
    &:hover {
        border-color: #ddd;
    }
}
legend {
    margin-bottom: $spacing-3;
    font-weight: bold;
    font-size: $size-5;
}

.is-checkradio:focus + label::before {
    outline: 2px dotted map-get($custom-colors, dark) !important;
    outline-offset: 1px;
}
::placeholder {
    color: #273d3f!important;
  }

.form-alert,
label.error {
    color: rgb(160, 18, 18);
}
    label.error {font-size:$size-6;}
    input.error {border: 1px rgb(160, 18, 18) solid}


.select-others:not(.is-multiple) {
    height: auto;
}

input.input-others {
    margin-top: $spacing-3;
}

.select:not(.is-multiple):not(.is-loading)::after {
    top: 25px;
}