.tabs-jornades {
    &::after {
        content: '';
        clear: both;
        display: table;
    }
}

// jquery.atAccordionOrTabs.css overwrites
.at-accordion-or-tabs {
    margin: 0;
    section {
        margin-top: 77px;
        h2 {
            font-size: 1.5rem;
        }
    }
}
.at-accordion-or-tabs:not(.at-tabs) > li {
    border: 0;
    margin-bottom: 0;
    > a {
        padding: 1.5625rem 1rem;
        position: relative;
        border-bottom: 1px solid #fff;
        font-size: .875rem;
        font-weight: 500;
        font-family: $family-secondary;
        &::before,
        &::after {
            content: '';
            background-color: map-get($custom-colors, blue-bg-1);
            position: absolute;
            top: 0;
            bottom: 0;
            width: 200%;
        }
        &::before {
            display: block;
            left: -200%;
        }
        &::after {
            display: block;
            right: -200%;
        }
        &:focus {
            background-color: map-get($custom-colors, green-bg);
        }
    }
}
.at-accordion-or-tabs:not(.at-tabs) > li > a {
    background-color: map-get($custom-colors, blue-bg-1);
}
.at-accordion-or-tabs:not(.at-tabs) > li > a.active,
.at-accordion-or-tabs:not(.at-tabs) > li > a:hover {
    color: #fff;
    background: map-get($custom-colors, darker);
    &::before,
    &::after {
        background: inherit;
    }
}
.at-accordion-or-tabs > li > section {
    padding: 0;
    background: transparent;
}
.at-accordion-or-tabs.at-tabs {
    > li {
        margin-left: -4px;
        > a {
            position: relative;
            padding: 1.5625rem 5rem 1.5625rem 1rem;
            font-size: 1.125rem;
            font-weight: 500;
            font-family: $family-secondary;
        }
        &:first-child {
            > a::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 100%;
                left: -3000px;
                width: 3000px;
                background-color: map-get($custom-colors, blue-bg-1);
            }
        }
        &:last-child {
            > a::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -3000px;
                left: 100%;
                width: 3000px;
                background-color: map-get($custom-colors, blue-bg-1);
            }
        }
    }
}
.at-accordion-or-tabs.at-tabs > li > section {
    border: 0;
}
.at-accordion-or-tabs.at-tabs > li > a {
    border: none;
    color: inherit;
    background-color: map-get($custom-colors, blue-bg-1);
    &:focus {
        background-color: map-get($custom-colors, green-bg);
    }
}
.at-accordion-or-tabs.at-tabs > li > a:hover,
.at-accordion-or-tabs.at-tabs > li > a.active {
    color: #fff;
    background: map-get($custom-colors, darker);   
}
.at-accordion-or-tabs.at-tabs .at-tab-one-pixel-fix-left,
.at-accordion-or-tabs.at-tabs .at-tab-one-pixel-fix-right {
    display: none;
}