.equip {
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
    h2 {
        background-color: #273D3F;
        width: fit-content;
        text-transform: uppercase;
    }
    .block-equip {
        gap: 4rem;
        .prof {
            margin-bottom: 15px;
            .other-prof {
                border: 2px solid #273D3F;
                padding: 8px;
            }
            .first-prof {
                border: 10px solid #273D3F;
            }

        }


    }
}
