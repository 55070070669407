.submenu {
  margin-top: 0;
  font-family: $family-secondary;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  //background-color: map-get($custom-colors, blue-bg-1);
    list-style: none;
    padding: 0;

    li {
      a {
        display: block;
        padding: 10px;
        -webkit-text-decoration-color: transparent;
        text-decoration-color: transparent;
        background-color: #78cbd0 !important;
        color: #273d3f !important;
        &:hover,
        &[aria-selected] {
            background-color: #273d3f !important;
            color: #fff !important;
          -webkit-text-decoration-color: inherit !important;
          text-decoration-color: inherit !important;
        }
      }
      &[data-state="selected"] a {
        -webkit-text-decoration-color: inherit;
                text-decoration-color: inherit
      }
    }


  @include tablet {

      display: flex;
      justify-content: space-between;

      li {
        flex-grow: 1;
        cursor: pointer;
        &[data-state="selected"],
        &:hover {
          background-color: map-get($custom-colors, darker);
          color: $white;
          -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
        }

        a {
          padding: 1.625rem;

        }
      }
    }


}
.submenu-tabs {
    .tab-menu {
        position:relative;
        li {
          &:last-child {
              margin-right: -1px;
           }
        }
    &:after {
        content:'';
        background-color: map-get($custom-colors, blue-bg-1);
        display: block;
        height:100%;
        width: 1000%;
        position: absolute;
        top: 0;
        left: -1000%;
    }
    &:before {
      content:'';
      background-color: map-get($custom-colors, blue-bg-1);
        display: block;
        height:100%;
        width: 1000%;
        position: absolute;
        top: 0;
        right: -1000%;
      }
   }
   .tabbed {
    :target {
      display: block;
      position: relative;
      top: -77px;
      //visibility: hidden;
    }
  }
.tab-panel {
    .js & {
        margin-top: 77px;
    }
  }
}
.submenu-basic {
  background-color: map-get($custom-colors, blue-bg-1);
  margin-top: 0;
  .submenu {
    /*&:after {
      content:'';
      background-color: map-get($custom-colors, blue-bg-1);
      display: block;
      height:100%;
      width: 1000%;
      position: absolute;
      top: 0;
      left: -1000%;
  }
  &:before {
    content:'';
    background-color: map-get($custom-colors, blue-bg-1);
      display: block;
      height:100%;
      width: 1000%;
      position: absolute;
      top: 0;
      right: -1000%;
    }*/
    li {
      a.active {
            background-color: #273d3f !important;
            color: #fff !important;
          -webkit-text-decoration-color: inherit !important;
          text-decoration-color: none !important;
      }
    }
  }
}
