.green-header {
    background-color: #98B726;
}
.full-green {
    position: relative;
    &:before {
        @include desktop {
            content: '';
            display: block;
            height: 78px;
            width: 1000%;
            position: absolute;
            top: 0;
            left: -1000%;
            background-color: #98B726;
        }
    }
}

.logo-csapg {
    margin-left: 30px;
    margin-right:50px;
}
.green-banner {
    margin-bottom: 80px;
    position: relative;
    .logo-gran {
        position:absolute;
        bottom:-80px;

    }
}
