.dropdown-lang {
    position: relative;
    border-right: 1px solid map-get($custom-colors, dark-lightest);
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    .dropdown-trigger {
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-size: $size-5;
        white-space: nowrap;
        .icon {
            width: 10px;
        }
        button {
            background-color: transparent;
            border: 0;
            &::after {
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                background-image: url('../img/sprites-svg/avall.svg');
                background-repeat: no-repeat;
                vertical-align: middle;
                margin-left: 5px;
            }
            // &[aria-expanded="true"]::after {
            //     transform: rotate(.5turn);
            // }
        }
    }

    .dropdown-menu {
        // display: none;
        position: absolute;
        z-index: 100;
        right: -150px;
        top: 2.85rem;
        background-color: map-get($custom-colors, blue-bg-1);
        padding: 1.5rem;
        min-width: 25rem;
        @include tablet {
            top: 3.05rem;  
            right: 0;
        }
        @include desktop {
            top: 3.4rem;    
        }
        // .tancar {
        //     position: absolute;
        //     right: 1rem;
        //     top: 1rem;
        // }
    }
}

// .dropdown-lang.is-active {
//     .dropdown-menu {
//         display: block;
//     }
// }
