@charset "UTF-8";
@import url(../../node_modules/modern-css-reset/src/reset.css);
@import url(../css/jquery.atAccordionOrTabs.css);
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: url(../../fonts/Barlow-italic-400.ttf) format("truetype"), url(../../fonts/Barlow-italic-400.woff) format("woff"), url(../../fonts/Barlow-italic-400.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Barlow-normal-400.eot);
  src: local("Barlow"), url(../../fonts/Barlow-normal-400.ttf) format("truetype"), url(../../fonts/Barlow-normal-400.svg#Barlow) format("svg"), url(../../fonts/Barlow-normal-400.eot?#iefix) format("embedded-opentype"), url(../../fonts/Barlow-normal-400.woff) format("woff"), url(../../fonts/Barlow-normal-400.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: url(../../fonts/Barlow-normal-500.ttf) format("truetype"), url(../../fonts/Barlow-normal-500.woff) format("woff"), url(../../fonts/Barlow-normal-500.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url(../../fonts/Barlow-normal-600.ttf) format("truetype"), url(../../fonts/Barlow-normal-600.woff) format("woff"), url(../../fonts/Barlow-normal-600.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Barlow-normal-700.ttf) format("truetype"), url(../../fonts/Barlow-normal-700.woff) format("woff"), url(../../fonts/Barlow-normal-700.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Noto-Sans-JP-normal-400.eot);
  src: local("Noto Sans JP"), url(../../fonts/Noto-Sans-JP-normal-400.woff) format("woff"), url(../../fonts/Noto-Sans-JP-normal-400.eot?#iefix) format("embedded-opentype"), url(../../fonts/Noto-Sans-JP-normal-400.svg#NotoSansJP) format("svg"), url(../../fonts/Noto-Sans-JP-normal-400.otf) format("opentype"), url(../../fonts/Noto-Sans-JP-normal-400.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url(../../fonts/Noto-Sans-JP-normal-500.woff) format("woff"), url(../../fonts/Noto-Sans-JP-normal-500.otf) format("opentype"), url(../../fonts/Noto-Sans-JP-normal-500.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Noto-Sans-JP-normal-700.woff) format("woff"), url(../../fonts/Noto-Sans-JP-normal-700.otf) format("opentype"), url(../../fonts/Noto-Sans-JP-normal-700.woff2) format("woff2");
  font-display: swap; }

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
/**
 * BASE SIZE
 * All calculations are based on this. It’s recommended that
 * you keep it at 1rem because that is the root font size. You
 * can set it to whatever you like and whatever unit you like.
 */
/**
 * SIZE SCALE
 * This is a Major Third scale that powers all the utilities that
 * it is relevant for (font-size, margin, padding). All items are
 * calcuated off the base size, so change that and cascade across
 * your whole project.
 */
/*Typographic scale*/
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 1.75rem 1.75rem;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }

.hamburger-box {
  width: 1.5rem;
  height: 19px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 1.5rem;
    height: 3px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 8px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(-45deg); }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/fonts/slick.eot");
  src: url("/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/fonts/slick.woff") format("woff"), url("/fonts/slick.ttf") format("truetype"), url("/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
  Slider
*/
.slick-loading .slick-list {
  background: #fff url("/media/css/img/ajax-loader.gif") center center no-repeat; }

/*=================================
  Previous and Next icon buttons
==================================*/
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  border: none;
  transform: translate(0, -50%); }
  .slick-prev:hover .slick-prev-icon,
  .slick-prev:hover .slick-next-icon, .slick-prev:focus .slick-prev-icon,
  .slick-prev:focus .slick-next-icon,
  .slick-next:hover .slick-prev-icon,
  .slick-next:hover .slick-next-icon,
  .slick-next:focus .slick-prev-icon,
  .slick-next:focus .slick-next-icon {
    opacity: 1; }
  .slick-prev:focus,
  .slick-next:focus {
    top: calc(50% - 1px); }
    .slick-prev:focus .slick-prev-icon,
    .slick-prev:focus .slick-next-icon,
    .slick-next:focus .slick-prev-icon,
    .slick-next:focus .slick-next-icon {
      color: orange;
      font-size: 28px;
      margin-left: -2px; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    cursor: default; }
    .slick-prev.slick-disabled .slick-prev-icon,
    .slick-prev.slick-disabled .slick-next-icon,
    .slick-next.slick-disabled .slick-prev-icon,
    .slick-next.slick-disabled .slick-next-icon {
      opacity: 0.25; }
  .slick-prev .slick-prev-icon,
  .slick-prev .slick-next-icon,
  .slick-next .slick-prev-icon,
  .slick-next .slick-next-icon {
    display: block;
    color: black;
    opacity: 0.75;
    font-family: "slick";
    font-size: 24px;
    line-height: 1; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev .slick-prev-icon:before {
    content: "←"; }
    [dir="rtl"] .slick-prev .slick-prev-icon:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next .slick-next-icon:before {
    content: "→"; }
    [dir="rtl"] .slick-next .slick-next-icon:before {
      content: "←"; }

/*==========================
  Slide navigation dots
===========================*/
.slick-slider {
  margin-bottom: 30px; }
  .slick-slider.slick-dotted {
    margin-bottom: 60px; }

.slick-dots {
  position: absolute;
  bottom: -30px;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0; }
    .slick-dots li button {
      display: block;
      height: 20px;
      width: 20px;
      margin-top: -4px;
      margin-left: -4px;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      border: 0;
      background: transparent;
      cursor: pointer; }
      .slick-dots li button:hover .slick-dot-icon, .slick-dots li button:focus .slick-dot-icon {
        opacity: 1; }
      .slick-dots li button:focus .slick-dot-icon:before {
        color: orange; }
      .slick-dots li button .slick-dot-icon {
        color: black;
        opacity: 0.25; }
        .slick-dots li button .slick-dot-icon:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "•";
          font-family: "slick";
          font-size: 12px;
          line-height: 1;
          text-align: center;
          transition: all .05s linear; }
    .slick-dots li.slick-active button:focus .slick-dot-icon {
      color: orange;
      opacity: 1; }
    .slick-dots li.slick-active button .slick-dot-icon {
      color: black;
      opacity: 1; }
      .slick-dots li.slick-active button .slick-dot-icon:before {
        margin-top: -3px;
        margin-left: -2px;
        font-size: 18px; }

/**
 Improved .sr-only class by ffoodd: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
 */
.slick-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

/*===========================
  Pause/play icon button
============================*/
.slick-autoplay-toggle-button {
  position: absolute;
  left: 5px;
  bottom: -32px;
  z-index: 10;
  opacity: 0.75;
  background: none;
  border: 0;
  cursor: pointer;
  color: black; }
  .slick-autoplay-toggle-button:hover, .slick-autoplay-toggle-button:focus {
    opacity: 1; }
  .slick-autoplay-toggle-button:focus {
    color: orange; }
  .slick-autoplay-toggle-button .slick-pause-icon:before {
    content: "⏸";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    text-align: center; }
  .slick-autoplay-toggle-button .slick-play-icon:before {
    content: "▶";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    text-align: center; }

/* Bulma Utilities */
/* Bulma Utilities */
.button, .input, .textarea, .select select, .file-cta,
.file-name, .pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top; }
  .button:focus, .input:focus, .textarea:focus, .select select:focus, .file-cta:focus,
  .file-name:focus, .pagination-previous:focus,
  .pagination-next:focus,
  .pagination-link:focus,
  .pagination-ellipsis:focus, .is-focused.button, .is-focused.input, .is-focused.textarea, .select select.is-focused, .is-focused.file-cta,
  .is-focused.file-name, .is-focused.pagination-previous,
  .is-focused.pagination-next,
  .is-focused.pagination-link,
  .is-focused.pagination-ellipsis, .button:active, .input:active, .textarea:active, .select select:active, .file-cta:active,
  .file-name:active, .pagination-previous:active,
  .pagination-next:active,
  .pagination-link:active,
  .pagination-ellipsis:active, .is-active.button, .is-active.input, .is-active.textarea, .select select.is-active, .is-active.file-cta,
  .is-active.file-name, .is-active.pagination-previous,
  .is-active.pagination-next,
  .is-active.pagination-link,
  .is-active.pagination-ellipsis {
    outline: none; }
  .button[disabled], .input[disabled], .textarea[disabled], .select select[disabled], .file-cta[disabled],
  .file-name[disabled], .pagination-previous[disabled],
  .pagination-next[disabled],
  .pagination-link[disabled],
  .pagination-ellipsis[disabled],
  fieldset[disabled] .button,
  fieldset[disabled] .input,
  fieldset[disabled] .textarea,
  fieldset[disabled] .select select,
  .select fieldset[disabled] select,
  fieldset[disabled] .file-cta,
  fieldset[disabled] .file-name,
  fieldset[disabled] .pagination-previous,
  fieldset[disabled] .pagination-next,
  fieldset[disabled] .pagination-link,
  fieldset[disabled] .pagination-ellipsis {
    cursor: not-allowed; }

.button, .file, .pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.select:not(.is-multiple):not(.is-loading)::after {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.625em; }

.table:not(:last-child), .table-container:not(:last-child), .level:not(:last-child), .pagination:not(:last-child) {
  margin-bottom: 1.125rem; }

.button.is-loading::after, .select.is-loading::after, .control.is-loading::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em; }

/* Bulma Base */
/* Bulma Elements */
.button {
  background-color: white;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap; }
  .button strong {
    color: inherit; }
  .button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
    height: 1.5em;
    width: 1.5em; }
  .button .icon:first-child:not(:last-child) {
    margin-left: calc(-0.5em - 1px);
    margin-right: 0.25em; }
  .button .icon:last-child:not(:first-child) {
    margin-left: 0.25em;
    margin-right: calc(-0.5em - 1px); }
  .button .icon:first-child:last-child {
    margin-left: calc(-0.5em - 1px);
    margin-right: calc(-0.5em - 1px); }
  .button:hover, .button.is-hovered {
    border-color: #b5b5b5;
    color: #363636; }
  .button:focus, .button.is-focused {
    border-color: #485fc7;
    color: #363636; }
    .button:focus:not(:active), .button.is-focused:not(:active) {
      box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25); }
  .button:active, .button.is-active {
    border-color: #4a4a4a;
    color: #363636; }
  .button.is-text {
    background-color: transparent;
    border-color: transparent;
    color: #4a4a4a;
    text-decoration: underline; }
    .button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
      background-color: transparent;
      color: #363636; }
    .button.is-text:active, .button.is-text.is-active {
      background-color: rgba(0, 0, 0, 0);
      color: #363636; }
    .button.is-text[disabled],
    fieldset[disabled] .button.is-text {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none; }
  .button.is-ghost {
    background: none;
    border-color: transparent;
    color: #485fc7;
    text-decoration: none; }
    .button.is-ghost:hover, .button.is-ghost.is-hovered {
      color: #485fc7;
      text-decoration: underline; }
  .button.is-white {
    background-color: white;
    border-color: transparent;
    color: #0a0a0a; }
    .button.is-white:hover, .button.is-white.is-hovered {
      background-color: #f9f9f9;
      border-color: transparent;
      color: #0a0a0a; }
    .button.is-white:focus, .button.is-white.is-focused {
      border-color: transparent;
      color: #0a0a0a; }
      .button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
    .button.is-white:active, .button.is-white.is-active {
      background-color: #f2f2f2;
      border-color: transparent;
      color: #0a0a0a; }
    .button.is-white[disabled],
    fieldset[disabled] .button.is-white {
      background-color: white;
      border-color: transparent;
      box-shadow: none; }
    .button.is-white.is-inverted {
      background-color: #0a0a0a;
      color: white; }
      .button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
        background-color: black; }
      .button.is-white.is-inverted[disabled],
      fieldset[disabled] .button.is-white.is-inverted {
        background-color: #0a0a0a;
        border-color: transparent;
        box-shadow: none;
        color: white; }
    .button.is-white.is-loading::after {
      border-color: transparent transparent #0a0a0a #0a0a0a !important; }
    .button.is-white.is-outlined {
      background-color: transparent;
      border-color: white;
      color: white; }
      .button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
        background-color: white;
        border-color: white;
        color: #0a0a0a; }
      .button.is-white.is-outlined.is-loading::after {
        border-color: transparent transparent white white !important; }
      .button.is-white.is-outlined.is-loading:hover::after, .button.is-white.is-outlined.is-loading.is-hovered::after, .button.is-white.is-outlined.is-loading:focus::after, .button.is-white.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #0a0a0a #0a0a0a !important; }
      .button.is-white.is-outlined[disabled],
      fieldset[disabled] .button.is-white.is-outlined {
        background-color: transparent;
        border-color: white;
        box-shadow: none;
        color: white; }
    .button.is-white.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #0a0a0a;
      color: #0a0a0a; }
      .button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
        background-color: #0a0a0a;
        color: white; }
      .button.is-white.is-inverted.is-outlined.is-loading:hover::after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-white.is-inverted.is-outlined.is-loading:focus::after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent white white !important; }
      .button.is-white.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-white.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #0a0a0a;
        box-shadow: none;
        color: #0a0a0a; }
  .button.is-black {
    background-color: #0a0a0a;
    border-color: transparent;
    color: white; }
    .button.is-black:hover, .button.is-black.is-hovered {
      background-color: #040404;
      border-color: transparent;
      color: white; }
    .button.is-black:focus, .button.is-black.is-focused {
      border-color: transparent;
      color: white; }
      .button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
    .button.is-black:active, .button.is-black.is-active {
      background-color: black;
      border-color: transparent;
      color: white; }
    .button.is-black[disabled],
    fieldset[disabled] .button.is-black {
      background-color: #0a0a0a;
      border-color: transparent;
      box-shadow: none; }
    .button.is-black.is-inverted {
      background-color: white;
      color: #0a0a0a; }
      .button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-black.is-inverted[disabled],
      fieldset[disabled] .button.is-black.is-inverted {
        background-color: white;
        border-color: transparent;
        box-shadow: none;
        color: #0a0a0a; }
    .button.is-black.is-loading::after {
      border-color: transparent transparent white white !important; }
    .button.is-black.is-outlined {
      background-color: transparent;
      border-color: #0a0a0a;
      color: #0a0a0a; }
      .button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
        background-color: #0a0a0a;
        border-color: #0a0a0a;
        color: white; }
      .button.is-black.is-outlined.is-loading::after {
        border-color: transparent transparent #0a0a0a #0a0a0a !important; }
      .button.is-black.is-outlined.is-loading:hover::after, .button.is-black.is-outlined.is-loading.is-hovered::after, .button.is-black.is-outlined.is-loading:focus::after, .button.is-black.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent white white !important; }
      .button.is-black.is-outlined[disabled],
      fieldset[disabled] .button.is-black.is-outlined {
        background-color: transparent;
        border-color: #0a0a0a;
        box-shadow: none;
        color: #0a0a0a; }
    .button.is-black.is-inverted.is-outlined {
      background-color: transparent;
      border-color: white;
      color: white; }
      .button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
        background-color: white;
        color: #0a0a0a; }
      .button.is-black.is-inverted.is-outlined.is-loading:hover::after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-black.is-inverted.is-outlined.is-loading:focus::after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #0a0a0a #0a0a0a !important; }
      .button.is-black.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-black.is-inverted.is-outlined {
        background-color: transparent;
        border-color: white;
        box-shadow: none;
        color: white; }
  .button.is-light {
    background-color: whitesmoke;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-light:hover, .button.is-light.is-hovered {
      background-color: #eeeeee;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-light:focus, .button.is-light.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
    .button.is-light:active, .button.is-light.is-active {
      background-color: #e8e8e8;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-light[disabled],
    fieldset[disabled] .button.is-light {
      background-color: whitesmoke;
      border-color: transparent;
      box-shadow: none; }
    .button.is-light.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: whitesmoke; }
      .button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-light.is-inverted[disabled],
      fieldset[disabled] .button.is-light.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: whitesmoke; }
    .button.is-light.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-light.is-outlined {
      background-color: transparent;
      border-color: whitesmoke;
      color: whitesmoke; }
      .button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
        background-color: whitesmoke;
        border-color: whitesmoke;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-light.is-outlined.is-loading::after {
        border-color: transparent transparent whitesmoke whitesmoke !important; }
      .button.is-light.is-outlined.is-loading:hover::after, .button.is-light.is-outlined.is-loading.is-hovered::after, .button.is-light.is-outlined.is-loading:focus::after, .button.is-light.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-light.is-outlined[disabled],
      fieldset[disabled] .button.is-light.is-outlined {
        background-color: transparent;
        border-color: whitesmoke;
        box-shadow: none;
        color: whitesmoke; }
    .button.is-light.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: whitesmoke; }
      .button.is-light.is-inverted.is-outlined.is-loading:hover::after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-light.is-inverted.is-outlined.is-loading:focus::after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent whitesmoke whitesmoke !important; }
      .button.is-light.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-light.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
  .button.is-dark {
    background-color: #4f6567;
    border-color: transparent;
    color: #fff; }
    .button.is-dark:hover, .button.is-dark.is-hovered {
      background-color: #495e60;
      border-color: transparent;
      color: #fff; }
    .button.is-dark:focus, .button.is-dark.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(79, 101, 103, 0.25); }
    .button.is-dark:active, .button.is-dark.is-active {
      background-color: #445759;
      border-color: transparent;
      color: #fff; }
    .button.is-dark[disabled],
    fieldset[disabled] .button.is-dark {
      background-color: #4f6567;
      border-color: transparent;
      box-shadow: none; }
    .button.is-dark.is-inverted {
      background-color: #fff;
      color: #4f6567; }
      .button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-dark.is-inverted[disabled],
      fieldset[disabled] .button.is-dark.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #4f6567; }
    .button.is-dark.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-dark.is-outlined {
      background-color: transparent;
      border-color: #4f6567;
      color: #4f6567; }
      .button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
        background-color: #4f6567;
        border-color: #4f6567;
        color: #fff; }
      .button.is-dark.is-outlined.is-loading::after {
        border-color: transparent transparent #4f6567 #4f6567 !important; }
      .button.is-dark.is-outlined.is-loading:hover::after, .button.is-dark.is-outlined.is-loading.is-hovered::after, .button.is-dark.is-outlined.is-loading:focus::after, .button.is-dark.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-dark.is-outlined[disabled],
      fieldset[disabled] .button.is-dark.is-outlined {
        background-color: transparent;
        border-color: #4f6567;
        box-shadow: none;
        color: #4f6567; }
    .button.is-dark.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #4f6567; }
      .button.is-dark.is-inverted.is-outlined.is-loading:hover::after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-dark.is-inverted.is-outlined.is-loading:focus::after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #4f6567 #4f6567 !important; }
      .button.is-dark.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-dark.is-light {
      background-color: #f3f6f6;
      color: #6a888b; }
      .button.is-dark.is-light:hover, .button.is-dark.is-light.is-hovered {
        background-color: #ecf0f1;
        border-color: transparent;
        color: #6a888b; }
      .button.is-dark.is-light:active, .button.is-dark.is-light.is-active {
        background-color: #e5ebeb;
        border-color: transparent;
        color: #6a888b; }
  .button.is-primary {
    background-color: #009ba9;
    border-color: transparent;
    color: #fff; }
    .button.is-primary:hover, .button.is-primary.is-hovered {
      background-color: #008f9c;
      border-color: transparent;
      color: #fff; }
    .button.is-primary:focus, .button.is-primary.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(0, 155, 169, 0.25); }
    .button.is-primary:active, .button.is-primary.is-active {
      background-color: #008490;
      border-color: transparent;
      color: #fff; }
    .button.is-primary[disabled],
    fieldset[disabled] .button.is-primary {
      background-color: #009ba9;
      border-color: transparent;
      box-shadow: none; }
    .button.is-primary.is-inverted {
      background-color: #fff;
      color: #009ba9; }
      .button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-primary.is-inverted[disabled],
      fieldset[disabled] .button.is-primary.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #009ba9; }
    .button.is-primary.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-primary.is-outlined {
      background-color: transparent;
      border-color: #009ba9;
      color: #009ba9; }
      .button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
        background-color: #009ba9;
        border-color: #009ba9;
        color: #fff; }
      .button.is-primary.is-outlined.is-loading::after {
        border-color: transparent transparent #009ba9 #009ba9 !important; }
      .button.is-primary.is-outlined.is-loading:hover::after, .button.is-primary.is-outlined.is-loading.is-hovered::after, .button.is-primary.is-outlined.is-loading:focus::after, .button.is-primary.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-primary.is-outlined[disabled],
      fieldset[disabled] .button.is-primary.is-outlined {
        background-color: transparent;
        border-color: #009ba9;
        box-shadow: none;
        color: #009ba9; }
    .button.is-primary.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #009ba9; }
      .button.is-primary.is-inverted.is-outlined.is-loading:hover::after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-primary.is-inverted.is-outlined.is-loading:focus::after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #009ba9 #009ba9 !important; }
      .button.is-primary.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-primary.is-light {
      background-color: #ebfdff;
      color: #00bbcc; }
      .button.is-primary.is-light:hover, .button.is-primary.is-light.is-hovered {
        background-color: #defcff;
        border-color: transparent;
        color: #00bbcc; }
      .button.is-primary.is-light:active, .button.is-primary.is-light.is-active {
        background-color: #d1fbff;
        border-color: transparent;
        color: #00bbcc; }
  .button.is-link {
    background-color: #485fc7;
    border-color: transparent;
    color: #fff; }
    .button.is-link:hover, .button.is-link.is-hovered {
      background-color: #3e56c4;
      border-color: transparent;
      color: #fff; }
    .button.is-link:focus, .button.is-link.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25); }
    .button.is-link:active, .button.is-link.is-active {
      background-color: #3a51bb;
      border-color: transparent;
      color: #fff; }
    .button.is-link[disabled],
    fieldset[disabled] .button.is-link {
      background-color: #485fc7;
      border-color: transparent;
      box-shadow: none; }
    .button.is-link.is-inverted {
      background-color: #fff;
      color: #485fc7; }
      .button.is-link.is-inverted:hover, .button.is-link.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-link.is-inverted[disabled],
      fieldset[disabled] .button.is-link.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #485fc7; }
    .button.is-link.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-link.is-outlined {
      background-color: transparent;
      border-color: #485fc7;
      color: #485fc7; }
      .button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
        background-color: #485fc7;
        border-color: #485fc7;
        color: #fff; }
      .button.is-link.is-outlined.is-loading::after {
        border-color: transparent transparent #485fc7 #485fc7 !important; }
      .button.is-link.is-outlined.is-loading:hover::after, .button.is-link.is-outlined.is-loading.is-hovered::after, .button.is-link.is-outlined.is-loading:focus::after, .button.is-link.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-link.is-outlined[disabled],
      fieldset[disabled] .button.is-link.is-outlined {
        background-color: transparent;
        border-color: #485fc7;
        box-shadow: none;
        color: #485fc7; }
    .button.is-link.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #485fc7; }
      .button.is-link.is-inverted.is-outlined.is-loading:hover::after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-link.is-inverted.is-outlined.is-loading:focus::after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #485fc7 #485fc7 !important; }
      .button.is-link.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-link.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-link.is-light {
      background-color: #eff1fa;
      color: #3850b7; }
      .button.is-link.is-light:hover, .button.is-link.is-light.is-hovered {
        background-color: #e6e9f7;
        border-color: transparent;
        color: #3850b7; }
      .button.is-link.is-light:active, .button.is-link.is-light.is-active {
        background-color: #dce0f4;
        border-color: transparent;
        color: #3850b7; }
  .button.is-info {
    background-color: #3e8ed0;
    border-color: transparent;
    color: #fff; }
    .button.is-info:hover, .button.is-info.is-hovered {
      background-color: #3488ce;
      border-color: transparent;
      color: #fff; }
    .button.is-info:focus, .button.is-info.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25); }
    .button.is-info:active, .button.is-info.is-active {
      background-color: #3082c5;
      border-color: transparent;
      color: #fff; }
    .button.is-info[disabled],
    fieldset[disabled] .button.is-info {
      background-color: #3e8ed0;
      border-color: transparent;
      box-shadow: none; }
    .button.is-info.is-inverted {
      background-color: #fff;
      color: #3e8ed0; }
      .button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-info.is-inverted[disabled],
      fieldset[disabled] .button.is-info.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #3e8ed0; }
    .button.is-info.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-info.is-outlined {
      background-color: transparent;
      border-color: #3e8ed0;
      color: #3e8ed0; }
      .button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
        background-color: #3e8ed0;
        border-color: #3e8ed0;
        color: #fff; }
      .button.is-info.is-outlined.is-loading::after {
        border-color: transparent transparent #3e8ed0 #3e8ed0 !important; }
      .button.is-info.is-outlined.is-loading:hover::after, .button.is-info.is-outlined.is-loading.is-hovered::after, .button.is-info.is-outlined.is-loading:focus::after, .button.is-info.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-info.is-outlined[disabled],
      fieldset[disabled] .button.is-info.is-outlined {
        background-color: transparent;
        border-color: #3e8ed0;
        box-shadow: none;
        color: #3e8ed0; }
    .button.is-info.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #3e8ed0; }
      .button.is-info.is-inverted.is-outlined.is-loading:hover::after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-info.is-inverted.is-outlined.is-loading:focus::after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #3e8ed0 #3e8ed0 !important; }
      .button.is-info.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-info.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-info.is-light {
      background-color: #eff5fb;
      color: #296fa8; }
      .button.is-info.is-light:hover, .button.is-info.is-light.is-hovered {
        background-color: #e4eff9;
        border-color: transparent;
        color: #296fa8; }
      .button.is-info.is-light:active, .button.is-info.is-light.is-active {
        background-color: #dae9f6;
        border-color: transparent;
        color: #296fa8; }
  .button.is-success {
    background-color: #48c78e;
    border-color: transparent;
    color: #fff; }
    .button.is-success:hover, .button.is-success.is-hovered {
      background-color: #3ec487;
      border-color: transparent;
      color: #fff; }
    .button.is-success:focus, .button.is-success.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(72, 199, 142, 0.25); }
    .button.is-success:active, .button.is-success.is-active {
      background-color: #3abb81;
      border-color: transparent;
      color: #fff; }
    .button.is-success[disabled],
    fieldset[disabled] .button.is-success {
      background-color: #48c78e;
      border-color: transparent;
      box-shadow: none; }
    .button.is-success.is-inverted {
      background-color: #fff;
      color: #48c78e; }
      .button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-success.is-inverted[disabled],
      fieldset[disabled] .button.is-success.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #48c78e; }
    .button.is-success.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-success.is-outlined {
      background-color: transparent;
      border-color: #48c78e;
      color: #48c78e; }
      .button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
        background-color: #48c78e;
        border-color: #48c78e;
        color: #fff; }
      .button.is-success.is-outlined.is-loading::after {
        border-color: transparent transparent #48c78e #48c78e !important; }
      .button.is-success.is-outlined.is-loading:hover::after, .button.is-success.is-outlined.is-loading.is-hovered::after, .button.is-success.is-outlined.is-loading:focus::after, .button.is-success.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-success.is-outlined[disabled],
      fieldset[disabled] .button.is-success.is-outlined {
        background-color: transparent;
        border-color: #48c78e;
        box-shadow: none;
        color: #48c78e; }
    .button.is-success.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #48c78e; }
      .button.is-success.is-inverted.is-outlined.is-loading:hover::after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-success.is-inverted.is-outlined.is-loading:focus::after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #48c78e #48c78e !important; }
      .button.is-success.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-success.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-success.is-light {
      background-color: #effaf5;
      color: #257953; }
      .button.is-success.is-light:hover, .button.is-success.is-light.is-hovered {
        background-color: #e6f7ef;
        border-color: transparent;
        color: #257953; }
      .button.is-success.is-light:active, .button.is-success.is-light.is-active {
        background-color: #dcf4e9;
        border-color: transparent;
        color: #257953; }
  .button.is-warning {
    background-color: #ffe08a;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-warning:hover, .button.is-warning.is-hovered {
      background-color: #ffdc7d;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-warning:focus, .button.is-warning.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25); }
    .button.is-warning:active, .button.is-warning.is-active {
      background-color: #ffd970;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-warning[disabled],
    fieldset[disabled] .button.is-warning {
      background-color: #ffe08a;
      border-color: transparent;
      box-shadow: none; }
    .button.is-warning.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #ffe08a; }
      .button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-inverted[disabled],
      fieldset[disabled] .button.is-warning.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #ffe08a; }
    .button.is-warning.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-warning.is-outlined {
      background-color: transparent;
      border-color: #ffe08a;
      color: #ffe08a; }
      .button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
        background-color: #ffe08a;
        border-color: #ffe08a;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-outlined.is-loading::after {
        border-color: transparent transparent #ffe08a #ffe08a !important; }
      .button.is-warning.is-outlined.is-loading:hover::after, .button.is-warning.is-outlined.is-loading.is-hovered::after, .button.is-warning.is-outlined.is-loading:focus::after, .button.is-warning.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-warning.is-outlined[disabled],
      fieldset[disabled] .button.is-warning.is-outlined {
        background-color: transparent;
        border-color: #ffe08a;
        box-shadow: none;
        color: #ffe08a; }
    .button.is-warning.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffe08a; }
      .button.is-warning.is-inverted.is-outlined.is-loading:hover::after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-warning.is-inverted.is-outlined.is-loading:focus::after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #ffe08a #ffe08a !important; }
      .button.is-warning.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-warning.is-light {
      background-color: #fffaeb;
      color: #946c00; }
      .button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
        background-color: #fff6de;
        border-color: transparent;
        color: #946c00; }
      .button.is-warning.is-light:active, .button.is-warning.is-light.is-active {
        background-color: #fff3d1;
        border-color: transparent;
        color: #946c00; }
  .button.is-danger {
    background-color: #f14668;
    border-color: transparent;
    color: #fff; }
    .button.is-danger:hover, .button.is-danger.is-hovered {
      background-color: #f03a5f;
      border-color: transparent;
      color: #fff; }
    .button.is-danger:focus, .button.is-danger.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25); }
    .button.is-danger:active, .button.is-danger.is-active {
      background-color: #ef2e55;
      border-color: transparent;
      color: #fff; }
    .button.is-danger[disabled],
    fieldset[disabled] .button.is-danger {
      background-color: #f14668;
      border-color: transparent;
      box-shadow: none; }
    .button.is-danger.is-inverted {
      background-color: #fff;
      color: #f14668; }
      .button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-danger.is-inverted[disabled],
      fieldset[disabled] .button.is-danger.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #f14668; }
    .button.is-danger.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-danger.is-outlined {
      background-color: transparent;
      border-color: #f14668;
      color: #f14668; }
      .button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
        background-color: #f14668;
        border-color: #f14668;
        color: #fff; }
      .button.is-danger.is-outlined.is-loading::after {
        border-color: transparent transparent #f14668 #f14668 !important; }
      .button.is-danger.is-outlined.is-loading:hover::after, .button.is-danger.is-outlined.is-loading.is-hovered::after, .button.is-danger.is-outlined.is-loading:focus::after, .button.is-danger.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-danger.is-outlined[disabled],
      fieldset[disabled] .button.is-danger.is-outlined {
        background-color: transparent;
        border-color: #f14668;
        box-shadow: none;
        color: #f14668; }
    .button.is-danger.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #f14668; }
      .button.is-danger.is-inverted.is-outlined.is-loading:hover::after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-danger.is-inverted.is-outlined.is-loading:focus::after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #f14668 #f14668 !important; }
      .button.is-danger.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-danger.is-light {
      background-color: #feecf0;
      color: #cc0f35; }
      .button.is-danger.is-light:hover, .button.is-danger.is-light.is-hovered {
        background-color: #fde0e6;
        border-color: transparent;
        color: #cc0f35; }
      .button.is-danger.is-light:active, .button.is-danger.is-light.is-active {
        background-color: #fcd4dc;
        border-color: transparent;
        color: #cc0f35; }
  .button.is-primary-brand-1 {
    background-color: #009ba9;
    border-color: transparent;
    color: #fff; }
    .button.is-primary-brand-1:hover, .button.is-primary-brand-1.is-hovered {
      background-color: #008f9c;
      border-color: transparent;
      color: #fff; }
    .button.is-primary-brand-1:focus, .button.is-primary-brand-1.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-primary-brand-1:focus:not(:active), .button.is-primary-brand-1.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(0, 155, 169, 0.25); }
    .button.is-primary-brand-1:active, .button.is-primary-brand-1.is-active {
      background-color: #008490;
      border-color: transparent;
      color: #fff; }
    .button.is-primary-brand-1[disabled],
    fieldset[disabled] .button.is-primary-brand-1 {
      background-color: #009ba9;
      border-color: transparent;
      box-shadow: none; }
    .button.is-primary-brand-1.is-inverted {
      background-color: #fff;
      color: #009ba9; }
      .button.is-primary-brand-1.is-inverted:hover, .button.is-primary-brand-1.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-primary-brand-1.is-inverted[disabled],
      fieldset[disabled] .button.is-primary-brand-1.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #009ba9; }
    .button.is-primary-brand-1.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-primary-brand-1.is-outlined {
      background-color: transparent;
      border-color: #009ba9;
      color: #009ba9; }
      .button.is-primary-brand-1.is-outlined:hover, .button.is-primary-brand-1.is-outlined.is-hovered, .button.is-primary-brand-1.is-outlined:focus, .button.is-primary-brand-1.is-outlined.is-focused {
        background-color: #009ba9;
        border-color: #009ba9;
        color: #fff; }
      .button.is-primary-brand-1.is-outlined.is-loading::after {
        border-color: transparent transparent #009ba9 #009ba9 !important; }
      .button.is-primary-brand-1.is-outlined.is-loading:hover::after, .button.is-primary-brand-1.is-outlined.is-loading.is-hovered::after, .button.is-primary-brand-1.is-outlined.is-loading:focus::after, .button.is-primary-brand-1.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-primary-brand-1.is-outlined[disabled],
      fieldset[disabled] .button.is-primary-brand-1.is-outlined {
        background-color: transparent;
        border-color: #009ba9;
        box-shadow: none;
        color: #009ba9; }
    .button.is-primary-brand-1.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-primary-brand-1.is-inverted.is-outlined:hover, .button.is-primary-brand-1.is-inverted.is-outlined.is-hovered, .button.is-primary-brand-1.is-inverted.is-outlined:focus, .button.is-primary-brand-1.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #009ba9; }
      .button.is-primary-brand-1.is-inverted.is-outlined.is-loading:hover::after, .button.is-primary-brand-1.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-primary-brand-1.is-inverted.is-outlined.is-loading:focus::after, .button.is-primary-brand-1.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #009ba9 #009ba9 !important; }
      .button.is-primary-brand-1.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-primary-brand-1.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-primary-brand-1.is-light {
      background-color: #ebfdff;
      color: #00bbcc; }
      .button.is-primary-brand-1.is-light:hover, .button.is-primary-brand-1.is-light.is-hovered {
        background-color: #defcff;
        border-color: transparent;
        color: #00bbcc; }
      .button.is-primary-brand-1.is-light:active, .button.is-primary-brand-1.is-light.is-active {
        background-color: #d1fbff;
        border-color: transparent;
        color: #00bbcc; }
  .button.is-primary-brand-2 {
    background-color: #95c11f;
    border-color: transparent;
    color: #fff; }
    .button.is-primary-brand-2:hover, .button.is-primary-brand-2.is-hovered {
      background-color: #8db61d;
      border-color: transparent;
      color: #fff; }
    .button.is-primary-brand-2:focus, .button.is-primary-brand-2.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-primary-brand-2:focus:not(:active), .button.is-primary-brand-2.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(149, 193, 31, 0.25); }
    .button.is-primary-brand-2:active, .button.is-primary-brand-2.is-active {
      background-color: #84ab1b;
      border-color: transparent;
      color: #fff; }
    .button.is-primary-brand-2[disabled],
    fieldset[disabled] .button.is-primary-brand-2 {
      background-color: #95c11f;
      border-color: transparent;
      box-shadow: none; }
    .button.is-primary-brand-2.is-inverted {
      background-color: #fff;
      color: #95c11f; }
      .button.is-primary-brand-2.is-inverted:hover, .button.is-primary-brand-2.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-primary-brand-2.is-inverted[disabled],
      fieldset[disabled] .button.is-primary-brand-2.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #95c11f; }
    .button.is-primary-brand-2.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-primary-brand-2.is-outlined {
      background-color: transparent;
      border-color: #95c11f;
      color: #95c11f; }
      .button.is-primary-brand-2.is-outlined:hover, .button.is-primary-brand-2.is-outlined.is-hovered, .button.is-primary-brand-2.is-outlined:focus, .button.is-primary-brand-2.is-outlined.is-focused {
        background-color: #95c11f;
        border-color: #95c11f;
        color: #fff; }
      .button.is-primary-brand-2.is-outlined.is-loading::after {
        border-color: transparent transparent #95c11f #95c11f !important; }
      .button.is-primary-brand-2.is-outlined.is-loading:hover::after, .button.is-primary-brand-2.is-outlined.is-loading.is-hovered::after, .button.is-primary-brand-2.is-outlined.is-loading:focus::after, .button.is-primary-brand-2.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-primary-brand-2.is-outlined[disabled],
      fieldset[disabled] .button.is-primary-brand-2.is-outlined {
        background-color: transparent;
        border-color: #95c11f;
        box-shadow: none;
        color: #95c11f; }
    .button.is-primary-brand-2.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-primary-brand-2.is-inverted.is-outlined:hover, .button.is-primary-brand-2.is-inverted.is-outlined.is-hovered, .button.is-primary-brand-2.is-inverted.is-outlined:focus, .button.is-primary-brand-2.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #95c11f; }
      .button.is-primary-brand-2.is-inverted.is-outlined.is-loading:hover::after, .button.is-primary-brand-2.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-primary-brand-2.is-inverted.is-outlined.is-loading:focus::after, .button.is-primary-brand-2.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #95c11f #95c11f !important; }
      .button.is-primary-brand-2.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-primary-brand-2.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-primary-brand-2.is-light {
      background-color: #f8fced;
      color: #668415; }
      .button.is-primary-brand-2.is-light:hover, .button.is-primary-brand-2.is-light.is-hovered {
        background-color: #f4fae2;
        border-color: transparent;
        color: #668415; }
      .button.is-primary-brand-2.is-light:active, .button.is-primary-brand-2.is-light.is-active {
        background-color: #f0f9d7;
        border-color: transparent;
        color: #668415; }
  .button.is-blue-bg-1 {
    background-color: #78cbd0;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-blue-bg-1:hover, .button.is-blue-bg-1.is-hovered {
      background-color: #6fc7cd;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-blue-bg-1:focus, .button.is-blue-bg-1.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-blue-bg-1:focus:not(:active), .button.is-blue-bg-1.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(120, 203, 208, 0.25); }
    .button.is-blue-bg-1:active, .button.is-blue-bg-1.is-active {
      background-color: #65c4c9;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-blue-bg-1[disabled],
    fieldset[disabled] .button.is-blue-bg-1 {
      background-color: #78cbd0;
      border-color: transparent;
      box-shadow: none; }
    .button.is-blue-bg-1.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #78cbd0; }
      .button.is-blue-bg-1.is-inverted:hover, .button.is-blue-bg-1.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-blue-bg-1.is-inverted[disabled],
      fieldset[disabled] .button.is-blue-bg-1.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #78cbd0; }
    .button.is-blue-bg-1.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-blue-bg-1.is-outlined {
      background-color: transparent;
      border-color: #78cbd0;
      color: #78cbd0; }
      .button.is-blue-bg-1.is-outlined:hover, .button.is-blue-bg-1.is-outlined.is-hovered, .button.is-blue-bg-1.is-outlined:focus, .button.is-blue-bg-1.is-outlined.is-focused {
        background-color: #78cbd0;
        border-color: #78cbd0;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-blue-bg-1.is-outlined.is-loading::after {
        border-color: transparent transparent #78cbd0 #78cbd0 !important; }
      .button.is-blue-bg-1.is-outlined.is-loading:hover::after, .button.is-blue-bg-1.is-outlined.is-loading.is-hovered::after, .button.is-blue-bg-1.is-outlined.is-loading:focus::after, .button.is-blue-bg-1.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-blue-bg-1.is-outlined[disabled],
      fieldset[disabled] .button.is-blue-bg-1.is-outlined {
        background-color: transparent;
        border-color: #78cbd0;
        box-shadow: none;
        color: #78cbd0; }
    .button.is-blue-bg-1.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-blue-bg-1.is-inverted.is-outlined:hover, .button.is-blue-bg-1.is-inverted.is-outlined.is-hovered, .button.is-blue-bg-1.is-inverted.is-outlined:focus, .button.is-blue-bg-1.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #78cbd0; }
      .button.is-blue-bg-1.is-inverted.is-outlined.is-loading:hover::after, .button.is-blue-bg-1.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-blue-bg-1.is-inverted.is-outlined.is-loading:focus::after, .button.is-blue-bg-1.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #78cbd0 #78cbd0 !important; }
      .button.is-blue-bg-1.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-blue-bg-1.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-blue-bg-1.is-light {
      background-color: #f0f9fa;
      color: #266a6e; }
      .button.is-blue-bg-1.is-light:hover, .button.is-blue-bg-1.is-light.is-hovered {
        background-color: #e6f6f6;
        border-color: transparent;
        color: #266a6e; }
      .button.is-blue-bg-1.is-light:active, .button.is-blue-bg-1.is-light.is-active {
        background-color: #ddf2f3;
        border-color: transparent;
        color: #266a6e; }
  .button.is-blue-bg-2 {
    background-color: #c0eaee;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-blue-bg-2:hover, .button.is-blue-bg-2.is-hovered {
      background-color: #b6e7eb;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-blue-bg-2:focus, .button.is-blue-bg-2.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-blue-bg-2:focus:not(:active), .button.is-blue-bg-2.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(192, 234, 238, 0.25); }
    .button.is-blue-bg-2:active, .button.is-blue-bg-2.is-active {
      background-color: #ace3e9;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-blue-bg-2[disabled],
    fieldset[disabled] .button.is-blue-bg-2 {
      background-color: #c0eaee;
      border-color: transparent;
      box-shadow: none; }
    .button.is-blue-bg-2.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #c0eaee; }
      .button.is-blue-bg-2.is-inverted:hover, .button.is-blue-bg-2.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-blue-bg-2.is-inverted[disabled],
      fieldset[disabled] .button.is-blue-bg-2.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #c0eaee; }
    .button.is-blue-bg-2.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-blue-bg-2.is-outlined {
      background-color: transparent;
      border-color: #c0eaee;
      color: #c0eaee; }
      .button.is-blue-bg-2.is-outlined:hover, .button.is-blue-bg-2.is-outlined.is-hovered, .button.is-blue-bg-2.is-outlined:focus, .button.is-blue-bg-2.is-outlined.is-focused {
        background-color: #c0eaee;
        border-color: #c0eaee;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-blue-bg-2.is-outlined.is-loading::after {
        border-color: transparent transparent #c0eaee #c0eaee !important; }
      .button.is-blue-bg-2.is-outlined.is-loading:hover::after, .button.is-blue-bg-2.is-outlined.is-loading.is-hovered::after, .button.is-blue-bg-2.is-outlined.is-loading:focus::after, .button.is-blue-bg-2.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-blue-bg-2.is-outlined[disabled],
      fieldset[disabled] .button.is-blue-bg-2.is-outlined {
        background-color: transparent;
        border-color: #c0eaee;
        box-shadow: none;
        color: #c0eaee; }
    .button.is-blue-bg-2.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-blue-bg-2.is-inverted.is-outlined:hover, .button.is-blue-bg-2.is-inverted.is-outlined.is-hovered, .button.is-blue-bg-2.is-inverted.is-outlined:focus, .button.is-blue-bg-2.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #c0eaee; }
      .button.is-blue-bg-2.is-inverted.is-outlined.is-loading:hover::after, .button.is-blue-bg-2.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-blue-bg-2.is-inverted.is-outlined.is-loading:focus::after, .button.is-blue-bg-2.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #c0eaee #c0eaee !important; }
      .button.is-blue-bg-2.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-blue-bg-2.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-blue-bg-2.is-light {
      background-color: #effafb;
      color: #1f6d74; }
      .button.is-blue-bg-2.is-light:hover, .button.is-blue-bg-2.is-light.is-hovered {
        background-color: #e5f6f8;
        border-color: transparent;
        color: #1f6d74; }
      .button.is-blue-bg-2.is-light:active, .button.is-blue-bg-2.is-light.is-active {
        background-color: #dbf3f5;
        border-color: transparent;
        color: #1f6d74; }
  .button.is-green-bg {
    background-color: #bed974;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-green-bg:hover, .button.is-green-bg.is-hovered {
      background-color: #b9d66a;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-green-bg:focus, .button.is-green-bg.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-green-bg:focus:not(:active), .button.is-green-bg.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(190, 217, 116, 0.25); }
    .button.is-green-bg:active, .button.is-green-bg.is-active {
      background-color: #b5d460;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-green-bg[disabled],
    fieldset[disabled] .button.is-green-bg {
      background-color: #bed974;
      border-color: transparent;
      box-shadow: none; }
    .button.is-green-bg.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #bed974; }
      .button.is-green-bg.is-inverted:hover, .button.is-green-bg.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-green-bg.is-inverted[disabled],
      fieldset[disabled] .button.is-green-bg.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #bed974; }
    .button.is-green-bg.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-green-bg.is-outlined {
      background-color: transparent;
      border-color: #bed974;
      color: #bed974; }
      .button.is-green-bg.is-outlined:hover, .button.is-green-bg.is-outlined.is-hovered, .button.is-green-bg.is-outlined:focus, .button.is-green-bg.is-outlined.is-focused {
        background-color: #bed974;
        border-color: #bed974;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-green-bg.is-outlined.is-loading::after {
        border-color: transparent transparent #bed974 #bed974 !important; }
      .button.is-green-bg.is-outlined.is-loading:hover::after, .button.is-green-bg.is-outlined.is-loading.is-hovered::after, .button.is-green-bg.is-outlined.is-loading:focus::after, .button.is-green-bg.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-green-bg.is-outlined[disabled],
      fieldset[disabled] .button.is-green-bg.is-outlined {
        background-color: transparent;
        border-color: #bed974;
        box-shadow: none;
        color: #bed974; }
    .button.is-green-bg.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-green-bg.is-inverted.is-outlined:hover, .button.is-green-bg.is-inverted.is-outlined.is-hovered, .button.is-green-bg.is-inverted.is-outlined:focus, .button.is-green-bg.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #bed974; }
      .button.is-green-bg.is-inverted.is-outlined.is-loading:hover::after, .button.is-green-bg.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-green-bg.is-inverted.is-outlined.is-loading:focus::after, .button.is-green-bg.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #bed974 #bed974 !important; }
      .button.is-green-bg.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-green-bg.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-green-bg.is-light {
      background-color: #f8fbef;
      color: #5e7420; }
      .button.is-green-bg.is-light:hover, .button.is-green-bg.is-light.is-hovered {
        background-color: #f3f8e5;
        border-color: transparent;
        color: #5e7420; }
      .button.is-green-bg.is-light:active, .button.is-green-bg.is-light.is-active {
        background-color: #eef5db;
        border-color: transparent;
        color: #5e7420; }
  .button.is-green-light {
    background-color: #eef8d3;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-green-light:hover, .button.is-green-light.is-hovered {
      background-color: #eaf6c8;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-green-light:focus, .button.is-green-light.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-green-light:focus:not(:active), .button.is-green-light.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(238, 248, 211, 0.25); }
    .button.is-green-light:active, .button.is-green-light.is-active {
      background-color: #e6f5bd;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-green-light[disabled],
    fieldset[disabled] .button.is-green-light {
      background-color: #eef8d3;
      border-color: transparent;
      box-shadow: none; }
    .button.is-green-light.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #eef8d3; }
      .button.is-green-light.is-inverted:hover, .button.is-green-light.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-green-light.is-inverted[disabled],
      fieldset[disabled] .button.is-green-light.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #eef8d3; }
    .button.is-green-light.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-green-light.is-outlined {
      background-color: transparent;
      border-color: #eef8d3;
      color: #eef8d3; }
      .button.is-green-light.is-outlined:hover, .button.is-green-light.is-outlined.is-hovered, .button.is-green-light.is-outlined:focus, .button.is-green-light.is-outlined.is-focused {
        background-color: #eef8d3;
        border-color: #eef8d3;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-green-light.is-outlined.is-loading::after {
        border-color: transparent transparent #eef8d3 #eef8d3 !important; }
      .button.is-green-light.is-outlined.is-loading:hover::after, .button.is-green-light.is-outlined.is-loading.is-hovered::after, .button.is-green-light.is-outlined.is-loading:focus::after, .button.is-green-light.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-green-light.is-outlined[disabled],
      fieldset[disabled] .button.is-green-light.is-outlined {
        background-color: transparent;
        border-color: #eef8d3;
        box-shadow: none;
        color: #eef8d3; }
    .button.is-green-light.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-green-light.is-inverted.is-outlined:hover, .button.is-green-light.is-inverted.is-outlined.is-hovered, .button.is-green-light.is-inverted.is-outlined:focus, .button.is-green-light.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #eef8d3; }
      .button.is-green-light.is-inverted.is-outlined.is-loading:hover::after, .button.is-green-light.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-green-light.is-inverted.is-outlined.is-loading:focus::after, .button.is-green-light.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #eef8d3 #eef8d3 !important; }
      .button.is-green-light.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-green-light.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-green-light.is-light {
      background-color: #f8fced;
      color: #638014; }
      .button.is-green-light.is-light:hover, .button.is-green-light.is-light.is-hovered {
        background-color: #f4fae2;
        border-color: transparent;
        color: #638014; }
      .button.is-green-light.is-light:active, .button.is-green-light.is-light.is-active {
        background-color: #f0f9d7;
        border-color: transparent;
        color: #638014; }
  .button.is-darker {
    background-color: #273d3f;
    border-color: transparent;
    color: #fff; }
    .button.is-darker:hover, .button.is-darker.is-hovered {
      background-color: #223537;
      border-color: transparent;
      color: #fff; }
    .button.is-darker:focus, .button.is-darker.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-darker:focus:not(:active), .button.is-darker.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(39, 61, 63, 0.25); }
    .button.is-darker:active, .button.is-darker.is-active {
      background-color: #1d2e2f;
      border-color: transparent;
      color: #fff; }
    .button.is-darker[disabled],
    fieldset[disabled] .button.is-darker {
      background-color: #273d3f;
      border-color: transparent;
      box-shadow: none; }
    .button.is-darker.is-inverted {
      background-color: #fff;
      color: #273d3f; }
      .button.is-darker.is-inverted:hover, .button.is-darker.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-darker.is-inverted[disabled],
      fieldset[disabled] .button.is-darker.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #273d3f; }
    .button.is-darker.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-darker.is-outlined {
      background-color: transparent;
      border-color: #273d3f;
      color: #273d3f; }
      .button.is-darker.is-outlined:hover, .button.is-darker.is-outlined.is-hovered, .button.is-darker.is-outlined:focus, .button.is-darker.is-outlined.is-focused {
        background-color: #273d3f;
        border-color: #273d3f;
        color: #fff; }
      .button.is-darker.is-outlined.is-loading::after {
        border-color: transparent transparent #273d3f #273d3f !important; }
      .button.is-darker.is-outlined.is-loading:hover::after, .button.is-darker.is-outlined.is-loading.is-hovered::after, .button.is-darker.is-outlined.is-loading:focus::after, .button.is-darker.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-darker.is-outlined[disabled],
      fieldset[disabled] .button.is-darker.is-outlined {
        background-color: transparent;
        border-color: #273d3f;
        box-shadow: none;
        color: #273d3f; }
    .button.is-darker.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-darker.is-inverted.is-outlined:hover, .button.is-darker.is-inverted.is-outlined.is-hovered, .button.is-darker.is-inverted.is-outlined:focus, .button.is-darker.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #273d3f; }
      .button.is-darker.is-inverted.is-outlined.is-loading:hover::after, .button.is-darker.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-darker.is-inverted.is-outlined.is-loading:focus::after, .button.is-darker.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #273d3f #273d3f !important; }
      .button.is-darker.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-darker.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-darker.is-light {
      background-color: #f2f7f7;
      color: #6b9fa3; }
      .button.is-darker.is-light:hover, .button.is-darker.is-light.is-hovered {
        background-color: #ebf2f2;
        border-color: transparent;
        color: #6b9fa3; }
      .button.is-darker.is-light:active, .button.is-darker.is-light.is-active {
        background-color: #e3eded;
        border-color: transparent;
        color: #6b9fa3; }
  .button.is-dark-mild {
    background-color: #789497;
    border-color: transparent;
    color: #fff; }
    .button.is-dark-mild:hover, .button.is-dark-mild.is-hovered {
      background-color: #718e91;
      border-color: transparent;
      color: #fff; }
    .button.is-dark-mild:focus, .button.is-dark-mild.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-dark-mild:focus:not(:active), .button.is-dark-mild.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(120, 148, 151, 0.25); }
    .button.is-dark-mild:active, .button.is-dark-mild.is-active {
      background-color: #6b888b;
      border-color: transparent;
      color: #fff; }
    .button.is-dark-mild[disabled],
    fieldset[disabled] .button.is-dark-mild {
      background-color: #789497;
      border-color: transparent;
      box-shadow: none; }
    .button.is-dark-mild.is-inverted {
      background-color: #fff;
      color: #789497; }
      .button.is-dark-mild.is-inverted:hover, .button.is-dark-mild.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-dark-mild.is-inverted[disabled],
      fieldset[disabled] .button.is-dark-mild.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #789497; }
    .button.is-dark-mild.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-dark-mild.is-outlined {
      background-color: transparent;
      border-color: #789497;
      color: #789497; }
      .button.is-dark-mild.is-outlined:hover, .button.is-dark-mild.is-outlined.is-hovered, .button.is-dark-mild.is-outlined:focus, .button.is-dark-mild.is-outlined.is-focused {
        background-color: #789497;
        border-color: #789497;
        color: #fff; }
      .button.is-dark-mild.is-outlined.is-loading::after {
        border-color: transparent transparent #789497 #789497 !important; }
      .button.is-dark-mild.is-outlined.is-loading:hover::after, .button.is-dark-mild.is-outlined.is-loading.is-hovered::after, .button.is-dark-mild.is-outlined.is-loading:focus::after, .button.is-dark-mild.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-dark-mild.is-outlined[disabled],
      fieldset[disabled] .button.is-dark-mild.is-outlined {
        background-color: transparent;
        border-color: #789497;
        box-shadow: none;
        color: #789497; }
    .button.is-dark-mild.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-dark-mild.is-inverted.is-outlined:hover, .button.is-dark-mild.is-inverted.is-outlined.is-hovered, .button.is-dark-mild.is-inverted.is-outlined:focus, .button.is-dark-mild.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #789497; }
      .button.is-dark-mild.is-inverted.is-outlined.is-loading:hover::after, .button.is-dark-mild.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-dark-mild.is-inverted.is-outlined.is-loading:focus::after, .button.is-dark-mild.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #789497 #789497 !important; }
      .button.is-dark-mild.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-dark-mild.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-dark-mild.is-light {
      background-color: #f3f6f6;
      color: #576e70; }
      .button.is-dark-mild.is-light:hover, .button.is-dark-mild.is-light.is-hovered {
        background-color: #ecf0f1;
        border-color: transparent;
        color: #576e70; }
      .button.is-dark-mild.is-light:active, .button.is-dark-mild.is-light.is-active {
        background-color: #e5eaeb;
        border-color: transparent;
        color: #576e70; }
  .button.is-dark-light {
    background-color: #a8c1c3;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-dark-light:hover, .button.is-dark-light.is-hovered {
      background-color: #a0bcbe;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-dark-light:focus, .button.is-dark-light.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-dark-light:focus:not(:active), .button.is-dark-light.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(168, 193, 195, 0.25); }
    .button.is-dark-light:active, .button.is-dark-light.is-active {
      background-color: #99b6b9;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-dark-light[disabled],
    fieldset[disabled] .button.is-dark-light {
      background-color: #a8c1c3;
      border-color: transparent;
      box-shadow: none; }
    .button.is-dark-light.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #a8c1c3; }
      .button.is-dark-light.is-inverted:hover, .button.is-dark-light.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-dark-light.is-inverted[disabled],
      fieldset[disabled] .button.is-dark-light.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #a8c1c3; }
    .button.is-dark-light.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-dark-light.is-outlined {
      background-color: transparent;
      border-color: #a8c1c3;
      color: #a8c1c3; }
      .button.is-dark-light.is-outlined:hover, .button.is-dark-light.is-outlined.is-hovered, .button.is-dark-light.is-outlined:focus, .button.is-dark-light.is-outlined.is-focused {
        background-color: #a8c1c3;
        border-color: #a8c1c3;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-dark-light.is-outlined.is-loading::after {
        border-color: transparent transparent #a8c1c3 #a8c1c3 !important; }
      .button.is-dark-light.is-outlined.is-loading:hover::after, .button.is-dark-light.is-outlined.is-loading.is-hovered::after, .button.is-dark-light.is-outlined.is-loading:focus::after, .button.is-dark-light.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-dark-light.is-outlined[disabled],
      fieldset[disabled] .button.is-dark-light.is-outlined {
        background-color: transparent;
        border-color: #a8c1c3;
        box-shadow: none;
        color: #a8c1c3; }
    .button.is-dark-light.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-dark-light.is-inverted.is-outlined:hover, .button.is-dark-light.is-inverted.is-outlined.is-hovered, .button.is-dark-light.is-inverted.is-outlined:focus, .button.is-dark-light.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #a8c1c3; }
      .button.is-dark-light.is-inverted.is-outlined.is-loading:hover::after, .button.is-dark-light.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-dark-light.is-inverted.is-outlined.is-loading:focus::after, .button.is-dark-light.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #a8c1c3 #a8c1c3 !important; }
      .button.is-dark-light.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-dark-light.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-dark-light.is-light {
      background-color: #f3f6f7;
      color: #3c5658; }
      .button.is-dark-light.is-light:hover, .button.is-dark-light.is-light.is-hovered {
        background-color: #ebf1f1;
        border-color: transparent;
        color: #3c5658; }
      .button.is-dark-light.is-light:active, .button.is-dark-light.is-light.is-active {
        background-color: #e4ecec;
        border-color: transparent;
        color: #3c5658; }
  .button.is-dark-lightest {
    background-color: #eff3f3;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-dark-lightest:hover, .button.is-dark-lightest.is-hovered {
      background-color: #e8eeee;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-dark-lightest:focus, .button.is-dark-lightest.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-dark-lightest:focus:not(:active), .button.is-dark-lightest.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(239, 243, 243, 0.25); }
    .button.is-dark-lightest:active, .button.is-dark-lightest.is-active {
      background-color: #e0e8e8;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-dark-lightest[disabled],
    fieldset[disabled] .button.is-dark-lightest {
      background-color: #eff3f3;
      border-color: transparent;
      box-shadow: none; }
    .button.is-dark-lightest.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #eff3f3; }
      .button.is-dark-lightest.is-inverted:hover, .button.is-dark-lightest.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-dark-lightest.is-inverted[disabled],
      fieldset[disabled] .button.is-dark-lightest.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #eff3f3; }
    .button.is-dark-lightest.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-dark-lightest.is-outlined {
      background-color: transparent;
      border-color: #eff3f3;
      color: #eff3f3; }
      .button.is-dark-lightest.is-outlined:hover, .button.is-dark-lightest.is-outlined.is-hovered, .button.is-dark-lightest.is-outlined:focus, .button.is-dark-lightest.is-outlined.is-focused {
        background-color: #eff3f3;
        border-color: #eff3f3;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-dark-lightest.is-outlined.is-loading::after {
        border-color: transparent transparent #eff3f3 #eff3f3 !important; }
      .button.is-dark-lightest.is-outlined.is-loading:hover::after, .button.is-dark-lightest.is-outlined.is-loading.is-hovered::after, .button.is-dark-lightest.is-outlined.is-loading:focus::after, .button.is-dark-lightest.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-dark-lightest.is-outlined[disabled],
      fieldset[disabled] .button.is-dark-lightest.is-outlined {
        background-color: transparent;
        border-color: #eff3f3;
        box-shadow: none;
        color: #eff3f3; }
    .button.is-dark-lightest.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-dark-lightest.is-inverted.is-outlined:hover, .button.is-dark-lightest.is-inverted.is-outlined.is-hovered, .button.is-dark-lightest.is-inverted.is-outlined:focus, .button.is-dark-lightest.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #eff3f3; }
      .button.is-dark-lightest.is-inverted.is-outlined.is-loading:hover::after, .button.is-dark-lightest.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-dark-lightest.is-inverted.is-outlined.is-loading:focus::after, .button.is-dark-lightest.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #eff3f3 #eff3f3 !important; }
      .button.is-dark-lightest.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-dark-lightest.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-dark-lightest.is-light {
      background-color: #f3f6f6;
      color: #3f5555; }
      .button.is-dark-lightest.is-light:hover, .button.is-dark-lightest.is-light.is-hovered {
        background-color: #ecf1f1;
        border-color: transparent;
        color: #3f5555; }
      .button.is-dark-lightest.is-light:active, .button.is-dark-lightest.is-light.is-active {
        background-color: #e5ebeb;
        border-color: transparent;
        color: #3f5555; }
  .button.is-small {
    font-size: 0.75rem; }
    .button.is-small:not(.is-rounded) {
      border-radius: 2px; }
  .button.is-normal {
    font-size: 1rem; }
  .button.is-medium {
    font-size: 1.333rem; }
  .button.is-large {
    font-size: 1.777rem; }
  .button[disabled],
  fieldset[disabled] .button {
    background-color: white;
    border-color: #dbdbdb;
    box-shadow: none;
    opacity: 0.5; }
  .button.is-fullwidth {
    display: flex;
    width: 100%; }
  .button.is-loading {
    color: transparent !important;
    pointer-events: none; }
    .button.is-loading::after {
      position: absolute;
      left: calc(50% - (1em * 0.5));
      top: calc(50% - (1em * 0.5));
      position: absolute !important; }
  .button.is-static {
    background-color: whitesmoke;
    border-color: #dbdbdb;
    color: #7a7a7a;
    box-shadow: none;
    pointer-events: none; }
  .button.is-rounded {
    border-radius: 9999px;
    padding-left: calc(1em + 0.25em);
    padding-right: calc(1em + 0.25em); }

.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .buttons .button {
    margin-bottom: 0.5rem; }
    .buttons .button:not(:last-child):not(.is-fullwidth) {
      margin-right: 0.5rem; }
  .buttons:last-child {
    margin-bottom: -0.5rem; }
  .buttons:not(:last-child) {
    margin-bottom: 1rem; }
  .buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
    font-size: 0.75rem; }
    .buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded) {
      border-radius: 2px; }
  .buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
    font-size: 1.333rem; }
  .buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
    font-size: 1.777rem; }
  .buttons.has-addons .button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .buttons.has-addons .button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px; }
  .buttons.has-addons .button:last-child {
    margin-right: 0; }
  .buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
    z-index: 2; }
  .buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
    z-index: 3; }
    .buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
      z-index: 4; }
  .buttons.has-addons .button.is-expanded {
    flex-grow: 1;
    flex-shrink: 1; }
  .buttons.is-centered {
    justify-content: center; }
    .buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
      margin-left: 0.25rem;
      margin-right: 0.25rem; }
  .buttons.is-right {
    justify-content: flex-end; }
    .buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
      margin-left: 0.25rem;
      margin-right: 0.25rem; }

.table {
  background-color: white;
  color: #363636; }
  .table td,
  .table th {
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    padding: 0.5em 0.75em;
    vertical-align: top; }
    .table td.is-white,
    .table th.is-white {
      background-color: white;
      border-color: white;
      color: #0a0a0a; }
    .table td.is-black,
    .table th.is-black {
      background-color: #0a0a0a;
      border-color: #0a0a0a;
      color: white; }
    .table td.is-light,
    .table th.is-light {
      background-color: whitesmoke;
      border-color: whitesmoke;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-dark,
    .table th.is-dark {
      background-color: #4f6567;
      border-color: #4f6567;
      color: #fff; }
    .table td.is-primary,
    .table th.is-primary {
      background-color: #009ba9;
      border-color: #009ba9;
      color: #fff; }
    .table td.is-link,
    .table th.is-link {
      background-color: #485fc7;
      border-color: #485fc7;
      color: #fff; }
    .table td.is-info,
    .table th.is-info {
      background-color: #3e8ed0;
      border-color: #3e8ed0;
      color: #fff; }
    .table td.is-success,
    .table th.is-success {
      background-color: #48c78e;
      border-color: #48c78e;
      color: #fff; }
    .table td.is-warning,
    .table th.is-warning {
      background-color: #ffe08a;
      border-color: #ffe08a;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-danger,
    .table th.is-danger {
      background-color: #f14668;
      border-color: #f14668;
      color: #fff; }
    .table td.is-primary-brand-1,
    .table th.is-primary-brand-1 {
      background-color: #009ba9;
      border-color: #009ba9;
      color: #fff; }
    .table td.is-primary-brand-2,
    .table th.is-primary-brand-2 {
      background-color: #95c11f;
      border-color: #95c11f;
      color: #fff; }
    .table td.is-blue-bg-1,
    .table th.is-blue-bg-1 {
      background-color: #78cbd0;
      border-color: #78cbd0;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-blue-bg-2,
    .table th.is-blue-bg-2 {
      background-color: #c0eaee;
      border-color: #c0eaee;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-green-bg,
    .table th.is-green-bg {
      background-color: #bed974;
      border-color: #bed974;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-green-light,
    .table th.is-green-light {
      background-color: #eef8d3;
      border-color: #eef8d3;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-darker,
    .table th.is-darker {
      background-color: #273d3f;
      border-color: #273d3f;
      color: #fff; }
    .table td.is-dark-mild,
    .table th.is-dark-mild {
      background-color: #789497;
      border-color: #789497;
      color: #fff; }
    .table td.is-dark-light,
    .table th.is-dark-light {
      background-color: #a8c1c3;
      border-color: #a8c1c3;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-dark-lightest,
    .table th.is-dark-lightest {
      background-color: #eff3f3;
      border-color: #eff3f3;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-narrow,
    .table th.is-narrow {
      white-space: nowrap;
      width: 1%; }
    .table td.is-selected,
    .table th.is-selected {
      background-color: #009ba9;
      color: #fff; }
      .table td.is-selected a,
      .table td.is-selected strong,
      .table th.is-selected a,
      .table th.is-selected strong {
        color: currentColor; }
    .table td.is-vcentered,
    .table th.is-vcentered {
      vertical-align: middle; }
  .table th {
    color: #363636; }
    .table th:not([align]) {
      text-align: inherit; }
  .table tr.is-selected {
    background-color: #009ba9;
    color: #fff; }
    .table tr.is-selected a,
    .table tr.is-selected strong {
      color: currentColor; }
    .table tr.is-selected td,
    .table tr.is-selected th {
      border-color: #fff;
      color: currentColor; }
  .table thead {
    background-color: transparent; }
    .table thead td,
    .table thead th {
      border-width: 0 0 2px;
      color: #363636; }
  .table tfoot {
    background-color: transparent; }
    .table tfoot td,
    .table tfoot th {
      border-width: 2px 0 0;
      color: #363636; }
  .table tbody {
    background-color: transparent; }
    .table tbody tr:last-child td,
    .table tbody tr:last-child th {
      border-bottom-width: 0; }
  .table.is-bordered td,
  .table.is-bordered th {
    border-width: 1px; }
  .table.is-bordered tr:last-child td,
  .table.is-bordered tr:last-child th {
    border-bottom-width: 1px; }
  .table.is-fullwidth {
    width: 100%; }
  .table.is-hoverable tbody tr:not(.is-selected):hover {
    background-color: #fafafa; }
  .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
    background-color: #fafafa; }
    .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(even) {
      background-color: whitesmoke; }
  .table.is-narrow td,
  .table.is-narrow th {
    padding: 0.25em 0.5em; }
  .table.is-striped tbody tr:not(.is-selected):nth-child(even) {
    background-color: transparent; }

.table-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%; }

/* Bulma Form */
/* Bulma Form */
.input, .textarea, .select select {
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636; }
  .input::-moz-placeholder, .textarea::-moz-placeholder, .select select::-moz-placeholder {
    color: #666; }
  .input::-webkit-input-placeholder, .textarea::-webkit-input-placeholder, .select select::-webkit-input-placeholder {
    color: #666; }
  .input:-moz-placeholder, .textarea:-moz-placeholder, .select select:-moz-placeholder {
    color: #666; }
  .input:-ms-input-placeholder, .textarea:-ms-input-placeholder, .select select:-ms-input-placeholder {
    color: #666; }
  .input:hover, .textarea:hover, .select select:hover, .is-hovered.input, .is-hovered.textarea, .select select.is-hovered {
    border-color: #b5b5b5; }
  .input:focus, .textarea:focus, .select select:focus, .is-focused.input, .is-focused.textarea, .select select.is-focused, .input:active, .textarea:active, .select select:active, .is-active.input, .is-active.textarea, .select select.is-active {
    border-color: #009ba9;
    box-shadow: 0 0 0 0.125em bulmaRgba(#009ba9, 1); }
  .input[disabled], .textarea[disabled], .select select[disabled],
  fieldset[disabled] .input,
  fieldset[disabled] .textarea,
  fieldset[disabled] .select select,
  .select fieldset[disabled] select {
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: none;
    color: #7a7a7a; }
    .input[disabled]::-moz-placeholder, .textarea[disabled]::-moz-placeholder, .select select[disabled]::-moz-placeholder,
    fieldset[disabled] .input::-moz-placeholder,
    fieldset[disabled] .textarea::-moz-placeholder,
    fieldset[disabled] .select select::-moz-placeholder,
    .select fieldset[disabled] select::-moz-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]::-webkit-input-placeholder, .textarea[disabled]::-webkit-input-placeholder, .select select[disabled]::-webkit-input-placeholder,
    fieldset[disabled] .input::-webkit-input-placeholder,
    fieldset[disabled] .textarea::-webkit-input-placeholder,
    fieldset[disabled] .select select::-webkit-input-placeholder,
    .select fieldset[disabled] select::-webkit-input-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]:-moz-placeholder, .textarea[disabled]:-moz-placeholder, .select select[disabled]:-moz-placeholder,
    fieldset[disabled] .input:-moz-placeholder,
    fieldset[disabled] .textarea:-moz-placeholder,
    fieldset[disabled] .select select:-moz-placeholder,
    .select fieldset[disabled] select:-moz-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]:-ms-input-placeholder, .textarea[disabled]:-ms-input-placeholder, .select select[disabled]:-ms-input-placeholder,
    fieldset[disabled] .input:-ms-input-placeholder,
    fieldset[disabled] .textarea:-ms-input-placeholder,
    fieldset[disabled] .select select:-ms-input-placeholder,
    .select fieldset[disabled] select:-ms-input-placeholder {
      color: rgba(122, 122, 122, 0.3); }

.input, .textarea {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 100%;
  width: 100%; }
  .input[readonly], .textarea[readonly] {
    box-shadow: none; }
  .is-white.input, .is-white.textarea {
    border-color: white; }
    .is-white.input:focus, .is-white.textarea:focus, .is-white.is-focused.input, .is-white.is-focused.textarea, .is-white.input:active, .is-white.textarea:active, .is-white.is-active.input, .is-white.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
  .is-black.input, .is-black.textarea {
    border-color: #0a0a0a; }
    .is-black.input:focus, .is-black.textarea:focus, .is-black.is-focused.input, .is-black.is-focused.textarea, .is-black.input:active, .is-black.textarea:active, .is-black.is-active.input, .is-black.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
  .is-light.input, .is-light.textarea {
    border-color: whitesmoke; }
    .is-light.input:focus, .is-light.textarea:focus, .is-light.is-focused.input, .is-light.is-focused.textarea, .is-light.input:active, .is-light.textarea:active, .is-light.is-active.input, .is-light.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
  .is-dark.input, .is-dark.textarea {
    border-color: #4f6567; }
    .is-dark.input:focus, .is-dark.textarea:focus, .is-dark.is-focused.input, .is-dark.is-focused.textarea, .is-dark.input:active, .is-dark.textarea:active, .is-dark.is-active.input, .is-dark.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(79, 101, 103, 0.25); }
  .is-primary.input, .is-primary.textarea {
    border-color: #009ba9; }
    .is-primary.input:focus, .is-primary.textarea:focus, .is-primary.is-focused.input, .is-primary.is-focused.textarea, .is-primary.input:active, .is-primary.textarea:active, .is-primary.is-active.input, .is-primary.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(0, 155, 169, 0.25); }
  .is-link.input, .is-link.textarea {
    border-color: #485fc7; }
    .is-link.input:focus, .is-link.textarea:focus, .is-link.is-focused.input, .is-link.is-focused.textarea, .is-link.input:active, .is-link.textarea:active, .is-link.is-active.input, .is-link.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25); }
  .is-info.input, .is-info.textarea {
    border-color: #3e8ed0; }
    .is-info.input:focus, .is-info.textarea:focus, .is-info.is-focused.input, .is-info.is-focused.textarea, .is-info.input:active, .is-info.textarea:active, .is-info.is-active.input, .is-info.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25); }
  .is-success.input, .is-success.textarea {
    border-color: #48c78e; }
    .is-success.input:focus, .is-success.textarea:focus, .is-success.is-focused.input, .is-success.is-focused.textarea, .is-success.input:active, .is-success.textarea:active, .is-success.is-active.input, .is-success.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(72, 199, 142, 0.25); }
  .is-warning.input, .is-warning.textarea {
    border-color: #ffe08a; }
    .is-warning.input:focus, .is-warning.textarea:focus, .is-warning.is-focused.input, .is-warning.is-focused.textarea, .is-warning.input:active, .is-warning.textarea:active, .is-warning.is-active.input, .is-warning.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25); }
  .is-danger.input, .is-danger.textarea {
    border-color: #f14668; }
    .is-danger.input:focus, .is-danger.textarea:focus, .is-danger.is-focused.input, .is-danger.is-focused.textarea, .is-danger.input:active, .is-danger.textarea:active, .is-danger.is-active.input, .is-danger.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25); }
  .is-primary-brand-1.input, .is-primary-brand-1.textarea {
    border-color: #009ba9; }
    .is-primary-brand-1.input:focus, .is-primary-brand-1.textarea:focus, .is-primary-brand-1.is-focused.input, .is-primary-brand-1.is-focused.textarea, .is-primary-brand-1.input:active, .is-primary-brand-1.textarea:active, .is-primary-brand-1.is-active.input, .is-primary-brand-1.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(0, 155, 169, 0.25); }
  .is-primary-brand-2.input, .is-primary-brand-2.textarea {
    border-color: #95c11f; }
    .is-primary-brand-2.input:focus, .is-primary-brand-2.textarea:focus, .is-primary-brand-2.is-focused.input, .is-primary-brand-2.is-focused.textarea, .is-primary-brand-2.input:active, .is-primary-brand-2.textarea:active, .is-primary-brand-2.is-active.input, .is-primary-brand-2.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(149, 193, 31, 0.25); }
  .is-blue-bg-1.input, .is-blue-bg-1.textarea {
    border-color: #78cbd0; }
    .is-blue-bg-1.input:focus, .is-blue-bg-1.textarea:focus, .is-blue-bg-1.is-focused.input, .is-blue-bg-1.is-focused.textarea, .is-blue-bg-1.input:active, .is-blue-bg-1.textarea:active, .is-blue-bg-1.is-active.input, .is-blue-bg-1.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(120, 203, 208, 0.25); }
  .is-blue-bg-2.input, .is-blue-bg-2.textarea {
    border-color: #c0eaee; }
    .is-blue-bg-2.input:focus, .is-blue-bg-2.textarea:focus, .is-blue-bg-2.is-focused.input, .is-blue-bg-2.is-focused.textarea, .is-blue-bg-2.input:active, .is-blue-bg-2.textarea:active, .is-blue-bg-2.is-active.input, .is-blue-bg-2.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(192, 234, 238, 0.25); }
  .is-green-bg.input, .is-green-bg.textarea {
    border-color: #bed974; }
    .is-green-bg.input:focus, .is-green-bg.textarea:focus, .is-green-bg.is-focused.input, .is-green-bg.is-focused.textarea, .is-green-bg.input:active, .is-green-bg.textarea:active, .is-green-bg.is-active.input, .is-green-bg.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(190, 217, 116, 0.25); }
  .is-green-light.input, .is-green-light.textarea {
    border-color: #eef8d3; }
    .is-green-light.input:focus, .is-green-light.textarea:focus, .is-green-light.is-focused.input, .is-green-light.is-focused.textarea, .is-green-light.input:active, .is-green-light.textarea:active, .is-green-light.is-active.input, .is-green-light.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(238, 248, 211, 0.25); }
  .is-darker.input, .is-darker.textarea {
    border-color: #273d3f; }
    .is-darker.input:focus, .is-darker.textarea:focus, .is-darker.is-focused.input, .is-darker.is-focused.textarea, .is-darker.input:active, .is-darker.textarea:active, .is-darker.is-active.input, .is-darker.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(39, 61, 63, 0.25); }
  .is-dark-mild.input, .is-dark-mild.textarea {
    border-color: #789497; }
    .is-dark-mild.input:focus, .is-dark-mild.textarea:focus, .is-dark-mild.is-focused.input, .is-dark-mild.is-focused.textarea, .is-dark-mild.input:active, .is-dark-mild.textarea:active, .is-dark-mild.is-active.input, .is-dark-mild.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(120, 148, 151, 0.25); }
  .is-dark-light.input, .is-dark-light.textarea {
    border-color: #a8c1c3; }
    .is-dark-light.input:focus, .is-dark-light.textarea:focus, .is-dark-light.is-focused.input, .is-dark-light.is-focused.textarea, .is-dark-light.input:active, .is-dark-light.textarea:active, .is-dark-light.is-active.input, .is-dark-light.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(168, 193, 195, 0.25); }
  .is-dark-lightest.input, .is-dark-lightest.textarea {
    border-color: #eff3f3; }
    .is-dark-lightest.input:focus, .is-dark-lightest.textarea:focus, .is-dark-lightest.is-focused.input, .is-dark-lightest.is-focused.textarea, .is-dark-lightest.input:active, .is-dark-lightest.textarea:active, .is-dark-lightest.is-active.input, .is-dark-lightest.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(239, 243, 243, 0.25); }
  .is-small.input, .is-small.textarea {
    border-radius: 2px;
    font-size: 0.75rem; }
  .is-medium.input, .is-medium.textarea {
    font-size: 1.333rem; }
  .is-large.input, .is-large.textarea {
    font-size: 1.777rem; }
  .is-fullwidth.input, .is-fullwidth.textarea {
    display: block;
    width: 100%; }
  .is-inline.input, .is-inline.textarea {
    display: inline;
    width: auto; }

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(calc(0.75em - 1px) + 0.375em);
  padding-right: calc(calc(0.75em - 1px) + 0.375em); }

.input.is-static {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0; }

.textarea {
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(0.75em - 1px);
  resize: vertical; }
  .textarea:not([rows]) {
    max-height: 40em;
    min-height: 8em; }
  .textarea[rows] {
    height: initial; }
  .textarea.has-fixed-size {
    resize: none; }

.checkbox, .radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative; }
  .checkbox input, .radio input {
    cursor: pointer; }
  .checkbox:hover, .radio:hover {
    color: #363636; }
  .checkbox[disabled], .radio[disabled],
  fieldset[disabled] .checkbox,
  fieldset[disabled] .radio,
  .checkbox input[disabled],
  .radio input[disabled] {
    color: #7a7a7a;
    cursor: not-allowed; }

.radio + .radio {
  margin-left: 0.5em; }

.select {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top; }
  .select:not(.is-multiple) {
    height: 48px; }
  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #009ba9;
    right: 1.125em;
    z-index: 4; }
  .select.is-rounded select {
    border-radius: 9999px;
    padding-left: 1em; }
  .select select {
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: none; }
    .select select::-ms-expand {
      display: none; }
    .select select[disabled]:hover,
    fieldset[disabled] .select select:hover {
      border-color: whitesmoke; }
    .select select:not([multiple]) {
      padding-right: 2.5em; }
    .select select[multiple] {
      height: auto;
      padding: 0; }
      .select select[multiple] option {
        padding: 0.5em 1em; }
  .select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: #363636; }
  .select.is-white:not(:hover)::after {
    border-color: white; }
  .select.is-white select {
    border-color: white; }
    .select.is-white select:hover, .select.is-white select.is-hovered {
      border-color: #f2f2f2; }
    .select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
  .select.is-black:not(:hover)::after {
    border-color: #0a0a0a; }
  .select.is-black select {
    border-color: #0a0a0a; }
    .select.is-black select:hover, .select.is-black select.is-hovered {
      border-color: black; }
    .select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
      box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
  .select.is-light:not(:hover)::after {
    border-color: whitesmoke; }
  .select.is-light select {
    border-color: whitesmoke; }
    .select.is-light select:hover, .select.is-light select.is-hovered {
      border-color: #e8e8e8; }
    .select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
      box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
  .select.is-dark:not(:hover)::after {
    border-color: #4f6567; }
  .select.is-dark select {
    border-color: #4f6567; }
    .select.is-dark select:hover, .select.is-dark select.is-hovered {
      border-color: #445759; }
    .select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
      box-shadow: 0 0 0 0.125em rgba(79, 101, 103, 0.25); }
  .select.is-primary:not(:hover)::after {
    border-color: #009ba9; }
  .select.is-primary select {
    border-color: #009ba9; }
    .select.is-primary select:hover, .select.is-primary select.is-hovered {
      border-color: #008490; }
    .select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
      box-shadow: 0 0 0 0.125em rgba(0, 155, 169, 0.25); }
  .select.is-link:not(:hover)::after {
    border-color: #485fc7; }
  .select.is-link select {
    border-color: #485fc7; }
    .select.is-link select:hover, .select.is-link select.is-hovered {
      border-color: #3a51bb; }
    .select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
      box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25); }
  .select.is-info:not(:hover)::after {
    border-color: #3e8ed0; }
  .select.is-info select {
    border-color: #3e8ed0; }
    .select.is-info select:hover, .select.is-info select.is-hovered {
      border-color: #3082c5; }
    .select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
      box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25); }
  .select.is-success:not(:hover)::after {
    border-color: #48c78e; }
  .select.is-success select {
    border-color: #48c78e; }
    .select.is-success select:hover, .select.is-success select.is-hovered {
      border-color: #3abb81; }
    .select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
      box-shadow: 0 0 0 0.125em rgba(72, 199, 142, 0.25); }
  .select.is-warning:not(:hover)::after {
    border-color: #ffe08a; }
  .select.is-warning select {
    border-color: #ffe08a; }
    .select.is-warning select:hover, .select.is-warning select.is-hovered {
      border-color: #ffd970; }
    .select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25); }
  .select.is-danger:not(:hover)::after {
    border-color: #f14668; }
  .select.is-danger select {
    border-color: #f14668; }
    .select.is-danger select:hover, .select.is-danger select.is-hovered {
      border-color: #ef2e55; }
    .select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
      box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25); }
  .select.is-primary-brand-1:not(:hover)::after {
    border-color: #009ba9; }
  .select.is-primary-brand-1 select {
    border-color: #009ba9; }
    .select.is-primary-brand-1 select:hover, .select.is-primary-brand-1 select.is-hovered {
      border-color: #008490; }
    .select.is-primary-brand-1 select:focus, .select.is-primary-brand-1 select.is-focused, .select.is-primary-brand-1 select:active, .select.is-primary-brand-1 select.is-active {
      box-shadow: 0 0 0 0.125em rgba(0, 155, 169, 0.25); }
  .select.is-primary-brand-2:not(:hover)::after {
    border-color: #95c11f; }
  .select.is-primary-brand-2 select {
    border-color: #95c11f; }
    .select.is-primary-brand-2 select:hover, .select.is-primary-brand-2 select.is-hovered {
      border-color: #84ab1b; }
    .select.is-primary-brand-2 select:focus, .select.is-primary-brand-2 select.is-focused, .select.is-primary-brand-2 select:active, .select.is-primary-brand-2 select.is-active {
      box-shadow: 0 0 0 0.125em rgba(149, 193, 31, 0.25); }
  .select.is-blue-bg-1:not(:hover)::after {
    border-color: #78cbd0; }
  .select.is-blue-bg-1 select {
    border-color: #78cbd0; }
    .select.is-blue-bg-1 select:hover, .select.is-blue-bg-1 select.is-hovered {
      border-color: #65c4c9; }
    .select.is-blue-bg-1 select:focus, .select.is-blue-bg-1 select.is-focused, .select.is-blue-bg-1 select:active, .select.is-blue-bg-1 select.is-active {
      box-shadow: 0 0 0 0.125em rgba(120, 203, 208, 0.25); }
  .select.is-blue-bg-2:not(:hover)::after {
    border-color: #c0eaee; }
  .select.is-blue-bg-2 select {
    border-color: #c0eaee; }
    .select.is-blue-bg-2 select:hover, .select.is-blue-bg-2 select.is-hovered {
      border-color: #ace3e9; }
    .select.is-blue-bg-2 select:focus, .select.is-blue-bg-2 select.is-focused, .select.is-blue-bg-2 select:active, .select.is-blue-bg-2 select.is-active {
      box-shadow: 0 0 0 0.125em rgba(192, 234, 238, 0.25); }
  .select.is-green-bg:not(:hover)::after {
    border-color: #bed974; }
  .select.is-green-bg select {
    border-color: #bed974; }
    .select.is-green-bg select:hover, .select.is-green-bg select.is-hovered {
      border-color: #b5d460; }
    .select.is-green-bg select:focus, .select.is-green-bg select.is-focused, .select.is-green-bg select:active, .select.is-green-bg select.is-active {
      box-shadow: 0 0 0 0.125em rgba(190, 217, 116, 0.25); }
  .select.is-green-light:not(:hover)::after {
    border-color: #eef8d3; }
  .select.is-green-light select {
    border-color: #eef8d3; }
    .select.is-green-light select:hover, .select.is-green-light select.is-hovered {
      border-color: #e6f5bd; }
    .select.is-green-light select:focus, .select.is-green-light select.is-focused, .select.is-green-light select:active, .select.is-green-light select.is-active {
      box-shadow: 0 0 0 0.125em rgba(238, 248, 211, 0.25); }
  .select.is-darker:not(:hover)::after {
    border-color: #273d3f; }
  .select.is-darker select {
    border-color: #273d3f; }
    .select.is-darker select:hover, .select.is-darker select.is-hovered {
      border-color: #1d2e2f; }
    .select.is-darker select:focus, .select.is-darker select.is-focused, .select.is-darker select:active, .select.is-darker select.is-active {
      box-shadow: 0 0 0 0.125em rgba(39, 61, 63, 0.25); }
  .select.is-dark-mild:not(:hover)::after {
    border-color: #789497; }
  .select.is-dark-mild select {
    border-color: #789497; }
    .select.is-dark-mild select:hover, .select.is-dark-mild select.is-hovered {
      border-color: #6b888b; }
    .select.is-dark-mild select:focus, .select.is-dark-mild select.is-focused, .select.is-dark-mild select:active, .select.is-dark-mild select.is-active {
      box-shadow: 0 0 0 0.125em rgba(120, 148, 151, 0.25); }
  .select.is-dark-light:not(:hover)::after {
    border-color: #a8c1c3; }
  .select.is-dark-light select {
    border-color: #a8c1c3; }
    .select.is-dark-light select:hover, .select.is-dark-light select.is-hovered {
      border-color: #99b6b9; }
    .select.is-dark-light select:focus, .select.is-dark-light select.is-focused, .select.is-dark-light select:active, .select.is-dark-light select.is-active {
      box-shadow: 0 0 0 0.125em rgba(168, 193, 195, 0.25); }
  .select.is-dark-lightest:not(:hover)::after {
    border-color: #eff3f3; }
  .select.is-dark-lightest select {
    border-color: #eff3f3; }
    .select.is-dark-lightest select:hover, .select.is-dark-lightest select.is-hovered {
      border-color: #e0e8e8; }
    .select.is-dark-lightest select:focus, .select.is-dark-lightest select.is-focused, .select.is-dark-lightest select:active, .select.is-dark-lightest select.is-active {
      box-shadow: 0 0 0 0.125em rgba(239, 243, 243, 0.25); }
  .select.is-small {
    border-radius: 2px;
    font-size: 0.75rem; }
  .select.is-medium {
    font-size: 1.333rem; }
  .select.is-large {
    font-size: 1.777rem; }
  .select.is-disabled::after {
    border-color: #7a7a7a; }
  .select.is-fullwidth {
    width: 100%; }
    .select.is-fullwidth select {
      width: 100%; }
  .select.is-loading::after {
    margin-top: 0;
    position: absolute;
    right: 0.625em;
    top: 0.625em;
    transform: none; }
  .select.is-loading.is-small:after {
    font-size: 0.75rem; }
  .select.is-loading.is-medium:after {
    font-size: 1.333rem; }
  .select.is-loading.is-large:after {
    font-size: 1.777rem; }

.file {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  position: relative; }
  .file.is-white .file-cta {
    background-color: white;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
    background-color: #f9f9f9;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.25);
    color: #0a0a0a; }
  .file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
    background-color: #f2f2f2;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-black .file-cta {
    background-color: #0a0a0a;
    border-color: transparent;
    color: white; }
  .file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
    background-color: #040404;
    border-color: transparent;
    color: white; }
  .file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(10, 10, 10, 0.25);
    color: white; }
  .file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
    background-color: black;
    border-color: transparent;
    color: white; }
  .file.is-light .file-cta {
    background-color: whitesmoke;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
    background-color: #eeeeee;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(245, 245, 245, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
    background-color: #e8e8e8;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark .file-cta {
    background-color: #4f6567;
    border-color: transparent;
    color: #fff; }
  .file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
    background-color: #495e60;
    border-color: transparent;
    color: #fff; }
  .file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(79, 101, 103, 0.25);
    color: #fff; }
  .file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
    background-color: #445759;
    border-color: transparent;
    color: #fff; }
  .file.is-primary .file-cta {
    background-color: #009ba9;
    border-color: transparent;
    color: #fff; }
  .file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
    background-color: #008f9c;
    border-color: transparent;
    color: #fff; }
  .file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(0, 155, 169, 0.25);
    color: #fff; }
  .file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
    background-color: #008490;
    border-color: transparent;
    color: #fff; }
  .file.is-link .file-cta {
    background-color: #485fc7;
    border-color: transparent;
    color: #fff; }
  .file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
    background-color: #3e56c4;
    border-color: transparent;
    color: #fff; }
  .file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(72, 95, 199, 0.25);
    color: #fff; }
  .file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
    background-color: #3a51bb;
    border-color: transparent;
    color: #fff; }
  .file.is-info .file-cta {
    background-color: #3e8ed0;
    border-color: transparent;
    color: #fff; }
  .file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
    background-color: #3488ce;
    border-color: transparent;
    color: #fff; }
  .file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(62, 142, 208, 0.25);
    color: #fff; }
  .file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
    background-color: #3082c5;
    border-color: transparent;
    color: #fff; }
  .file.is-success .file-cta {
    background-color: #48c78e;
    border-color: transparent;
    color: #fff; }
  .file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
    background-color: #3ec487;
    border-color: transparent;
    color: #fff; }
  .file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(72, 199, 142, 0.25);
    color: #fff; }
  .file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
    background-color: #3abb81;
    border-color: transparent;
    color: #fff; }
  .file.is-warning .file-cta {
    background-color: #ffe08a;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
    background-color: #ffdc7d;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(255, 224, 138, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
    background-color: #ffd970;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-danger .file-cta {
    background-color: #f14668;
    border-color: transparent;
    color: #fff; }
  .file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
    background-color: #f03a5f;
    border-color: transparent;
    color: #fff; }
  .file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(241, 70, 104, 0.25);
    color: #fff; }
  .file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
    background-color: #ef2e55;
    border-color: transparent;
    color: #fff; }
  .file.is-primary-brand-1 .file-cta {
    background-color: #009ba9;
    border-color: transparent;
    color: #fff; }
  .file.is-primary-brand-1:hover .file-cta, .file.is-primary-brand-1.is-hovered .file-cta {
    background-color: #008f9c;
    border-color: transparent;
    color: #fff; }
  .file.is-primary-brand-1:focus .file-cta, .file.is-primary-brand-1.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(0, 155, 169, 0.25);
    color: #fff; }
  .file.is-primary-brand-1:active .file-cta, .file.is-primary-brand-1.is-active .file-cta {
    background-color: #008490;
    border-color: transparent;
    color: #fff; }
  .file.is-primary-brand-2 .file-cta {
    background-color: #95c11f;
    border-color: transparent;
    color: #fff; }
  .file.is-primary-brand-2:hover .file-cta, .file.is-primary-brand-2.is-hovered .file-cta {
    background-color: #8db61d;
    border-color: transparent;
    color: #fff; }
  .file.is-primary-brand-2:focus .file-cta, .file.is-primary-brand-2.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(149, 193, 31, 0.25);
    color: #fff; }
  .file.is-primary-brand-2:active .file-cta, .file.is-primary-brand-2.is-active .file-cta {
    background-color: #84ab1b;
    border-color: transparent;
    color: #fff; }
  .file.is-blue-bg-1 .file-cta {
    background-color: #78cbd0;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-blue-bg-1:hover .file-cta, .file.is-blue-bg-1.is-hovered .file-cta {
    background-color: #6fc7cd;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-blue-bg-1:focus .file-cta, .file.is-blue-bg-1.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(120, 203, 208, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-blue-bg-1:active .file-cta, .file.is-blue-bg-1.is-active .file-cta {
    background-color: #65c4c9;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-blue-bg-2 .file-cta {
    background-color: #c0eaee;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-blue-bg-2:hover .file-cta, .file.is-blue-bg-2.is-hovered .file-cta {
    background-color: #b6e7eb;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-blue-bg-2:focus .file-cta, .file.is-blue-bg-2.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(192, 234, 238, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-blue-bg-2:active .file-cta, .file.is-blue-bg-2.is-active .file-cta {
    background-color: #ace3e9;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-green-bg .file-cta {
    background-color: #bed974;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-green-bg:hover .file-cta, .file.is-green-bg.is-hovered .file-cta {
    background-color: #b9d66a;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-green-bg:focus .file-cta, .file.is-green-bg.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(190, 217, 116, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-green-bg:active .file-cta, .file.is-green-bg.is-active .file-cta {
    background-color: #b5d460;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-green-light .file-cta {
    background-color: #eef8d3;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-green-light:hover .file-cta, .file.is-green-light.is-hovered .file-cta {
    background-color: #eaf6c8;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-green-light:focus .file-cta, .file.is-green-light.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(238, 248, 211, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-green-light:active .file-cta, .file.is-green-light.is-active .file-cta {
    background-color: #e6f5bd;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-darker .file-cta {
    background-color: #273d3f;
    border-color: transparent;
    color: #fff; }
  .file.is-darker:hover .file-cta, .file.is-darker.is-hovered .file-cta {
    background-color: #223537;
    border-color: transparent;
    color: #fff; }
  .file.is-darker:focus .file-cta, .file.is-darker.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(39, 61, 63, 0.25);
    color: #fff; }
  .file.is-darker:active .file-cta, .file.is-darker.is-active .file-cta {
    background-color: #1d2e2f;
    border-color: transparent;
    color: #fff; }
  .file.is-dark-mild .file-cta {
    background-color: #789497;
    border-color: transparent;
    color: #fff; }
  .file.is-dark-mild:hover .file-cta, .file.is-dark-mild.is-hovered .file-cta {
    background-color: #718e91;
    border-color: transparent;
    color: #fff; }
  .file.is-dark-mild:focus .file-cta, .file.is-dark-mild.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(120, 148, 151, 0.25);
    color: #fff; }
  .file.is-dark-mild:active .file-cta, .file.is-dark-mild.is-active .file-cta {
    background-color: #6b888b;
    border-color: transparent;
    color: #fff; }
  .file.is-dark-light .file-cta {
    background-color: #a8c1c3;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark-light:hover .file-cta, .file.is-dark-light.is-hovered .file-cta {
    background-color: #a0bcbe;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark-light:focus .file-cta, .file.is-dark-light.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(168, 193, 195, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark-light:active .file-cta, .file.is-dark-light.is-active .file-cta {
    background-color: #99b6b9;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark-lightest .file-cta {
    background-color: #eff3f3;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark-lightest:hover .file-cta, .file.is-dark-lightest.is-hovered .file-cta {
    background-color: #e8eeee;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark-lightest:focus .file-cta, .file.is-dark-lightest.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(239, 243, 243, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark-lightest:active .file-cta, .file.is-dark-lightest.is-active .file-cta {
    background-color: #e0e8e8;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-small {
    font-size: 0.75rem; }
  .file.is-normal {
    font-size: 1rem; }
  .file.is-medium {
    font-size: 1.333rem; }
    .file.is-medium .file-icon .fa {
      font-size: 21px; }
  .file.is-large {
    font-size: 1.777rem; }
    .file.is-large .file-icon .fa {
      font-size: 28px; }
  .file.has-name .file-cta {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .file.has-name .file-name {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .file.has-name.is-empty .file-cta {
    border-radius: 4px; }
  .file.has-name.is-empty .file-name {
    display: none; }
  .file.is-boxed .file-label {
    flex-direction: column; }
  .file.is-boxed .file-cta {
    flex-direction: column;
    height: auto;
    padding: 1em 3em; }
  .file.is-boxed .file-name {
    border-width: 0 1px 1px; }
  .file.is-boxed .file-icon {
    height: 1.5em;
    width: 1.5em; }
    .file.is-boxed .file-icon .fa {
      font-size: 21px; }
  .file.is-boxed.is-small .file-icon .fa {
    font-size: 14px; }
  .file.is-boxed.is-medium .file-icon .fa {
    font-size: 28px; }
  .file.is-boxed.is-large .file-icon .fa {
    font-size: 35px; }
  .file.is-boxed.has-name .file-cta {
    border-radius: 4px 4px 0 0; }
  .file.is-boxed.has-name .file-name {
    border-radius: 0 0 4px 4px;
    border-width: 0 1px 1px; }
  .file.is-centered {
    justify-content: center; }
  .file.is-fullwidth .file-label {
    width: 100%; }
  .file.is-fullwidth .file-name {
    flex-grow: 1;
    max-width: none; }
  .file.is-right {
    justify-content: flex-end; }
    .file.is-right .file-cta {
      border-radius: 0 4px 4px 0; }
    .file.is-right .file-name {
      border-radius: 4px 0 0 4px;
      border-width: 1px 0 1px 1px;
      order: -1; }

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative; }
  .file-label:hover .file-cta {
    background-color: #eeeeee;
    color: #363636; }
  .file-label:hover .file-name {
    border-color: #d5d5d5; }
  .file-label:active .file-cta {
    background-color: #e8e8e8;
    color: #363636; }
  .file-label:active .file-name {
    border-color: #cfcfcf; }

.file-input {
  height: 100%;
  left: 0;
  opacity: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%; }

.file-cta,
.file-name {
  border-color: #dbdbdb;
  border-radius: 4px;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap; }

.file-cta {
  background-color: whitesmoke;
  color: #4a4a4a; }

.file-name {
  border-color: #dbdbdb;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  display: block;
  max-width: 16em;
  overflow: hidden;
  text-align: inherit;
  text-overflow: ellipsis; }

.file-icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  margin-right: 0.5em;
  width: 1em; }
  .file-icon .fa {
    font-size: 14px; }

.label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700; }
  .label:not(:last-child) {
    margin-bottom: 0.5em; }
  .label.is-small {
    font-size: 0.75rem; }
  .label.is-medium {
    font-size: 1.333rem; }
  .label.is-large {
    font-size: 1.777rem; }

.help {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.25rem; }
  .help.is-white {
    color: white; }
  .help.is-black {
    color: #0a0a0a; }
  .help.is-light {
    color: whitesmoke; }
  .help.is-dark {
    color: #4f6567; }
  .help.is-primary {
    color: #009ba9; }
  .help.is-link {
    color: #485fc7; }
  .help.is-info {
    color: #3e8ed0; }
  .help.is-success {
    color: #48c78e; }
  .help.is-warning {
    color: #ffe08a; }
  .help.is-danger {
    color: #f14668; }
  .help.is-primary-brand-1 {
    color: #009ba9; }
  .help.is-primary-brand-2 {
    color: #95c11f; }
  .help.is-blue-bg-1 {
    color: #78cbd0; }
  .help.is-blue-bg-2 {
    color: #c0eaee; }
  .help.is-green-bg {
    color: #bed974; }
  .help.is-green-light {
    color: #eef8d3; }
  .help.is-darker {
    color: #273d3f; }
  .help.is-dark-mild {
    color: #789497; }
  .help.is-dark-light {
    color: #a8c1c3; }
  .help.is-dark-lightest {
    color: #eff3f3; }

.field:not(:last-child) {
  margin-bottom: 0.75rem; }

.field.has-addons {
  display: flex;
  justify-content: flex-start; }
  .field.has-addons .control:not(:last-child) {
    margin-right: -1px; }
  .field.has-addons .control:not(:first-child):not(:last-child) .button,
  .field.has-addons .control:not(:first-child):not(:last-child) .input,
  .field.has-addons .control:not(:first-child):not(:last-child) .select select {
    border-radius: 0; }
  .field.has-addons .control:first-child:not(:only-child) .button,
  .field.has-addons .control:first-child:not(:only-child) .input,
  .field.has-addons .control:first-child:not(:only-child) .select select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .field.has-addons .control:last-child:not(:only-child) .button,
  .field.has-addons .control:last-child:not(:only-child) .input,
  .field.has-addons .control:last-child:not(:only-child) .select select {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered,
  .field.has-addons .control .input:not([disabled]):hover,
  .field.has-addons .control .input:not([disabled]).is-hovered,
  .field.has-addons .control .select select:not([disabled]):hover,
  .field.has-addons .control .select select:not([disabled]).is-hovered {
    z-index: 2; }
  .field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active,
  .field.has-addons .control .input:not([disabled]):focus,
  .field.has-addons .control .input:not([disabled]).is-focused,
  .field.has-addons .control .input:not([disabled]):active,
  .field.has-addons .control .input:not([disabled]).is-active,
  .field.has-addons .control .select select:not([disabled]):focus,
  .field.has-addons .control .select select:not([disabled]).is-focused,
  .field.has-addons .control .select select:not([disabled]):active,
  .field.has-addons .control .select select:not([disabled]).is-active {
    z-index: 3; }
    .field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover,
    .field.has-addons .control .input:not([disabled]):focus:hover,
    .field.has-addons .control .input:not([disabled]).is-focused:hover,
    .field.has-addons .control .input:not([disabled]):active:hover,
    .field.has-addons .control .input:not([disabled]).is-active:hover,
    .field.has-addons .control .select select:not([disabled]):focus:hover,
    .field.has-addons .control .select select:not([disabled]).is-focused:hover,
    .field.has-addons .control .select select:not([disabled]):active:hover,
    .field.has-addons .control .select select:not([disabled]).is-active:hover {
      z-index: 4; }
  .field.has-addons .control.is-expanded {
    flex-grow: 1;
    flex-shrink: 1; }
  .field.has-addons.has-addons-centered {
    justify-content: center; }
  .field.has-addons.has-addons-right {
    justify-content: flex-end; }
  .field.has-addons.has-addons-fullwidth .control {
    flex-grow: 1;
    flex-shrink: 0; }

.field.is-grouped {
  display: flex;
  justify-content: flex-start; }
  .field.is-grouped > .control {
    flex-shrink: 0; }
    .field.is-grouped > .control:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.75rem; }
    .field.is-grouped > .control.is-expanded {
      flex-grow: 1;
      flex-shrink: 1; }
  .field.is-grouped.is-grouped-centered {
    justify-content: center; }
  .field.is-grouped.is-grouped-right {
    justify-content: flex-end; }
  .field.is-grouped.is-grouped-multiline {
    flex-wrap: wrap; }
    .field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
      margin-bottom: 0.75rem; }
    .field.is-grouped.is-grouped-multiline:last-child {
      margin-bottom: -0.75rem; }
    .field.is-grouped.is-grouped-multiline:not(:last-child) {
      margin-bottom: 0; }

@media screen and (min-width: 900px), print {
  .field.is-horizontal {
    display: flex; } }

.field-label .label {
  font-size: inherit; }

@media screen and (max-width: 899px) {
  .field-label {
    margin-bottom: 0.5rem; } }

@media screen and (min-width: 900px), print {
  .field-label {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1.5rem;
    text-align: right; }
    .field-label.is-small {
      font-size: 0.75rem;
      padding-top: 0.375em; }
    .field-label.is-normal {
      padding-top: 0.375em; }
    .field-label.is-medium {
      font-size: 1.333rem;
      padding-top: 0.375em; }
    .field-label.is-large {
      font-size: 1.777rem;
      padding-top: 0.375em; } }

.field-body .field .field {
  margin-bottom: 0; }

@media screen and (min-width: 900px), print {
  .field-body {
    display: flex;
    flex-basis: 0;
    flex-grow: 5;
    flex-shrink: 1; }
    .field-body .field {
      margin-bottom: 0; }
    .field-body > .field {
      flex-shrink: 1; }
      .field-body > .field:not(.is-narrow) {
        flex-grow: 1; }
      .field-body > .field:not(:last-child) {
        margin-right: 0.75rem; } }

.control {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit; }
  .control.has-icons-left .input:focus ~ .icon,
  .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon,
  .control.has-icons-right .select:focus ~ .icon {
    color: #4a4a4a; }
  .control.has-icons-left .input.is-small ~ .icon,
  .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon,
  .control.has-icons-right .select.is-small ~ .icon {
    font-size: 0.75rem; }
  .control.has-icons-left .input.is-medium ~ .icon,
  .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon,
  .control.has-icons-right .select.is-medium ~ .icon {
    font-size: 1.333rem; }
  .control.has-icons-left .input.is-large ~ .icon,
  .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon,
  .control.has-icons-right .select.is-large ~ .icon {
    font-size: 1.777rem; }
  .control.has-icons-left .icon, .control.has-icons-right .icon {
    color: #dbdbdb;
    height: 48px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 48px;
    z-index: 4; }
  .control.has-icons-left .input,
  .control.has-icons-left .select select {
    padding-left: 48px; }
  .control.has-icons-left .icon.is-left {
    left: 0; }
  .control.has-icons-right .input,
  .control.has-icons-right .select select {
    padding-right: 48px; }
  .control.has-icons-right .icon.is-right {
    right: 0; }
  .control.is-loading::after {
    position: absolute !important;
    right: 0.625em;
    top: 0.625em;
    z-index: 4; }
  .control.is-loading.is-small:after {
    font-size: 0.75rem; }
  .control.is-loading.is-medium:after {
    font-size: 1.333rem; }
  .control.is-loading.is-large:after {
    font-size: 1.777rem; }

/* Bulma Components */
.level {
  align-items: center;
  justify-content: space-between; }
  .level code {
    border-radius: 4px; }
  .level img {
    display: inline-block;
    vertical-align: top; }
  .level.is-mobile {
    display: flex; }
    .level.is-mobile .level-left,
    .level.is-mobile .level-right {
      display: flex; }
    .level.is-mobile .level-left + .level-right {
      margin-top: 0; }
    .level.is-mobile .level-item:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.5625rem; }
    .level.is-mobile .level-item:not(.is-narrow) {
      flex-grow: 1; }
  @media screen and (min-width: 900px), print {
    .level {
      display: flex; }
      .level > .level-item:not(.is-narrow) {
        flex-grow: 1; } }

.level-item {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center; }
  .level-item .title,
  .level-item .subtitle {
    margin-bottom: 0; }
  @media screen and (max-width: 899px) {
    .level-item:not(:last-child) {
      margin-bottom: 0.5625rem; } }

.level-left,
.level-right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0; }
  .level-left .level-item.is-flexible,
  .level-right .level-item.is-flexible {
    flex-grow: 1; }
  @media screen and (min-width: 900px), print {
    .level-left .level-item:not(:last-child),
    .level-right .level-item:not(:last-child) {
      margin-right: 0.5625rem; } }

.level-left {
  align-items: center;
  justify-content: flex-start; }
  @media screen and (max-width: 899px) {
    .level-left + .level-right {
      margin-top: 1.5rem; } }
  @media screen and (min-width: 900px), print {
    .level-left {
      display: flex; } }

.level-right {
  align-items: center;
  justify-content: flex-end; }
  @media screen and (min-width: 900px), print {
    .level-right {
      display: flex; } }

.pagination {
  font-size: 1rem;
  margin: -0.25rem; }
  .pagination.is-small {
    font-size: 0.75rem; }
  .pagination.is-medium {
    font-size: 1.333rem; }
  .pagination.is-large {
    font-size: 1.777rem; }
  .pagination.is-rounded .pagination-previous,
  .pagination.is-rounded .pagination-next {
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 9999px; }
  .pagination.is-rounded .pagination-link {
    border-radius: 9999px; }

.pagination,
.pagination-list {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center; }

.pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
  font-size: 1em;
  justify-content: center;
  margin: 0.25rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center; }

.pagination-previous,
.pagination-next,
.pagination-link {
  border-color: #dbdbdb;
  color: #363636;
  min-width: 2.5em; }
  .pagination-previous:hover,
  .pagination-next:hover,
  .pagination-link:hover {
    border-color: #b5b5b5;
    color: #363636; }
  .pagination-previous:focus,
  .pagination-next:focus,
  .pagination-link:focus {
    border-color: #485fc7; }
  .pagination-previous:active,
  .pagination-next:active,
  .pagination-link:active {
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.2); }
  .pagination-previous[disabled],
  .pagination-next[disabled],
  .pagination-link[disabled] {
    background-color: #dbdbdb;
    border-color: #dbdbdb;
    box-shadow: none;
    color: #7a7a7a;
    opacity: 0.5; }

.pagination-previous,
.pagination-next {
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap; }

.pagination-link.is-current {
  background-color: #485fc7;
  border-color: #485fc7;
  color: #fff; }

.pagination-ellipsis {
  color: #b5b5b5;
  pointer-events: none; }

.pagination-list {
  flex-wrap: wrap; }
  .pagination-list li {
    list-style: none; }

@media screen and (max-width: 899px) {
  .pagination {
    flex-wrap: wrap; }
  .pagination-previous,
  .pagination-next {
    flex-grow: 1;
    flex-shrink: 1; }
  .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1; } }

@media screen and (min-width: 900px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    order: 1; }
  .pagination-previous,
  .pagination-next,
  .pagination-link,
  .pagination-ellipsis {
    margin-bottom: 0;
    margin-top: 0; }
  .pagination-previous {
    order: 2; }
  .pagination-next {
    order: 3; }
  .pagination {
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 0; }
    .pagination.is-centered .pagination-previous {
      order: 1; }
    .pagination.is-centered .pagination-list {
      justify-content: center;
      order: 2; }
    .pagination.is-centered .pagination-next {
      order: 3; }
    .pagination.is-right .pagination-previous {
      order: 1; }
    .pagination.is-right .pagination-next {
      order: 2; }
    .pagination.is-right .pagination-list {
      justify-content: flex-end;
      order: 3; } }

/* Bulma Grid */
.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.875rem; }
  .columns.is-mobile > .column.is-narrow {
    flex: none;
    width: unset; }
  .columns.is-mobile > .column.is-full {
    flex: none;
    width: 100%; }
  .columns.is-mobile > .column.is-three-quarters {
    flex: none;
    width: 75%; }
  .columns.is-mobile > .column.is-two-thirds {
    flex: none;
    width: 66.6666%; }
  .columns.is-mobile > .column.is-half {
    flex: none;
    width: 50%; }
  .columns.is-mobile > .column.is-one-third {
    flex: none;
    width: 33.3333%; }
  .columns.is-mobile > .column.is-one-quarter {
    flex: none;
    width: 25%; }
  .columns.is-mobile > .column.is-one-fifth {
    flex: none;
    width: 20%; }
  .columns.is-mobile > .column.is-two-fifths {
    flex: none;
    width: 40%; }
  .columns.is-mobile > .column.is-three-fifths {
    flex: none;
    width: 60%; }
  .columns.is-mobile > .column.is-four-fifths {
    flex: none;
    width: 80%; }
  .columns.is-mobile > .column.is-offset-three-quarters {
    margin-left: 75%; }
  .columns.is-mobile > .column.is-offset-two-thirds {
    margin-left: 66.6666%; }
  .columns.is-mobile > .column.is-offset-half {
    margin-left: 50%; }
  .columns.is-mobile > .column.is-offset-one-third {
    margin-left: 33.3333%; }
  .columns.is-mobile > .column.is-offset-one-quarter {
    margin-left: 25%; }
  .columns.is-mobile > .column.is-offset-one-fifth {
    margin-left: 20%; }
  .columns.is-mobile > .column.is-offset-two-fifths {
    margin-left: 40%; }
  .columns.is-mobile > .column.is-offset-three-fifths {
    margin-left: 60%; }
  .columns.is-mobile > .column.is-offset-four-fifths {
    margin-left: 80%; }
  .columns.is-mobile > .column.is-0 {
    flex: none;
    width: 0%; }
  .columns.is-mobile > .column.is-offset-0 {
    margin-left: 0%; }
  .columns.is-mobile > .column.is-1 {
    flex: none;
    width: 8.33333%; }
  .columns.is-mobile > .column.is-offset-1 {
    margin-left: 8.33333%; }
  .columns.is-mobile > .column.is-2 {
    flex: none;
    width: 16.66667%; }
  .columns.is-mobile > .column.is-offset-2 {
    margin-left: 16.66667%; }
  .columns.is-mobile > .column.is-3 {
    flex: none;
    width: 25%; }
  .columns.is-mobile > .column.is-offset-3 {
    margin-left: 25%; }
  .columns.is-mobile > .column.is-4 {
    flex: none;
    width: 33.33333%; }
  .columns.is-mobile > .column.is-offset-4 {
    margin-left: 33.33333%; }
  .columns.is-mobile > .column.is-5 {
    flex: none;
    width: 41.66667%; }
  .columns.is-mobile > .column.is-offset-5 {
    margin-left: 41.66667%; }
  .columns.is-mobile > .column.is-6 {
    flex: none;
    width: 50%; }
  .columns.is-mobile > .column.is-offset-6 {
    margin-left: 50%; }
  .columns.is-mobile > .column.is-7 {
    flex: none;
    width: 58.33333%; }
  .columns.is-mobile > .column.is-offset-7 {
    margin-left: 58.33333%; }
  .columns.is-mobile > .column.is-8 {
    flex: none;
    width: 66.66667%; }
  .columns.is-mobile > .column.is-offset-8 {
    margin-left: 66.66667%; }
  .columns.is-mobile > .column.is-9 {
    flex: none;
    width: 75%; }
  .columns.is-mobile > .column.is-offset-9 {
    margin-left: 75%; }
  .columns.is-mobile > .column.is-10 {
    flex: none;
    width: 83.33333%; }
  .columns.is-mobile > .column.is-offset-10 {
    margin-left: 83.33333%; }
  .columns.is-mobile > .column.is-11 {
    flex: none;
    width: 91.66667%; }
  .columns.is-mobile > .column.is-offset-11 {
    margin-left: 91.66667%; }
  .columns.is-mobile > .column.is-12 {
    flex: none;
    width: 100%; }
  .columns.is-mobile > .column.is-offset-12 {
    margin-left: 100%; }
  @media screen and (max-width: 899px) {
    .column.is-narrow-mobile {
      flex: none;
      width: unset; }
    .column.is-full-mobile {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-mobile {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-mobile {
      flex: none;
      width: 66.6666%; }
    .column.is-half-mobile {
      flex: none;
      width: 50%; }
    .column.is-one-third-mobile {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-mobile {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-mobile {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-mobile {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-mobile {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-mobile {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-mobile {
      margin-left: 75%; }
    .column.is-offset-two-thirds-mobile {
      margin-left: 66.6666%; }
    .column.is-offset-half-mobile {
      margin-left: 50%; }
    .column.is-offset-one-third-mobile {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-mobile {
      margin-left: 25%; }
    .column.is-offset-one-fifth-mobile {
      margin-left: 20%; }
    .column.is-offset-two-fifths-mobile {
      margin-left: 40%; }
    .column.is-offset-three-fifths-mobile {
      margin-left: 60%; }
    .column.is-offset-four-fifths-mobile {
      margin-left: 80%; }
    .column.is-0-mobile {
      flex: none;
      width: 0%; }
    .column.is-offset-0-mobile {
      margin-left: 0%; }
    .column.is-1-mobile {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-mobile {
      margin-left: 8.33333%; }
    .column.is-2-mobile {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-mobile {
      margin-left: 16.66667%; }
    .column.is-3-mobile {
      flex: none;
      width: 25%; }
    .column.is-offset-3-mobile {
      margin-left: 25%; }
    .column.is-4-mobile {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-mobile {
      margin-left: 33.33333%; }
    .column.is-5-mobile {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-mobile {
      margin-left: 41.66667%; }
    .column.is-6-mobile {
      flex: none;
      width: 50%; }
    .column.is-offset-6-mobile {
      margin-left: 50%; }
    .column.is-7-mobile {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-mobile {
      margin-left: 58.33333%; }
    .column.is-8-mobile {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-mobile {
      margin-left: 66.66667%; }
    .column.is-9-mobile {
      flex: none;
      width: 75%; }
    .column.is-offset-9-mobile {
      margin-left: 75%; }
    .column.is-10-mobile {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-mobile {
      margin-left: 83.33333%; }
    .column.is-11-mobile {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-mobile {
      margin-left: 91.66667%; }
    .column.is-12-mobile {
      flex: none;
      width: 100%; }
    .column.is-offset-12-mobile {
      margin-left: 100%; } }
  @media screen and (min-width: 900px), print {
    .column.is-narrow, .column.is-narrow-tablet {
      flex: none;
      width: unset; }
    .column.is-full, .column.is-full-tablet {
      flex: none;
      width: 100%; }
    .column.is-three-quarters, .column.is-three-quarters-tablet {
      flex: none;
      width: 75%; }
    .column.is-two-thirds, .column.is-two-thirds-tablet {
      flex: none;
      width: 66.6666%; }
    .column.is-half, .column.is-half-tablet {
      flex: none;
      width: 50%; }
    .column.is-one-third, .column.is-one-third-tablet {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter, .column.is-one-quarter-tablet {
      flex: none;
      width: 25%; }
    .column.is-one-fifth, .column.is-one-fifth-tablet {
      flex: none;
      width: 20%; }
    .column.is-two-fifths, .column.is-two-fifths-tablet {
      flex: none;
      width: 40%; }
    .column.is-three-fifths, .column.is-three-fifths-tablet {
      flex: none;
      width: 60%; }
    .column.is-four-fifths, .column.is-four-fifths-tablet {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
      margin-left: 75%; }
    .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
      margin-left: 66.6666%; }
    .column.is-offset-half, .column.is-offset-half-tablet {
      margin-left: 50%; }
    .column.is-offset-one-third, .column.is-offset-one-third-tablet {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
      margin-left: 25%; }
    .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
      margin-left: 20%; }
    .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
      margin-left: 40%; }
    .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
      margin-left: 60%; }
    .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
      margin-left: 80%; }
    .column.is-0, .column.is-0-tablet {
      flex: none;
      width: 0%; }
    .column.is-offset-0, .column.is-offset-0-tablet {
      margin-left: 0%; }
    .column.is-1, .column.is-1-tablet {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1, .column.is-offset-1-tablet {
      margin-left: 8.33333%; }
    .column.is-2, .column.is-2-tablet {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2, .column.is-offset-2-tablet {
      margin-left: 16.66667%; }
    .column.is-3, .column.is-3-tablet {
      flex: none;
      width: 25%; }
    .column.is-offset-3, .column.is-offset-3-tablet {
      margin-left: 25%; }
    .column.is-4, .column.is-4-tablet {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4, .column.is-offset-4-tablet {
      margin-left: 33.33333%; }
    .column.is-5, .column.is-5-tablet {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5, .column.is-offset-5-tablet {
      margin-left: 41.66667%; }
    .column.is-6, .column.is-6-tablet {
      flex: none;
      width: 50%; }
    .column.is-offset-6, .column.is-offset-6-tablet {
      margin-left: 50%; }
    .column.is-7, .column.is-7-tablet {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7, .column.is-offset-7-tablet {
      margin-left: 58.33333%; }
    .column.is-8, .column.is-8-tablet {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8, .column.is-offset-8-tablet {
      margin-left: 66.66667%; }
    .column.is-9, .column.is-9-tablet {
      flex: none;
      width: 75%; }
    .column.is-offset-9, .column.is-offset-9-tablet {
      margin-left: 75%; }
    .column.is-10, .column.is-10-tablet {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10, .column.is-offset-10-tablet {
      margin-left: 83.33333%; }
    .column.is-11, .column.is-11-tablet {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11, .column.is-offset-11-tablet {
      margin-left: 91.66667%; }
    .column.is-12, .column.is-12-tablet {
      flex: none;
      width: 100%; }
    .column.is-offset-12, .column.is-offset-12-tablet {
      margin-left: 100%; } }
  @media screen and (max-width: 1023px) {
    .column.is-narrow-touch {
      flex: none;
      width: unset; }
    .column.is-full-touch {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-touch {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-touch {
      flex: none;
      width: 66.6666%; }
    .column.is-half-touch {
      flex: none;
      width: 50%; }
    .column.is-one-third-touch {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-touch {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-touch {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-touch {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-touch {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-touch {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-touch {
      margin-left: 75%; }
    .column.is-offset-two-thirds-touch {
      margin-left: 66.6666%; }
    .column.is-offset-half-touch {
      margin-left: 50%; }
    .column.is-offset-one-third-touch {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-touch {
      margin-left: 25%; }
    .column.is-offset-one-fifth-touch {
      margin-left: 20%; }
    .column.is-offset-two-fifths-touch {
      margin-left: 40%; }
    .column.is-offset-three-fifths-touch {
      margin-left: 60%; }
    .column.is-offset-four-fifths-touch {
      margin-left: 80%; }
    .column.is-0-touch {
      flex: none;
      width: 0%; }
    .column.is-offset-0-touch {
      margin-left: 0%; }
    .column.is-1-touch {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-touch {
      margin-left: 8.33333%; }
    .column.is-2-touch {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-touch {
      margin-left: 16.66667%; }
    .column.is-3-touch {
      flex: none;
      width: 25%; }
    .column.is-offset-3-touch {
      margin-left: 25%; }
    .column.is-4-touch {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-touch {
      margin-left: 33.33333%; }
    .column.is-5-touch {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-touch {
      margin-left: 41.66667%; }
    .column.is-6-touch {
      flex: none;
      width: 50%; }
    .column.is-offset-6-touch {
      margin-left: 50%; }
    .column.is-7-touch {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-touch {
      margin-left: 58.33333%; }
    .column.is-8-touch {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-touch {
      margin-left: 66.66667%; }
    .column.is-9-touch {
      flex: none;
      width: 75%; }
    .column.is-offset-9-touch {
      margin-left: 75%; }
    .column.is-10-touch {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-touch {
      margin-left: 83.33333%; }
    .column.is-11-touch {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-touch {
      margin-left: 91.66667%; }
    .column.is-12-touch {
      flex: none;
      width: 100%; }
    .column.is-offset-12-touch {
      margin-left: 100%; } }
  @media screen and (min-width: 1024px) {
    .column.is-narrow-desktop {
      flex: none;
      width: unset; }
    .column.is-full-desktop {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-desktop {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-desktop {
      flex: none;
      width: 66.6666%; }
    .column.is-half-desktop {
      flex: none;
      width: 50%; }
    .column.is-one-third-desktop {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-desktop {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-desktop {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-desktop {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-desktop {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-desktop {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-desktop {
      margin-left: 75%; }
    .column.is-offset-two-thirds-desktop {
      margin-left: 66.6666%; }
    .column.is-offset-half-desktop {
      margin-left: 50%; }
    .column.is-offset-one-third-desktop {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-desktop {
      margin-left: 25%; }
    .column.is-offset-one-fifth-desktop {
      margin-left: 20%; }
    .column.is-offset-two-fifths-desktop {
      margin-left: 40%; }
    .column.is-offset-three-fifths-desktop {
      margin-left: 60%; }
    .column.is-offset-four-fifths-desktop {
      margin-left: 80%; }
    .column.is-0-desktop {
      flex: none;
      width: 0%; }
    .column.is-offset-0-desktop {
      margin-left: 0%; }
    .column.is-1-desktop {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-desktop {
      margin-left: 8.33333%; }
    .column.is-2-desktop {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-desktop {
      margin-left: 16.66667%; }
    .column.is-3-desktop {
      flex: none;
      width: 25%; }
    .column.is-offset-3-desktop {
      margin-left: 25%; }
    .column.is-4-desktop {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-desktop {
      margin-left: 33.33333%; }
    .column.is-5-desktop {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-desktop {
      margin-left: 41.66667%; }
    .column.is-6-desktop {
      flex: none;
      width: 50%; }
    .column.is-offset-6-desktop {
      margin-left: 50%; }
    .column.is-7-desktop {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-desktop {
      margin-left: 58.33333%; }
    .column.is-8-desktop {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-desktop {
      margin-left: 66.66667%; }
    .column.is-9-desktop {
      flex: none;
      width: 75%; }
    .column.is-offset-9-desktop {
      margin-left: 75%; }
    .column.is-10-desktop {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-desktop {
      margin-left: 83.33333%; }
    .column.is-11-desktop {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-desktop {
      margin-left: 91.66667%; }
    .column.is-12-desktop {
      flex: none;
      width: 100%; }
    .column.is-offset-12-desktop {
      margin-left: 100%; } }
  @media screen and (min-width: 1216px) {
    .column.is-narrow-widescreen {
      flex: none;
      width: unset; }
    .column.is-full-widescreen {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-widescreen {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-widescreen {
      flex: none;
      width: 66.6666%; }
    .column.is-half-widescreen {
      flex: none;
      width: 50%; }
    .column.is-one-third-widescreen {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-widescreen {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-widescreen {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-widescreen {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-widescreen {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-widescreen {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-widescreen {
      margin-left: 75%; }
    .column.is-offset-two-thirds-widescreen {
      margin-left: 66.6666%; }
    .column.is-offset-half-widescreen {
      margin-left: 50%; }
    .column.is-offset-one-third-widescreen {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-widescreen {
      margin-left: 25%; }
    .column.is-offset-one-fifth-widescreen {
      margin-left: 20%; }
    .column.is-offset-two-fifths-widescreen {
      margin-left: 40%; }
    .column.is-offset-three-fifths-widescreen {
      margin-left: 60%; }
    .column.is-offset-four-fifths-widescreen {
      margin-left: 80%; }
    .column.is-0-widescreen {
      flex: none;
      width: 0%; }
    .column.is-offset-0-widescreen {
      margin-left: 0%; }
    .column.is-1-widescreen {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-widescreen {
      margin-left: 8.33333%; }
    .column.is-2-widescreen {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-widescreen {
      margin-left: 16.66667%; }
    .column.is-3-widescreen {
      flex: none;
      width: 25%; }
    .column.is-offset-3-widescreen {
      margin-left: 25%; }
    .column.is-4-widescreen {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-widescreen {
      margin-left: 33.33333%; }
    .column.is-5-widescreen {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-widescreen {
      margin-left: 41.66667%; }
    .column.is-6-widescreen {
      flex: none;
      width: 50%; }
    .column.is-offset-6-widescreen {
      margin-left: 50%; }
    .column.is-7-widescreen {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-widescreen {
      margin-left: 58.33333%; }
    .column.is-8-widescreen {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-widescreen {
      margin-left: 66.66667%; }
    .column.is-9-widescreen {
      flex: none;
      width: 75%; }
    .column.is-offset-9-widescreen {
      margin-left: 75%; }
    .column.is-10-widescreen {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-widescreen {
      margin-left: 83.33333%; }
    .column.is-11-widescreen {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-widescreen {
      margin-left: 91.66667%; }
    .column.is-12-widescreen {
      flex: none;
      width: 100%; }
    .column.is-offset-12-widescreen {
      margin-left: 100%; } }
  @media screen and (min-width: 1408px) {
    .column.is-narrow-fullhd {
      flex: none;
      width: unset; }
    .column.is-full-fullhd {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-fullhd {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-fullhd {
      flex: none;
      width: 66.6666%; }
    .column.is-half-fullhd {
      flex: none;
      width: 50%; }
    .column.is-one-third-fullhd {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-fullhd {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-fullhd {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-fullhd {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-fullhd {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-fullhd {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-fullhd {
      margin-left: 75%; }
    .column.is-offset-two-thirds-fullhd {
      margin-left: 66.6666%; }
    .column.is-offset-half-fullhd {
      margin-left: 50%; }
    .column.is-offset-one-third-fullhd {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-fullhd {
      margin-left: 25%; }
    .column.is-offset-one-fifth-fullhd {
      margin-left: 20%; }
    .column.is-offset-two-fifths-fullhd {
      margin-left: 40%; }
    .column.is-offset-three-fifths-fullhd {
      margin-left: 60%; }
    .column.is-offset-four-fifths-fullhd {
      margin-left: 80%; }
    .column.is-0-fullhd {
      flex: none;
      width: 0%; }
    .column.is-offset-0-fullhd {
      margin-left: 0%; }
    .column.is-1-fullhd {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-fullhd {
      margin-left: 8.33333%; }
    .column.is-2-fullhd {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-fullhd {
      margin-left: 16.66667%; }
    .column.is-3-fullhd {
      flex: none;
      width: 25%; }
    .column.is-offset-3-fullhd {
      margin-left: 25%; }
    .column.is-4-fullhd {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-fullhd {
      margin-left: 33.33333%; }
    .column.is-5-fullhd {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-fullhd {
      margin-left: 41.66667%; }
    .column.is-6-fullhd {
      flex: none;
      width: 50%; }
    .column.is-offset-6-fullhd {
      margin-left: 50%; }
    .column.is-7-fullhd {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-fullhd {
      margin-left: 58.33333%; }
    .column.is-8-fullhd {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-fullhd {
      margin-left: 66.66667%; }
    .column.is-9-fullhd {
      flex: none;
      width: 75%; }
    .column.is-offset-9-fullhd {
      margin-left: 75%; }
    .column.is-10-fullhd {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-fullhd {
      margin-left: 83.33333%; }
    .column.is-11-fullhd {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-fullhd {
      margin-left: 91.66667%; }
    .column.is-12-fullhd {
      flex: none;
      width: 100%; }
    .column.is-offset-12-fullhd {
      margin-left: 100%; } }

.columns {
  margin-left: -0.875rem;
  margin-right: -0.875rem;
  margin-top: -0.875rem; }
  .columns:last-child {
    margin-bottom: -0.875rem; }
  .columns:not(:last-child) {
    margin-bottom: calc(1.5rem - 0.875rem); }
  .columns.is-centered {
    justify-content: center; }
  .columns.is-gapless {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0; }
    .columns.is-gapless > .column {
      margin: 0;
      padding: 0 !important; }
    .columns.is-gapless:not(:last-child) {
      margin-bottom: 1.5rem; }
    .columns.is-gapless:last-child {
      margin-bottom: 0; }
  .columns.is-mobile {
    display: flex; }
  .columns.is-multiline {
    flex-wrap: wrap; }
  .columns.is-vcentered {
    align-items: center; }
  @media screen and (min-width: 900px), print {
    .columns:not(.is-desktop) {
      display: flex; } }
  @media screen and (min-width: 1024px) {
    .columns.is-desktop {
      display: flex; } }

.columns.is-variable {
  --columnGap: 0.75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap)); }
  .columns.is-variable > .column {
    padding-left: var(--columnGap);
    padding-right: var(--columnGap); }
  .columns.is-variable.is-0 {
    --columnGap: 0rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-0-mobile {
      --columnGap: 0rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-0-tablet {
      --columnGap: 0rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-0-tablet-only {
      --columnGap: 0rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-0-touch {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-0-desktop {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-0-desktop-only {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-0-widescreen {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-0-widescreen-only {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-0-fullhd {
      --columnGap: 0rem; } }
  .columns.is-variable.is-1 {
    --columnGap: 0.25rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-1-mobile {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-1-tablet {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-1-tablet-only {
      --columnGap: 0.25rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-1-touch {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-1-desktop {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-1-desktop-only {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-1-widescreen {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-1-widescreen-only {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-1-fullhd {
      --columnGap: 0.25rem; } }
  .columns.is-variable.is-2 {
    --columnGap: 0.5rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-2-mobile {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-2-tablet {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-2-tablet-only {
      --columnGap: 0.5rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-2-touch {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-2-desktop {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-2-desktop-only {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-2-widescreen {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-2-widescreen-only {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-2-fullhd {
      --columnGap: 0.5rem; } }
  .columns.is-variable.is-3 {
    --columnGap: 0.75rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-3-mobile {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-3-tablet {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-3-tablet-only {
      --columnGap: 0.75rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-3-touch {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-3-desktop {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-3-desktop-only {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-3-widescreen {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-3-widescreen-only {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-3-fullhd {
      --columnGap: 0.75rem; } }
  .columns.is-variable.is-4 {
    --columnGap: 1rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-4-mobile {
      --columnGap: 1rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-4-tablet {
      --columnGap: 1rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-4-tablet-only {
      --columnGap: 1rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-4-touch {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-4-desktop {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-4-desktop-only {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-4-widescreen {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-4-widescreen-only {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-4-fullhd {
      --columnGap: 1rem; } }
  .columns.is-variable.is-5 {
    --columnGap: 1.25rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-5-mobile {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-5-tablet {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-5-tablet-only {
      --columnGap: 1.25rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-5-touch {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-5-desktop {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-5-desktop-only {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-5-widescreen {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-5-widescreen-only {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-5-fullhd {
      --columnGap: 1.25rem; } }
  .columns.is-variable.is-6 {
    --columnGap: 1.5rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-6-mobile {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-6-tablet {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-6-tablet-only {
      --columnGap: 1.5rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-6-touch {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-6-desktop {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-6-desktop-only {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-6-widescreen {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-6-widescreen-only {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-6-fullhd {
      --columnGap: 1.5rem; } }
  .columns.is-variable.is-7 {
    --columnGap: 1.75rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-7-mobile {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-7-tablet {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-7-tablet-only {
      --columnGap: 1.75rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-7-touch {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-7-desktop {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-7-desktop-only {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-7-widescreen {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-7-widescreen-only {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-7-fullhd {
      --columnGap: 1.75rem; } }
  .columns.is-variable.is-8 {
    --columnGap: 2rem; }
  @media screen and (max-width: 899px) {
    .columns.is-variable.is-8-mobile {
      --columnGap: 2rem; } }
  @media screen and (min-width: 900px), print {
    .columns.is-variable.is-8-tablet {
      --columnGap: 2rem; } }
  @media screen and (min-width: 900px) and (max-width: 1023px) {
    .columns.is-variable.is-8-tablet-only {
      --columnGap: 2rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-8-touch {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-8-desktop {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-8-desktop-only {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-8-widescreen {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-8-widescreen-only {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-8-fullhd {
      --columnGap: 2rem; } }

/*https://bulma.io/documentation/columns/*/
/* Bulma Helpers */
.has-text-white {
  color: white !important; }

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important; }

.has-background-white {
  background-color: white !important; }

.has-text-black {
  color: #0a0a0a !important; }

a.has-text-black:hover, a.has-text-black:focus {
  color: black !important; }

.has-background-black {
  background-color: #0a0a0a !important; }

.has-text-light {
  color: whitesmoke !important; }

a.has-text-light:hover, a.has-text-light:focus {
  color: #dbdbdb !important; }

.has-background-light {
  background-color: whitesmoke !important; }

.has-text-dark {
  color: #4f6567 !important; }

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #39494a !important; }

.has-background-dark {
  background-color: #4f6567 !important; }

.has-text-dark-light {
  color: #f3f6f6 !important; }

a.has-text-dark-light:hover, a.has-text-dark-light:focus {
  color: #d7dfe0 !important; }

.has-background-dark-light {
  background-color: #f3f6f6 !important; }

.has-text-dark-dark {
  color: #6a888b !important; }

a.has-text-dark-dark:hover, a.has-text-dark-dark:focus {
  color: #86a0a2 !important; }

.has-background-dark-dark {
  background-color: #6a888b !important; }

.has-text-primary {
  color: #009ba9 !important; }

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #006c76 !important; }

.has-background-primary {
  background-color: #009ba9 !important; }

.has-text-primary-light {
  color: #ebfdff !important; }

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #b8f9ff !important; }

.has-background-primary-light {
  background-color: #ebfdff !important; }

.has-text-primary-dark {
  color: #00bbcc !important; }

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: #00eaff !important; }

.has-background-primary-dark {
  background-color: #00bbcc !important; }

.has-text-link {
  color: #485fc7 !important; }

a.has-text-link:hover, a.has-text-link:focus {
  color: #3449a8 !important; }

.has-background-link {
  background-color: #485fc7 !important; }

.has-text-link-light {
  color: #eff1fa !important; }

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #c8cfee !important; }

.has-background-link-light {
  background-color: #eff1fa !important; }

.has-text-link-dark {
  color: #3850b7 !important; }

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #576dcb !important; }

.has-background-link-dark {
  background-color: #3850b7 !important; }

.has-text-info {
  color: #3e8ed0 !important; }

a.has-text-info:hover, a.has-text-info:focus {
  color: #2b74b1 !important; }

.has-background-info {
  background-color: #3e8ed0 !important; }

.has-text-info-light {
  color: #eff5fb !important; }

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #c6ddf1 !important; }

.has-background-info-light {
  background-color: #eff5fb !important; }

.has-text-info-dark {
  color: #296fa8 !important; }

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #368ace !important; }

.has-background-info-dark {
  background-color: #296fa8 !important; }

.has-text-success {
  color: #48c78e !important; }

a.has-text-success:hover, a.has-text-success:focus {
  color: #34a873 !important; }

.has-background-success {
  background-color: #48c78e !important; }

.has-text-success-light {
  color: #effaf5 !important; }

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #c8eedd !important; }

.has-background-success-light {
  background-color: #effaf5 !important; }

.has-text-success-dark {
  color: #257953 !important; }

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #31a06e !important; }

.has-background-success-dark {
  background-color: #257953 !important; }

.has-text-warning {
  color: #ffe08a !important; }

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #ffd257 !important; }

.has-background-warning {
  background-color: #ffe08a !important; }

.has-text-warning-light {
  color: #fffaeb !important; }

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #ffecb8 !important; }

.has-background-warning-light {
  background-color: #fffaeb !important; }

.has-text-warning-dark {
  color: #946c00 !important; }

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c79200 !important; }

.has-background-warning-dark {
  background-color: #946c00 !important; }

.has-text-danger {
  color: #f14668 !important; }

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #ee1742 !important; }

.has-background-danger {
  background-color: #f14668 !important; }

.has-text-danger-light {
  color: #feecf0 !important; }

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #fabdc9 !important; }

.has-background-danger-light {
  background-color: #feecf0 !important; }

.has-text-danger-dark {
  color: #cc0f35 !important; }

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #ee2049 !important; }

.has-background-danger-dark {
  background-color: #cc0f35 !important; }

.has-text-primary-brand-1 {
  color: #009ba9 !important; }

a.has-text-primary-brand-1:hover, a.has-text-primary-brand-1:focus {
  color: #006c76 !important; }

.has-background-primary-brand-1 {
  background-color: #009ba9 !important; }

.has-text-primary-brand-1-light {
  color: #ebfdff !important; }

a.has-text-primary-brand-1-light:hover, a.has-text-primary-brand-1-light:focus {
  color: #b8f9ff !important; }

.has-background-primary-brand-1-light {
  background-color: #ebfdff !important; }

.has-text-primary-brand-1-dark {
  color: #00bbcc !important; }

a.has-text-primary-brand-1-dark:hover, a.has-text-primary-brand-1-dark:focus {
  color: #00eaff !important; }

.has-background-primary-brand-1-dark {
  background-color: #00bbcc !important; }

.has-text-primary-brand-2 {
  color: #95c11f !important; }

a.has-text-primary-brand-2:hover, a.has-text-primary-brand-2:focus {
  color: #739518 !important; }

.has-background-primary-brand-2 {
  background-color: #95c11f !important; }

.has-text-primary-brand-2-light {
  color: #f8fced !important; }

a.has-text-primary-brand-2-light:hover, a.has-text-primary-brand-2-light:focus {
  color: #e7f5c1 !important; }

.has-background-primary-brand-2-light {
  background-color: #f8fced !important; }

.has-text-primary-brand-2-dark {
  color: #668415 !important; }

a.has-text-primary-brand-2-dark:hover, a.has-text-primary-brand-2-dark:focus {
  color: #88b01c !important; }

.has-background-primary-brand-2-dark {
  background-color: #668415 !important; }

.has-text-blue-bg-1 {
  color: #78cbd0 !important; }

a.has-text-blue-bg-1:hover, a.has-text-blue-bg-1:focus {
  color: #52bcc3 !important; }

.has-background-blue-bg-1 {
  background-color: #78cbd0 !important; }

.has-text-blue-bg-1-light {
  color: #f0f9fa !important; }

a.has-text-blue-bg-1-light:hover, a.has-text-blue-bg-1-light:focus {
  color: #caebed !important; }

.has-background-blue-bg-1-light {
  background-color: #f0f9fa !important; }

.has-text-blue-bg-1-dark {
  color: #266a6e !important; }

a.has-text-blue-bg-1-dark:hover, a.has-text-blue-bg-1-dark:focus {
  color: #338e94 !important; }

.has-background-blue-bg-1-dark {
  background-color: #266a6e !important; }

.has-text-blue-bg-2 {
  color: #c0eaee !important; }

a.has-text-blue-bg-2:hover, a.has-text-blue-bg-2:focus {
  color: #98dde3 !important; }

.has-background-blue-bg-2 {
  background-color: #c0eaee !important; }

.has-text-blue-bg-2-light {
  color: #effafb !important; }

a.has-text-blue-bg-2-light:hover, a.has-text-blue-bg-2-light:focus {
  color: #c7ecf0 !important; }

.has-background-blue-bg-2-light {
  background-color: #effafb !important; }

.has-text-blue-bg-2-dark {
  color: #1f6d74 !important; }

a.has-text-blue-bg-2-dark:hover, a.has-text-blue-bg-2-dark:focus {
  color: #2a939d !important; }

.has-background-blue-bg-2-dark {
  background-color: #1f6d74 !important; }

.has-text-green-bg {
  color: #bed974 !important; }

a.has-text-green-bg:hover, a.has-text-green-bg:focus {
  color: #abce4c !important; }

.has-background-green-bg {
  background-color: #bed974 !important; }

.has-text-green-bg-light {
  color: #f8fbef !important; }

a.has-text-green-bg-light:hover, a.has-text-green-bg-light:focus {
  color: #e5f0c7 !important; }

.has-background-green-bg-light {
  background-color: #f8fbef !important; }

.has-text-green-bg-dark {
  color: #5e7420 !important; }

a.has-text-green-bg-dark:hover, a.has-text-green-bg-dark:focus {
  color: #7e9c2b !important; }

.has-background-green-bg-dark {
  background-color: #5e7420 !important; }

.has-text-green-light {
  color: #eef8d3 !important; }

a.has-text-green-light:hover, a.has-text-green-light:focus {
  color: #ddf1a7 !important; }

.has-background-green-light {
  background-color: #eef8d3 !important; }

.has-text-green-light-light {
  color: #f8fced !important; }

a.has-text-green-light-light:hover, a.has-text-green-light-light:focus {
  color: #e7f5c1 !important; }

.has-background-green-light-light {
  background-color: #f8fced !important; }

.has-text-green-light-dark {
  color: #638014 !important; }

a.has-text-green-light-dark:hover, a.has-text-green-light-dark:focus {
  color: #85ac1b !important; }

.has-background-green-light-dark {
  background-color: #638014 !important; }

.has-text-darker {
  color: #273d3f !important; }

a.has-text-darker:hover, a.has-text-darker:focus {
  color: #141f20 !important; }

.has-background-darker {
  background-color: #273d3f !important; }

.has-text-darker-light {
  color: #f2f7f7 !important; }

a.has-text-darker-light:hover, a.has-text-darker-light:focus {
  color: #d3e2e4 !important; }

.has-background-darker-light {
  background-color: #f2f7f7 !important; }

.has-text-darker-dark {
  color: #6b9fa3 !important; }

a.has-text-darker-dark:hover, a.has-text-darker-dark:focus {
  color: #8ab3b7 !important; }

.has-background-darker-dark {
  background-color: #6b9fa3 !important; }

.has-text-dark-mild {
  color: #789497 !important; }

a.has-text-dark-mild:hover, a.has-text-dark-mild:focus {
  color: #607a7c !important; }

.has-background-dark-mild {
  background-color: #789497 !important; }

.has-text-dark-mild-light {
  color: #f3f6f6 !important; }

a.has-text-dark-mild-light:hover, a.has-text-dark-mild-light:focus {
  color: #d7dfe0 !important; }

.has-background-dark-mild-light {
  background-color: #f3f6f6 !important; }

.has-text-dark-mild-dark {
  color: #576e70 !important; }

a.has-text-dark-mild-dark:hover, a.has-text-dark-mild-dark:focus {
  color: #6d8a8d !important; }

.has-background-dark-mild-dark {
  background-color: #576e70 !important; }

.has-text-dark-light {
  color: #a8c1c3 !important; }

a.has-text-dark-light:hover, a.has-text-dark-light:focus {
  color: #8aabae !important; }

.has-background-dark-light {
  background-color: #a8c1c3 !important; }

.has-text-dark-light-light {
  color: #f3f6f7 !important; }

a.has-text-dark-light-light:hover, a.has-text-dark-light-light:focus {
  color: #d5e1e2 !important; }

.has-background-dark-light-light {
  background-color: #f3f6f7 !important; }

.has-text-dark-light-dark {
  color: #3c5658 !important; }

a.has-text-dark-light-dark:hover, a.has-text-dark-light-dark:focus {
  color: #517376 !important; }

.has-background-dark-light-dark {
  background-color: #3c5658 !important; }

.has-text-dark-lightest {
  color: #eff3f3 !important; }

a.has-text-dark-lightest:hover, a.has-text-dark-lightest:focus {
  color: #d2dddd !important; }

.has-background-dark-lightest {
  background-color: #eff3f3 !important; }

.has-text-dark-lightest-light {
  color: #f3f6f6 !important; }

a.has-text-dark-lightest-light:hover, a.has-text-dark-lightest-light:focus {
  color: #d6e0e0 !important; }

.has-background-dark-lightest-light {
  background-color: #f3f6f6 !important; }

.has-text-dark-lightest-dark {
  color: #3f5555 !important; }

a.has-text-dark-lightest-dark:hover, a.has-text-dark-lightest-dark:focus {
  color: #557272 !important; }

.has-background-dark-lightest-dark {
  background-color: #3f5555 !important; }

.has-text-black-bis {
  color: #121212 !important; }

.has-background-black-bis {
  background-color: #121212 !important; }

.has-text-black-ter {
  color: #242424 !important; }

.has-background-black-ter {
  background-color: #242424 !important; }

.has-text-grey-darker {
  color: #363636 !important; }

.has-background-grey-darker {
  background-color: #363636 !important; }

.has-text-grey-dark {
  color: #4a4a4a !important; }

.has-background-grey-dark {
  background-color: #4a4a4a !important; }

.has-text-grey {
  color: #7a7a7a !important; }

.has-background-grey {
  background-color: #7a7a7a !important; }

.has-text-grey-light {
  color: #b5b5b5 !important; }

.has-background-grey-light {
  background-color: #b5b5b5 !important; }

.has-text-grey-lighter {
  color: #dbdbdb !important; }

.has-background-grey-lighter {
  background-color: #dbdbdb !important; }

.has-text-white-ter {
  color: whitesmoke !important; }

.has-background-white-ter {
  background-color: whitesmoke !important; }

.has-text-white-bis {
  color: #fafafa !important; }

.has-background-white-bis {
  background-color: #fafafa !important; }

/*https://bulma.io/documentation/helpers/color-helpers/*/
.is-flex-direction-row {
  flex-direction: row !important; }

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important; }

.is-flex-direction-column {
  flex-direction: column !important; }

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important; }

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important; }

.is-flex-wrap-wrap {
  flex-wrap: wrap !important; }

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.is-justify-content-flex-start {
  justify-content: flex-start !important; }

.is-justify-content-flex-end {
  justify-content: flex-end !important; }

.is-justify-content-center {
  justify-content: center !important; }

.is-justify-content-space-between {
  justify-content: space-between !important; }

.is-justify-content-space-around {
  justify-content: space-around !important; }

.is-justify-content-space-evenly {
  justify-content: space-evenly !important; }

.is-justify-content-start {
  justify-content: start !important; }

.is-justify-content-end {
  justify-content: end !important; }

.is-justify-content-left {
  justify-content: left !important; }

.is-justify-content-right {
  justify-content: right !important; }

.is-align-content-flex-start {
  align-content: flex-start !important; }

.is-align-content-flex-end {
  align-content: flex-end !important; }

.is-align-content-center {
  align-content: center !important; }

.is-align-content-space-between {
  align-content: space-between !important; }

.is-align-content-space-around {
  align-content: space-around !important; }

.is-align-content-space-evenly {
  align-content: space-evenly !important; }

.is-align-content-stretch {
  align-content: stretch !important; }

.is-align-content-start {
  align-content: start !important; }

.is-align-content-end {
  align-content: end !important; }

.is-align-content-baseline {
  align-content: baseline !important; }

.is-align-items-stretch {
  align-items: stretch !important; }

.is-align-items-flex-start {
  align-items: flex-start !important; }

.is-align-items-flex-end {
  align-items: flex-end !important; }

.is-align-items-center {
  align-items: center !important; }

.is-align-items-baseline {
  align-items: baseline !important; }

.is-align-items-start {
  align-items: start !important; }

.is-align-items-end {
  align-items: end !important; }

.is-align-items-self-start {
  align-items: self-start !important; }

.is-align-items-self-end {
  align-items: self-end !important; }

.is-align-self-auto {
  align-self: auto !important; }

.is-align-self-flex-start {
  align-self: flex-start !important; }

.is-align-self-flex-end {
  align-self: flex-end !important; }

.is-align-self-center {
  align-self: center !important; }

.is-align-self-baseline {
  align-self: baseline !important; }

.is-align-self-stretch {
  align-self: stretch !important; }

.is-flex-grow-0 {
  flex-grow: 0 !important; }

.is-flex-grow-1 {
  flex-grow: 1 !important; }

.is-flex-grow-2 {
  flex-grow: 2 !important; }

.is-flex-grow-3 {
  flex-grow: 3 !important; }

.is-flex-grow-4 {
  flex-grow: 4 !important; }

.is-flex-grow-5 {
  flex-grow: 5 !important; }

.is-flex-shrink-0 {
  flex-shrink: 0 !important; }

.is-flex-shrink-1 {
  flex-shrink: 1 !important; }

.is-flex-shrink-2 {
  flex-shrink: 2 !important; }

.is-flex-shrink-3 {
  flex-shrink: 3 !important; }

.is-flex-shrink-4 {
  flex-shrink: 4 !important; }

.is-flex-shrink-5 {
  flex-shrink: 5 !important; }

.is-marginless {
  margin: 0 !important; }

.is-paddingless {
  padding: 0 !important; }

.m-0 {
  margin: 0rem !important; }

.mt-0 {
  margin-top: 0rem !important; }

.mr-0 {
  margin-right: 0rem !important; }

.mb-0 {
  margin-bottom: 0rem !important; }

.ml-0 {
  margin-left: 0rem !important; }

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important; }

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.m-1 {
  margin: 0.875rem !important; }

.mt-1 {
  margin-top: 0.875rem !important; }

.mr-1 {
  margin-right: 0.875rem !important; }

.mb-1 {
  margin-bottom: 0.875rem !important; }

.ml-1 {
  margin-left: 0.875rem !important; }

.mx-1 {
  margin-left: 0.875rem !important;
  margin-right: 0.875rem !important; }

.my-1 {
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2 {
  margin-top: 1rem !important; }

.mr-2 {
  margin-right: 1rem !important; }

.mb-2 {
  margin-bottom: 1rem !important; }

.ml-2 {
  margin-left: 1rem !important; }

.mx-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important; }

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-3 {
  margin: 1.125rem !important; }

.mt-3 {
  margin-top: 1.125rem !important; }

.mr-3 {
  margin-right: 1.125rem !important; }

.mb-3 {
  margin-bottom: 1.125rem !important; }

.ml-3 {
  margin-left: 1.125rem !important; }

.mx-3 {
  margin-left: 1.125rem !important;
  margin-right: 1.125rem !important; }

.my-3 {
  margin-top: 1.125rem !important;
  margin-bottom: 1.125rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.mt-5 {
  margin-top: 2rem !important; }

.mr-5 {
  margin-right: 2rem !important; }

.mb-5 {
  margin-bottom: 2rem !important; }

.ml-5 {
  margin-left: 2rem !important; }

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important; }

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.m-6 {
  margin: 2.5rem !important; }

.mt-6 {
  margin-top: 2.5rem !important; }

.mr-6 {
  margin-right: 2.5rem !important; }

.mb-6 {
  margin-bottom: 2.5rem !important; }

.ml-6 {
  margin-left: 2.5rem !important; }

.mx-6 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important; }

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.m-7 {
  margin: 3rem !important; }

.mt-7 {
  margin-top: 3rem !important; }

.mr-7 {
  margin-right: 3rem !important; }

.mb-7 {
  margin-bottom: 3rem !important; }

.ml-7 {
  margin-left: 3rem !important; }

.mx-7 {
  margin-left: 3rem !important;
  margin-right: 3rem !important; }

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0rem !important; }

.pt-0 {
  padding-top: 0rem !important; }

.pr-0 {
  padding-right: 0rem !important; }

.pb-0 {
  padding-bottom: 0rem !important; }

.pl-0 {
  padding-left: 0rem !important; }

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important; }

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.p-1 {
  padding: 0.875rem !important; }

.pt-1 {
  padding-top: 0.875rem !important; }

.pr-1 {
  padding-right: 0.875rem !important; }

.pb-1 {
  padding-bottom: 0.875rem !important; }

.pl-1 {
  padding-left: 0.875rem !important; }

.px-1 {
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important; }

.py-1 {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2 {
  padding-top: 1rem !important; }

.pr-2 {
  padding-right: 1rem !important; }

.pb-2 {
  padding-bottom: 1rem !important; }

.pl-2 {
  padding-left: 1rem !important; }

.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important; }

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-3 {
  padding: 1.125rem !important; }

.pt-3 {
  padding-top: 1.125rem !important; }

.pr-3 {
  padding-right: 1.125rem !important; }

.pb-3 {
  padding-bottom: 1.125rem !important; }

.pl-3 {
  padding-left: 1.125rem !important; }

.px-3 {
  padding-left: 1.125rem !important;
  padding-right: 1.125rem !important; }

.py-3 {
  padding-top: 1.125rem !important;
  padding-bottom: 1.125rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.pt-5 {
  padding-top: 2rem !important; }

.pr-5 {
  padding-right: 2rem !important; }

.pb-5 {
  padding-bottom: 2rem !important; }

.pl-5 {
  padding-left: 2rem !important; }

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important; }

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.p-6 {
  padding: 2.5rem !important; }

.pt-6 {
  padding-top: 2.5rem !important; }

.pr-6 {
  padding-right: 2.5rem !important; }

.pb-6 {
  padding-bottom: 2.5rem !important; }

.pl-6 {
  padding-left: 2.5rem !important; }

.px-6 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important; }

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.p-7 {
  padding: 3rem !important; }

.pt-7 {
  padding-top: 3rem !important; }

.pr-7 {
  padding-right: 3rem !important; }

.pb-7 {
  padding-bottom: 3rem !important; }

.pl-7 {
  padding-left: 3rem !important; }

.px-7 {
  padding-left: 3rem !important;
  padding-right: 3rem !important; }

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

/*https://bulma.io/documentation/helpers/spacing-helpers/*/
.is-size-1 {
  font-size: 3rem !important; }

.is-size-2 {
  font-size: 2.5rem !important; }

.is-size-3 {
  font-size: 2rem !important; }

.is-size-4 {
  font-size: 1.5rem !important; }

.is-size-5 {
  font-size: 1.125rem !important; }

.is-size-6 {
  font-size: 1rem !important; }

.is-size-7 {
  font-size: 0.875rem !important; }

@media screen and (max-width: 899px) {
  .is-size-1-mobile {
    font-size: 3rem !important; }
  .is-size-2-mobile {
    font-size: 2.5rem !important; }
  .is-size-3-mobile {
    font-size: 2rem !important; }
  .is-size-4-mobile {
    font-size: 1.5rem !important; }
  .is-size-5-mobile {
    font-size: 1.125rem !important; }
  .is-size-6-mobile {
    font-size: 1rem !important; }
  .is-size-7-mobile {
    font-size: 0.875rem !important; } }

@media screen and (min-width: 900px), print {
  .is-size-1-tablet {
    font-size: 3rem !important; }
  .is-size-2-tablet {
    font-size: 2.5rem !important; }
  .is-size-3-tablet {
    font-size: 2rem !important; }
  .is-size-4-tablet {
    font-size: 1.5rem !important; }
  .is-size-5-tablet {
    font-size: 1.125rem !important; }
  .is-size-6-tablet {
    font-size: 1rem !important; }
  .is-size-7-tablet {
    font-size: 0.875rem !important; } }

@media screen and (max-width: 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important; }
  .is-size-2-touch {
    font-size: 2.5rem !important; }
  .is-size-3-touch {
    font-size: 2rem !important; }
  .is-size-4-touch {
    font-size: 1.5rem !important; }
  .is-size-5-touch {
    font-size: 1.125rem !important; }
  .is-size-6-touch {
    font-size: 1rem !important; }
  .is-size-7-touch {
    font-size: 0.875rem !important; } }

@media screen and (min-width: 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important; }
  .is-size-2-desktop {
    font-size: 2.5rem !important; }
  .is-size-3-desktop {
    font-size: 2rem !important; }
  .is-size-4-desktop {
    font-size: 1.5rem !important; }
  .is-size-5-desktop {
    font-size: 1.125rem !important; }
  .is-size-6-desktop {
    font-size: 1rem !important; }
  .is-size-7-desktop {
    font-size: 0.875rem !important; } }

@media screen and (min-width: 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important; }
  .is-size-2-widescreen {
    font-size: 2.5rem !important; }
  .is-size-3-widescreen {
    font-size: 2rem !important; }
  .is-size-4-widescreen {
    font-size: 1.5rem !important; }
  .is-size-5-widescreen {
    font-size: 1.125rem !important; }
  .is-size-6-widescreen {
    font-size: 1rem !important; }
  .is-size-7-widescreen {
    font-size: 0.875rem !important; } }

@media screen and (min-width: 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important; }
  .is-size-2-fullhd {
    font-size: 2.5rem !important; }
  .is-size-3-fullhd {
    font-size: 2rem !important; }
  .is-size-4-fullhd {
    font-size: 1.5rem !important; }
  .is-size-5-fullhd {
    font-size: 1.125rem !important; }
  .is-size-6-fullhd {
    font-size: 1rem !important; }
  .is-size-7-fullhd {
    font-size: 0.875rem !important; } }

.has-text-centered {
  text-align: center !important; }

.has-text-justified {
  text-align: justify !important; }

.has-text-left {
  text-align: left !important; }

.has-text-right {
  text-align: right !important; }

@media screen and (max-width: 899px) {
  .has-text-centered-mobile {
    text-align: center !important; } }

@media screen and (min-width: 900px), print {
  .has-text-centered-tablet {
    text-align: center !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important; } }

@media screen and (max-width: 1023px) {
  .has-text-centered-touch {
    text-align: center !important; } }

@media screen and (min-width: 1024px) {
  .has-text-centered-desktop {
    text-align: center !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important; } }

@media screen and (min-width: 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important; } }

@media screen and (min-width: 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important; } }

@media screen and (max-width: 899px) {
  .has-text-justified-mobile {
    text-align: justify !important; } }

@media screen and (min-width: 900px), print {
  .has-text-justified-tablet {
    text-align: justify !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important; } }

@media screen and (max-width: 1023px) {
  .has-text-justified-touch {
    text-align: justify !important; } }

@media screen and (min-width: 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important; } }

@media screen and (min-width: 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important; } }

@media screen and (min-width: 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important; } }

@media screen and (max-width: 899px) {
  .has-text-left-mobile {
    text-align: left !important; } }

@media screen and (min-width: 900px), print {
  .has-text-left-tablet {
    text-align: left !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important; } }

@media screen and (max-width: 1023px) {
  .has-text-left-touch {
    text-align: left !important; } }

@media screen and (min-width: 1024px) {
  .has-text-left-desktop {
    text-align: left !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important; } }

@media screen and (min-width: 1216px) {
  .has-text-left-widescreen {
    text-align: left !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important; } }

@media screen and (min-width: 1408px) {
  .has-text-left-fullhd {
    text-align: left !important; } }

@media screen and (max-width: 899px) {
  .has-text-right-mobile {
    text-align: right !important; } }

@media screen and (min-width: 900px), print {
  .has-text-right-tablet {
    text-align: right !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important; } }

@media screen and (max-width: 1023px) {
  .has-text-right-touch {
    text-align: right !important; } }

@media screen and (min-width: 1024px) {
  .has-text-right-desktop {
    text-align: right !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important; } }

@media screen and (min-width: 1216px) {
  .has-text-right-widescreen {
    text-align: right !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important; } }

@media screen and (min-width: 1408px) {
  .has-text-right-fullhd {
    text-align: right !important; } }

.is-capitalized {
  text-transform: capitalize !important; }

.is-lowercase {
  text-transform: lowercase !important; }

.is-uppercase {
  text-transform: uppercase !important; }

.is-italic {
  font-style: italic !important; }

.is-underlined {
  text-decoration: underline !important; }

.has-text-weight-light {
  font-weight: 300 !important; }

.has-text-weight-normal {
  font-weight: 400 !important; }

.has-text-weight-medium {
  font-weight: 500 !important; }

.has-text-weight-semibold {
  font-weight: 600 !important; }

.has-text-weight-bold {
  font-weight: 700 !important; }

.is-family-primary {
  font-family: Barlow, Helvetica, Arial, sans-serif !important; }

.is-family-secondary {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif !important; }

.is-family-sans-serif {
  font-family: "Helvetica, Arial, sans-serif" !important; }

.is-family-monospace {
  font-family: monospace !important; }

.is-family-code {
  font-family: monospace !important; }

/*https://bulma.io/documentation/helpers/typography-helpers/*/
.is-block {
  display: block !important; }

@media screen and (max-width: 899px) {
  .is-block-mobile {
    display: block !important; } }

@media screen and (min-width: 900px), print {
  .is-block-tablet {
    display: block !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .is-block-tablet-only {
    display: block !important; } }

@media screen and (max-width: 1023px) {
  .is-block-touch {
    display: block !important; } }

@media screen and (min-width: 1024px) {
  .is-block-desktop {
    display: block !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-block-desktop-only {
    display: block !important; } }

@media screen and (min-width: 1216px) {
  .is-block-widescreen {
    display: block !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-block-widescreen-only {
    display: block !important; } }

@media screen and (min-width: 1408px) {
  .is-block-fullhd {
    display: block !important; } }

.is-flex {
  display: flex !important; }

@media screen and (max-width: 899px) {
  .is-flex-mobile {
    display: flex !important; } }

@media screen and (min-width: 900px), print {
  .is-flex-tablet {
    display: flex !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .is-flex-tablet-only {
    display: flex !important; } }

@media screen and (max-width: 1023px) {
  .is-flex-touch {
    display: flex !important; } }

@media screen and (min-width: 1024px) {
  .is-flex-desktop {
    display: flex !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-flex-desktop-only {
    display: flex !important; } }

@media screen and (min-width: 1216px) {
  .is-flex-widescreen {
    display: flex !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-flex-widescreen-only {
    display: flex !important; } }

@media screen and (min-width: 1408px) {
  .is-flex-fullhd {
    display: flex !important; } }

.is-inline {
  display: inline !important; }

@media screen and (max-width: 899px) {
  .is-inline-mobile {
    display: inline !important; } }

@media screen and (min-width: 900px), print {
  .is-inline-tablet {
    display: inline !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .is-inline-tablet-only {
    display: inline !important; } }

@media screen and (max-width: 1023px) {
  .is-inline-touch {
    display: inline !important; } }

@media screen and (min-width: 1024px) {
  .is-inline-desktop {
    display: inline !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-desktop-only {
    display: inline !important; } }

@media screen and (min-width: 1216px) {
  .is-inline-widescreen {
    display: inline !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-widescreen-only {
    display: inline !important; } }

@media screen and (min-width: 1408px) {
  .is-inline-fullhd {
    display: inline !important; } }

.is-inline-block {
  display: inline-block !important; }

@media screen and (max-width: 899px) {
  .is-inline-block-mobile {
    display: inline-block !important; } }

@media screen and (min-width: 900px), print {
  .is-inline-block-tablet {
    display: inline-block !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important; } }

@media screen and (max-width: 1023px) {
  .is-inline-block-touch {
    display: inline-block !important; } }

@media screen and (min-width: 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important; } }

@media screen and (min-width: 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important; } }

@media screen and (min-width: 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important; } }

.is-inline-flex {
  display: inline-flex !important; }

@media screen and (max-width: 899px) {
  .is-inline-flex-mobile {
    display: inline-flex !important; } }

@media screen and (min-width: 900px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important; } }

@media screen and (max-width: 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important; } }

@media screen and (min-width: 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important; } }

@media screen and (min-width: 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important; } }

@media screen and (min-width: 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important; } }

.is-hidden {
  display: none !important; }

.is-sr-only {
  border: none !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 0.01em !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0.01em !important; }

@media screen and (max-width: 899px) {
  .is-hidden-mobile {
    display: none !important; } }

@media screen and (min-width: 900px), print {
  .is-hidden-tablet {
    display: none !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .is-hidden-tablet-only {
    display: none !important; } }

@media screen and (max-width: 1023px) {
  .is-hidden-touch {
    display: none !important; } }

@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-hidden-desktop-only {
    display: none !important; } }

@media screen and (min-width: 1216px) {
  .is-hidden-widescreen {
    display: none !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-hidden-widescreen-only {
    display: none !important; } }

@media screen and (min-width: 1408px) {
  .is-hidden-fullhd {
    display: none !important; } }

.is-invisible {
  visibility: hidden !important; }

@media screen and (max-width: 899px) {
  .is-invisible-mobile {
    visibility: hidden !important; } }

@media screen and (min-width: 900px), print {
  .is-invisible-tablet {
    visibility: hidden !important; } }

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important; } }

@media screen and (max-width: 1023px) {
  .is-invisible-touch {
    visibility: hidden !important; } }

@media screen and (min-width: 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important; } }

@media screen and (min-width: 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important; } }

@media screen and (min-width: 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important; } }

/*https://bulma.io/documentation/helpers/visibility-helpers/*/
/* Bulma Layout */
.section {
  padding: 1rem 1.125rem; }
  @media screen and (min-width: 1024px) {
    .section {
      padding: 3rem 3rem; }
      .section.is-medium {
        padding: 1.5rem 1.125rem; }
      .section.is-large {
        padding: 2.5rem 1.125rem; } }

.is-checkradio[type="radio"],
.is-checkradio[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0; }
  .is-checkradio[type="radio"] + label,
  .is-checkradio[type="checkbox"] + label {
    position: relative;
    display: initial;
    cursor: pointer;
    vertical-align: middle;
    margin: .5em;
    padding: .2rem .5rem .2rem 0;
    border-radius: 4px; }
    .is-checkradio[type="radio"] + label:first-of-type,
    .is-checkradio[type="checkbox"] + label:first-of-type {
      margin-left: 0; }
    .is-checkradio[type="radio"] + label:hover::before, .is-checkradio[type="radio"] + label:hover:before,
    .is-checkradio[type="checkbox"] + label:hover::before,
    .is-checkradio[type="checkbox"] + label:hover:before {
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: hover-color; }
    .is-checkradio[type="radio"] + label::before, .is-checkradio[type="radio"] + label:before,
    .is-checkradio[type="checkbox"] + label::before,
    .is-checkradio[type="checkbox"] + label:before {
      position: absolute;
      left: 0;
      top: 0rem;
      content: '';
      border: 0.1rem solid #dbdbdb; }
    .is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label:after,
    .is-checkradio[type="checkbox"] + label::after,
    .is-checkradio[type="checkbox"] + label:after {
      position: absolute;
      display: none;
      content: '';
      top: 0rem; }
  .is-checkradio[type="radio"].is-rtl + label,
  .is-checkradio[type="checkbox"].is-rtl + label {
    margin-right: 0rem;
    margin-left: 0.5rem; }
    .is-checkradio[type="radio"].is-rtl + label::before, .is-checkradio[type="radio"].is-rtl + label:before,
    .is-checkradio[type="checkbox"].is-rtl + label::before,
    .is-checkradio[type="checkbox"].is-rtl + label:before {
      left: auto;
      right: 0; }
  .is-checkradio[type="radio"]:focus + label::before, .is-checkradio[type="radio"]:focus + label:before,
  .is-checkradio[type="checkbox"]:focus + label::before,
  .is-checkradio[type="checkbox"]:focus + label:before {
    outline: inherit; }
  .is-checkradio[type="radio"]:hover:not([disabled]) + label::before, .is-checkradio[type="radio"]:hover:not([disabled]) + label:before,
  .is-checkradio[type="checkbox"]:hover:not([disabled]) + label::before,
  .is-checkradio[type="checkbox"]:hover:not([disabled]) + label:before {
    border-color: #009ba9 !important; }
  .is-checkradio[type="radio"]:checked + label::before, .is-checkradio[type="radio"]:checked + label:before,
  .is-checkradio[type="checkbox"]:checked + label::before,
  .is-checkradio[type="checkbox"]:checked + label:before {
    border: 0.1rem solid #dbdbdb; }
  .is-checkradio[type="radio"]:checked[disabled],
  .is-checkradio[type="checkbox"]:checked[disabled] {
    cursor: not-allowed; }
    .is-checkradio[type="radio"]:checked[disabled] + label,
    .is-checkradio[type="checkbox"]:checked[disabled] + label {
      opacity: 0.5; }
  .is-checkradio[type="radio"]:checked + label::before, .is-checkradio[type="radio"]:checked + label:before,
  .is-checkradio[type="checkbox"]:checked + label::before,
  .is-checkradio[type="checkbox"]:checked + label:before {
    animation-name: none; }
  .is-checkradio[type="radio"]:checked + label::after, .is-checkradio[type="radio"]:checked + label:after,
  .is-checkradio[type="checkbox"]:checked + label::after,
  .is-checkradio[type="checkbox"]:checked + label:after {
    display: inline-block; }
  .is-checkradio[type="radio"][disabled],
  .is-checkradio[type="checkbox"][disabled] {
    cursor: not-allowed; }
    .is-checkradio[type="radio"][disabled] + label,
    .is-checkradio[type="checkbox"][disabled] + label {
      opacity: 0.5;
      cursor: not-allowed; }
      .is-checkradio[type="radio"][disabled] + label:hover, .is-checkradio[type="radio"][disabled] + label:before, .is-checkradio[type="radio"][disabled] + label::before, .is-checkradio[type="radio"][disabled] + label::after, .is-checkradio[type="radio"][disabled] + label:after,
      .is-checkradio[type="checkbox"][disabled] + label:hover,
      .is-checkradio[type="checkbox"][disabled] + label:before,
      .is-checkradio[type="checkbox"][disabled] + label::before,
      .is-checkradio[type="checkbox"][disabled] + label::after,
      .is-checkradio[type="checkbox"][disabled] + label:after {
        cursor: not-allowed; }
    .is-checkradio[type="radio"][disabled]:hover,
    .is-checkradio[type="checkbox"][disabled]:hover {
      cursor: not-allowed; }
      .is-checkradio[type="radio"][disabled]:hover::before, .is-checkradio[type="radio"][disabled]:hover:before,
      .is-checkradio[type="checkbox"][disabled]:hover::before,
      .is-checkradio[type="checkbox"][disabled]:hover:before {
        animation-name: none; }
    .is-checkradio[type="radio"][disabled]::before, .is-checkradio[type="radio"][disabled]:before,
    .is-checkradio[type="checkbox"][disabled]::before,
    .is-checkradio[type="checkbox"][disabled]:before {
      cursor: not-allowed; }
    .is-checkradio[type="radio"][disabled]::after, .is-checkradio[type="radio"][disabled]:after,
    .is-checkradio[type="checkbox"][disabled]::after,
    .is-checkradio[type="checkbox"][disabled]:after {
      cursor: not-allowed; }
  .is-checkradio[type="radio"].has-no-border + label::before, .is-checkradio[type="radio"].has-no-border + label:before,
  .is-checkradio[type="checkbox"].has-no-border + label::before,
  .is-checkradio[type="checkbox"].has-no-border + label:before {
    border: none !important; }
  .is-checkradio[type="radio"].is-block,
  .is-checkradio[type="checkbox"].is-block {
    display: none !important; }
    .is-checkradio[type="radio"].is-block + label,
    .is-checkradio[type="checkbox"].is-block + label {
      width: 100% !important;
      background: whitesmoke;
      color: rgba(0, 0, 0, 0.7);
      padding-right: .75em; }
    .is-checkradio[type="radio"].is-block:hover:not([disabled]) + label,
    .is-checkradio[type="checkbox"].is-block:hover:not([disabled]) + label {
      background: #e8e8e8; }

.is-checkradio[type="checkbox"] + label::before, .is-checkradio[type="checkbox"] + label:before {
  border-radius: 4px; }

.is-checkradio[type="checkbox"] + label::after, .is-checkradio[type="checkbox"] + label:after {
  box-sizing: border-box;
  transform: translateY(0rem) rotate(45deg);
  border-width: 3px;
  border-style: solid;
  border-color: #009ba9;
  border-top: 0;
  border-left: 0; }

.is-checkradio[type="checkbox"].is-circle + label::before, .is-checkradio[type="checkbox"].is-circle + label:before {
  border-radius: 50%; }

.is-checkradio[type="checkbox"] + label {
  font-size: 1rem;
  padding-left: 2rem; }
  .is-checkradio[type="checkbox"] + label::before, .is-checkradio[type="checkbox"] + label:before {
    width: 1.5rem;
    height: 1.5rem; }
  .is-checkradio[type="checkbox"] + label::after, .is-checkradio[type="checkbox"] + label:after {
    width: 0.375rem;
    height: 0.6rem;
    top: 0.405rem;
    left: 0.6rem; }

.is-checkradio[type="checkbox"].is-block + label::before, .is-checkradio[type="checkbox"].is-block + label:before {
  width: 1.25rem;
  height: 1.25rem;
  left: .175rem;
  top: .175rem; }

.is-checkradio[type="checkbox"].is-block + label::after, .is-checkradio[type="checkbox"].is-block + label:after {
  top: 0.325rem;
  left: 0.65rem; }

.is-checkradio[type="checkbox"].is-rtl + label {
  padding-left: 0;
  padding-right: 2rem; }
  .is-checkradio[type="checkbox"].is-rtl + label::after, .is-checkradio[type="checkbox"].is-rtl + label:after {
    left: auto;
    right: 0.6rem; }

.is-checkradio[type="checkbox"].is-small + label {
  font-size: 0.75rem;
  padding-left: 1.5rem; }
  .is-checkradio[type="checkbox"].is-small + label::before, .is-checkradio[type="checkbox"].is-small + label:before {
    width: 1.125rem;
    height: 1.125rem; }
  .is-checkradio[type="checkbox"].is-small + label::after, .is-checkradio[type="checkbox"].is-small + label:after {
    width: 0.28125rem;
    height: 0.45rem;
    top: 0.30375rem;
    left: 0.45rem; }

.is-checkradio[type="checkbox"].is-small.is-block + label::before, .is-checkradio[type="checkbox"].is-small.is-block + label:before {
  width: 0.9375rem;
  height: 0.9375rem;
  left: .175rem;
  top: .175rem; }

.is-checkradio[type="checkbox"].is-small.is-block + label::after, .is-checkradio[type="checkbox"].is-small.is-block + label:after {
  top: 0.29375rem;
  left: 0.5375rem; }

.is-checkradio[type="checkbox"].is-small.is-rtl + label {
  padding-left: 0;
  padding-right: 1.5rem; }
  .is-checkradio[type="checkbox"].is-small.is-rtl + label::after, .is-checkradio[type="checkbox"].is-small.is-rtl + label:after {
    left: auto;
    right: 0.45rem; }

.is-checkradio[type="checkbox"].is-medium + label {
  font-size: 1.333rem;
  padding-left: 2.666rem; }
  .is-checkradio[type="checkbox"].is-medium + label::before, .is-checkradio[type="checkbox"].is-medium + label:before {
    width: 1.9995rem;
    height: 1.9995rem; }
  .is-checkradio[type="checkbox"].is-medium + label::after, .is-checkradio[type="checkbox"].is-medium + label:after {
    width: 0.49987rem;
    height: 0.7998rem;
    top: 0.53987rem;
    left: 0.7998rem; }

.is-checkradio[type="checkbox"].is-medium.is-block + label::before, .is-checkradio[type="checkbox"].is-medium.is-block + label:before {
  width: 1.66625rem;
  height: 1.66625rem;
  left: .175rem;
  top: .175rem; }

.is-checkradio[type="checkbox"].is-medium.is-block + label::after, .is-checkradio[type="checkbox"].is-medium.is-block + label:after {
  top: 0.36662rem;
  left: 0.79985rem; }

.is-checkradio[type="checkbox"].is-medium.is-rtl + label {
  padding-left: 0;
  padding-right: 2.666rem; }
  .is-checkradio[type="checkbox"].is-medium.is-rtl + label::after, .is-checkradio[type="checkbox"].is-medium.is-rtl + label:after {
    left: auto;
    right: 0.7998rem; }

.is-checkradio[type="checkbox"].is-large + label {
  font-size: 1.777rem;
  padding-left: 3.554rem; }
  .is-checkradio[type="checkbox"].is-large + label::before, .is-checkradio[type="checkbox"].is-large + label:before {
    width: 2.6655rem;
    height: 2.6655rem; }
  .is-checkradio[type="checkbox"].is-large + label::after, .is-checkradio[type="checkbox"].is-large + label:after {
    width: 0.66637rem;
    height: 1.0662rem;
    top: 0.71969rem;
    left: 1.0662rem; }

.is-checkradio[type="checkbox"].is-large.is-block + label::before, .is-checkradio[type="checkbox"].is-large.is-block + label:before {
  width: 2.22125rem;
  height: 2.22125rem;
  left: .175rem;
  top: .175rem; }

.is-checkradio[type="checkbox"].is-large.is-block + label::after, .is-checkradio[type="checkbox"].is-large.is-block + label:after {
  top: 0.42212rem;
  left: 0.99965rem; }

.is-checkradio[type="checkbox"].is-large.is-rtl + label {
  padding-left: 0;
  padding-right: 3.554rem; }
  .is-checkradio[type="checkbox"].is-large.is-rtl + label::after, .is-checkradio[type="checkbox"].is-large.is-rtl + label:after {
    left: auto;
    right: 1.0662rem; }

.is-checkradio[type="checkbox"].is-white.has-background-color + label::before, .is-checkradio[type="checkbox"].is-white.has-background-color + label:before {
  border-color: transparent !important;
  background-color: white !important; }

.is-checkradio[type="checkbox"].is-white:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-white:hover:not([disabled]) + label:before {
  border-color: white !important; }

.is-checkradio[type="checkbox"].is-white:checked + label::after, .is-checkradio[type="checkbox"].is-white:checked + label:after {
  border-color: white !important; }

.is-checkradio[type="checkbox"].is-white:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-white:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: white !important; }

.is-checkradio[type="checkbox"].is-white:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-white:checked.has-background-color + label:after {
  border-color: #0a0a0a !important;
  background-color: white !important; }

.is-checkradio[type="checkbox"].is-white.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-white.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-white.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-white.is-block:hover:not([disabled]) + label:before {
  border-color: white !important; }

.is-checkradio[type="checkbox"].is-white.is-block:checked + label {
  color: #0a0a0a;
  border-color: white !important;
  background: white; }
  .is-checkradio[type="checkbox"].is-white.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-white.is-block:checked + label:after {
    border-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label {
  background: #f2f2f2; }
  .is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label:before {
    border-color: black !important; }

.is-checkradio[type="checkbox"].is-black.has-background-color + label::before, .is-checkradio[type="checkbox"].is-black.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-black:hover:not([disabled]) + label:before {
  border-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black:checked + label::after, .is-checkradio[type="checkbox"].is-black:checked + label:after {
  border-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-black:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-black:checked.has-background-color + label:after {
  border-color: white !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-black.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-black.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-black.is-block:hover:not([disabled]) + label:before {
  border-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black.is-block:checked + label {
  color: white;
  border-color: #0a0a0a !important;
  background: #0a0a0a; }
  .is-checkradio[type="checkbox"].is-black.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-black.is-block:checked + label:after {
    border-color: white !important; }

.is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label {
  background: black; }
  .is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-light.has-background-color + label::before, .is-checkradio[type="checkbox"].is-light.has-background-color + label:before {
  border-color: transparent !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-light:hover:not([disabled]) + label:before {
  border-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light:checked + label::after, .is-checkradio[type="checkbox"].is-light:checked + label:after {
  border-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-light:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-light.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-light.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-light.is-block:hover:not([disabled]) + label:before {
  border-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: whitesmoke !important;
  background: whitesmoke; }
  .is-checkradio[type="checkbox"].is-light.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-light.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label {
  background: #e8e8e8; }
  .is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-dark.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #4f6567 !important; }

.is-checkradio[type="checkbox"].is-dark:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark:hover:not([disabled]) + label:before {
  border-color: #4f6567 !important; }

.is-checkradio[type="checkbox"].is-dark:checked + label::after, .is-checkradio[type="checkbox"].is-dark:checked + label:after {
  border-color: #4f6567 !important; }

.is-checkradio[type="checkbox"].is-dark:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #4f6567 !important; }

.is-checkradio[type="checkbox"].is-dark:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-dark:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #4f6567 !important; }

.is-checkradio[type="checkbox"].is-dark.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark.is-block:hover:not([disabled]) + label:before {
  border-color: #4f6567 !important; }

.is-checkradio[type="checkbox"].is-dark.is-block:checked + label {
  color: #fff;
  border-color: #4f6567 !important;
  background: #4f6567; }
  .is-checkradio[type="checkbox"].is-dark.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-dark.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label {
  background: #445759; }
  .is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-primary.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary:hover:not([disabled]) + label:before {
  border-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary:checked + label::after, .is-checkradio[type="checkbox"].is-primary:checked + label:after {
  border-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary.is-block:hover:not([disabled]) + label:before {
  border-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary.is-block:checked + label {
  color: #fff;
  border-color: #009ba9 !important;
  background: #009ba9; }
  .is-checkradio[type="checkbox"].is-primary.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-primary.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label {
  background: #008490; }
  .is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-link.has-background-color + label::before, .is-checkradio[type="checkbox"].is-link.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-link:hover:not([disabled]) + label:before {
  border-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link:checked + label::after, .is-checkradio[type="checkbox"].is-link:checked + label:after {
  border-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-link:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-link:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-link.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-link.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-link.is-block:hover:not([disabled]) + label:before {
  border-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link.is-block:checked + label {
  color: #fff;
  border-color: #485fc7 !important;
  background: #485fc7; }
  .is-checkradio[type="checkbox"].is-link.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-link.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label {
  background: #3a51bb; }
  .is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-info.has-background-color + label::before, .is-checkradio[type="checkbox"].is-info.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-info:hover:not([disabled]) + label:before {
  border-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info:checked + label::after, .is-checkradio[type="checkbox"].is-info:checked + label:after {
  border-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-info:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-info:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-info.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-info.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-info.is-block:hover:not([disabled]) + label:before {
  border-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info.is-block:checked + label {
  color: #fff;
  border-color: #3e8ed0 !important;
  background: #3e8ed0; }
  .is-checkradio[type="checkbox"].is-info.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-info.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label {
  background: #3082c5; }
  .is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-success.has-background-color + label::before, .is-checkradio[type="checkbox"].is-success.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #48c78e !important; }

.is-checkradio[type="checkbox"].is-success:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-success:hover:not([disabled]) + label:before {
  border-color: #48c78e !important; }

.is-checkradio[type="checkbox"].is-success:checked + label::after, .is-checkradio[type="checkbox"].is-success:checked + label:after {
  border-color: #48c78e !important; }

.is-checkradio[type="checkbox"].is-success:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-success:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #48c78e !important; }

.is-checkradio[type="checkbox"].is-success:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-success:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #48c78e !important; }

.is-checkradio[type="checkbox"].is-success.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-success.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-success.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-success.is-block:hover:not([disabled]) + label:before {
  border-color: #48c78e !important; }

.is-checkradio[type="checkbox"].is-success.is-block:checked + label {
  color: #fff;
  border-color: #48c78e !important;
  background: #48c78e; }
  .is-checkradio[type="checkbox"].is-success.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-success.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label {
  background: #3abb81; }
  .is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-warning.has-background-color + label::before, .is-checkradio[type="checkbox"].is-warning.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-warning:hover:not([disabled]) + label:before {
  border-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning:checked + label::after, .is-checkradio[type="checkbox"].is-warning:checked + label:after {
  border-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-warning:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-warning:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-warning.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-warning.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-warning.is-block:hover:not([disabled]) + label:before {
  border-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #ffe08a !important;
  background: #ffe08a; }
  .is-checkradio[type="checkbox"].is-warning.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-warning.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label {
  background: #ffd970; }
  .is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-danger.has-background-color + label::before, .is-checkradio[type="checkbox"].is-danger.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #f14668 !important; }

.is-checkradio[type="checkbox"].is-danger:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-danger:hover:not([disabled]) + label:before {
  border-color: #f14668 !important; }

.is-checkradio[type="checkbox"].is-danger:checked + label::after, .is-checkradio[type="checkbox"].is-danger:checked + label:after {
  border-color: #f14668 !important; }

.is-checkradio[type="checkbox"].is-danger:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-danger:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #f14668 !important; }

.is-checkradio[type="checkbox"].is-danger:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-danger:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #f14668 !important; }

.is-checkradio[type="checkbox"].is-danger.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-danger.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-danger.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-danger.is-block:hover:not([disabled]) + label:before {
  border-color: #f14668 !important; }

.is-checkradio[type="checkbox"].is-danger.is-block:checked + label {
  color: #fff;
  border-color: #f14668 !important;
  background: #f14668; }
  .is-checkradio[type="checkbox"].is-danger.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-danger.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label {
  background: #ef2e55; }
  .is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-primary-brand-1.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary-brand-1.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary-brand-1:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-brand-1:hover:not([disabled]) + label:before {
  border-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary-brand-1:checked + label::after, .is-checkradio[type="checkbox"].is-primary-brand-1:checked + label:after {
  border-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary-brand-1:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary-brand-1:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary-brand-1:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-primary-brand-1:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary-brand-1.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:hover:not([disabled]) + label:before {
  border-color: #009ba9 !important; }

.is-checkradio[type="checkbox"].is-primary-brand-1.is-block:checked + label {
  color: #fff;
  border-color: #009ba9 !important;
  background: #009ba9; }
  .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-primary-brand-1.is-block:checked:hover:not([disabled]) + label {
  background: #008490; }
  .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-brand-1.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-primary-brand-2.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary-brand-2.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #95c11f !important; }

.is-checkradio[type="checkbox"].is-primary-brand-2:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-brand-2:hover:not([disabled]) + label:before {
  border-color: #95c11f !important; }

.is-checkradio[type="checkbox"].is-primary-brand-2:checked + label::after, .is-checkradio[type="checkbox"].is-primary-brand-2:checked + label:after {
  border-color: #95c11f !important; }

.is-checkradio[type="checkbox"].is-primary-brand-2:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary-brand-2:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #95c11f !important; }

.is-checkradio[type="checkbox"].is-primary-brand-2:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-primary-brand-2:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #95c11f !important; }

.is-checkradio[type="checkbox"].is-primary-brand-2.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:hover:not([disabled]) + label:before {
  border-color: #95c11f !important; }

.is-checkradio[type="checkbox"].is-primary-brand-2.is-block:checked + label {
  color: #fff;
  border-color: #95c11f !important;
  background: #95c11f; }
  .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-primary-brand-2.is-block:checked:hover:not([disabled]) + label {
  background: #84ab1b; }
  .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-brand-2.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-blue-bg-1.has-background-color + label::before, .is-checkradio[type="checkbox"].is-blue-bg-1.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #78cbd0 !important; }

.is-checkradio[type="checkbox"].is-blue-bg-1:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-blue-bg-1:hover:not([disabled]) + label:before {
  border-color: #78cbd0 !important; }

.is-checkradio[type="checkbox"].is-blue-bg-1:checked + label::after, .is-checkradio[type="checkbox"].is-blue-bg-1:checked + label:after {
  border-color: #78cbd0 !important; }

.is-checkradio[type="checkbox"].is-blue-bg-1:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-blue-bg-1:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #78cbd0 !important; }

.is-checkradio[type="checkbox"].is-blue-bg-1:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-blue-bg-1:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #78cbd0 !important; }

.is-checkradio[type="checkbox"].is-blue-bg-1.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:hover:not([disabled]) + label:before {
  border-color: #78cbd0 !important; }

.is-checkradio[type="checkbox"].is-blue-bg-1.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #78cbd0 !important;
  background: #78cbd0; }
  .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-blue-bg-1.is-block:checked:hover:not([disabled]) + label {
  background: #65c4c9; }
  .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-blue-bg-1.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-blue-bg-2.has-background-color + label::before, .is-checkradio[type="checkbox"].is-blue-bg-2.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #c0eaee !important; }

.is-checkradio[type="checkbox"].is-blue-bg-2:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-blue-bg-2:hover:not([disabled]) + label:before {
  border-color: #c0eaee !important; }

.is-checkradio[type="checkbox"].is-blue-bg-2:checked + label::after, .is-checkradio[type="checkbox"].is-blue-bg-2:checked + label:after {
  border-color: #c0eaee !important; }

.is-checkradio[type="checkbox"].is-blue-bg-2:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-blue-bg-2:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #c0eaee !important; }

.is-checkradio[type="checkbox"].is-blue-bg-2:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-blue-bg-2:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #c0eaee !important; }

.is-checkradio[type="checkbox"].is-blue-bg-2.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:hover:not([disabled]) + label:before {
  border-color: #c0eaee !important; }

.is-checkradio[type="checkbox"].is-blue-bg-2.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #c0eaee !important;
  background: #c0eaee; }
  .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-blue-bg-2.is-block:checked:hover:not([disabled]) + label {
  background: #ace3e9; }
  .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-blue-bg-2.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-green-bg.has-background-color + label::before, .is-checkradio[type="checkbox"].is-green-bg.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #bed974 !important; }

.is-checkradio[type="checkbox"].is-green-bg:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-green-bg:hover:not([disabled]) + label:before {
  border-color: #bed974 !important; }

.is-checkradio[type="checkbox"].is-green-bg:checked + label::after, .is-checkradio[type="checkbox"].is-green-bg:checked + label:after {
  border-color: #bed974 !important; }

.is-checkradio[type="checkbox"].is-green-bg:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-green-bg:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #bed974 !important; }

.is-checkradio[type="checkbox"].is-green-bg:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-green-bg:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #bed974 !important; }

.is-checkradio[type="checkbox"].is-green-bg.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-green-bg.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-green-bg.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-green-bg.is-block:hover:not([disabled]) + label:before {
  border-color: #bed974 !important; }

.is-checkradio[type="checkbox"].is-green-bg.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #bed974 !important;
  background: #bed974; }
  .is-checkradio[type="checkbox"].is-green-bg.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-green-bg.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-green-bg.is-block:checked:hover:not([disabled]) + label {
  background: #b5d460; }
  .is-checkradio[type="checkbox"].is-green-bg.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-green-bg.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-green-bg.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-green-bg.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-green-light.has-background-color + label::before, .is-checkradio[type="checkbox"].is-green-light.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #eef8d3 !important; }

.is-checkradio[type="checkbox"].is-green-light:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-green-light:hover:not([disabled]) + label:before {
  border-color: #eef8d3 !important; }

.is-checkradio[type="checkbox"].is-green-light:checked + label::after, .is-checkradio[type="checkbox"].is-green-light:checked + label:after {
  border-color: #eef8d3 !important; }

.is-checkradio[type="checkbox"].is-green-light:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-green-light:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #eef8d3 !important; }

.is-checkradio[type="checkbox"].is-green-light:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-green-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #eef8d3 !important; }

.is-checkradio[type="checkbox"].is-green-light.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-green-light.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-green-light.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-green-light.is-block:hover:not([disabled]) + label:before {
  border-color: #eef8d3 !important; }

.is-checkradio[type="checkbox"].is-green-light.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #eef8d3 !important;
  background: #eef8d3; }
  .is-checkradio[type="checkbox"].is-green-light.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-green-light.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-green-light.is-block:checked:hover:not([disabled]) + label {
  background: #e6f5bd; }
  .is-checkradio[type="checkbox"].is-green-light.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-green-light.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-green-light.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-green-light.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-darker.has-background-color + label::before, .is-checkradio[type="checkbox"].is-darker.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #273d3f !important; }

.is-checkradio[type="checkbox"].is-darker:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-darker:hover:not([disabled]) + label:before {
  border-color: #273d3f !important; }

.is-checkradio[type="checkbox"].is-darker:checked + label::after, .is-checkradio[type="checkbox"].is-darker:checked + label:after {
  border-color: #273d3f !important; }

.is-checkradio[type="checkbox"].is-darker:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-darker:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #273d3f !important; }

.is-checkradio[type="checkbox"].is-darker:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-darker:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #273d3f !important; }

.is-checkradio[type="checkbox"].is-darker.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-darker.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-darker.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-darker.is-block:hover:not([disabled]) + label:before {
  border-color: #273d3f !important; }

.is-checkradio[type="checkbox"].is-darker.is-block:checked + label {
  color: #fff;
  border-color: #273d3f !important;
  background: #273d3f; }
  .is-checkradio[type="checkbox"].is-darker.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-darker.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-darker.is-block:checked:hover:not([disabled]) + label {
  background: #1d2e2f; }
  .is-checkradio[type="checkbox"].is-darker.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-darker.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-darker.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-darker.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-dark-mild.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark-mild.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #789497 !important; }

.is-checkradio[type="checkbox"].is-dark-mild:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-mild:hover:not([disabled]) + label:before {
  border-color: #789497 !important; }

.is-checkradio[type="checkbox"].is-dark-mild:checked + label::after, .is-checkradio[type="checkbox"].is-dark-mild:checked + label:after {
  border-color: #789497 !important; }

.is-checkradio[type="checkbox"].is-dark-mild:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark-mild:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #789497 !important; }

.is-checkradio[type="checkbox"].is-dark-mild:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-dark-mild:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #789497 !important; }

.is-checkradio[type="checkbox"].is-dark-mild.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark-mild.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark-mild.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-mild.is-block:hover:not([disabled]) + label:before {
  border-color: #789497 !important; }

.is-checkradio[type="checkbox"].is-dark-mild.is-block:checked + label {
  color: #fff;
  border-color: #789497 !important;
  background: #789497; }
  .is-checkradio[type="checkbox"].is-dark-mild.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-dark-mild.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-dark-mild.is-block:checked:hover:not([disabled]) + label {
  background: #6b888b; }
  .is-checkradio[type="checkbox"].is-dark-mild.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark-mild.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark-mild.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-mild.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-dark-light.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark-light.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #a8c1c3 !important; }

.is-checkradio[type="checkbox"].is-dark-light:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-light:hover:not([disabled]) + label:before {
  border-color: #a8c1c3 !important; }

.is-checkradio[type="checkbox"].is-dark-light:checked + label::after, .is-checkradio[type="checkbox"].is-dark-light:checked + label:after {
  border-color: #a8c1c3 !important; }

.is-checkradio[type="checkbox"].is-dark-light:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark-light:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #a8c1c3 !important; }

.is-checkradio[type="checkbox"].is-dark-light:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-dark-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #a8c1c3 !important; }

.is-checkradio[type="checkbox"].is-dark-light.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark-light.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark-light.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-light.is-block:hover:not([disabled]) + label:before {
  border-color: #a8c1c3 !important; }

.is-checkradio[type="checkbox"].is-dark-light.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #a8c1c3 !important;
  background: #a8c1c3; }
  .is-checkradio[type="checkbox"].is-dark-light.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-dark-light.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-dark-light.is-block:checked:hover:not([disabled]) + label {
  background: #99b6b9; }
  .is-checkradio[type="checkbox"].is-dark-light.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark-light.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark-light.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-light.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-dark-lightest.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark-lightest.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #eff3f3 !important; }

.is-checkradio[type="checkbox"].is-dark-lightest:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-lightest:hover:not([disabled]) + label:before {
  border-color: #eff3f3 !important; }

.is-checkradio[type="checkbox"].is-dark-lightest:checked + label::after, .is-checkradio[type="checkbox"].is-dark-lightest:checked + label:after {
  border-color: #eff3f3 !important; }

.is-checkradio[type="checkbox"].is-dark-lightest:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark-lightest:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #eff3f3 !important; }

.is-checkradio[type="checkbox"].is-dark-lightest:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-dark-lightest:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #eff3f3 !important; }

.is-checkradio[type="checkbox"].is-dark-lightest.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark-lightest.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark-lightest.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-lightest.is-block:hover:not([disabled]) + label:before {
  border-color: #eff3f3 !important; }

.is-checkradio[type="checkbox"].is-dark-lightest.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #eff3f3 !important;
  background: #eff3f3; }
  .is-checkradio[type="checkbox"].is-dark-lightest.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-dark-lightest.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-dark-lightest.is-block:checked:hover:not([disabled]) + label {
  background: #e0e8e8; }
  .is-checkradio[type="checkbox"].is-dark-lightest.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark-lightest.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark-lightest.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark-lightest.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"]:indeterminate + label::after, .is-checkradio[type="checkbox"]:indeterminate + label:after {
  display: inline-block;
  transform: rotate(90deg);
  border-bottom: none; }

.is-checkradio[type="checkbox"]:indeterminate.is-white + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-white + label:after {
  border-color: white; }

.is-checkradio[type="checkbox"]:indeterminate.is-black + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-black + label:after {
  border-color: #0a0a0a; }

.is-checkradio[type="checkbox"]:indeterminate.is-light + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-light + label:after {
  border-color: whitesmoke; }

.is-checkradio[type="checkbox"]:indeterminate.is-dark + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-dark + label:after {
  border-color: #4f6567; }

.is-checkradio[type="checkbox"]:indeterminate.is-primary + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-primary + label:after {
  border-color: #009ba9; }

.is-checkradio[type="checkbox"]:indeterminate.is-link + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-link + label:after {
  border-color: #485fc7; }

.is-checkradio[type="checkbox"]:indeterminate.is-info + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-info + label:after {
  border-color: #3e8ed0; }

.is-checkradio[type="checkbox"]:indeterminate.is-success + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-success + label:after {
  border-color: #48c78e; }

.is-checkradio[type="checkbox"]:indeterminate.is-warning + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-warning + label:after {
  border-color: #ffe08a; }

.is-checkradio[type="checkbox"]:indeterminate.is-danger + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-danger + label:after {
  border-color: #f14668; }

.is-checkradio[type="checkbox"]:indeterminate.is-primary-brand-1 + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-primary-brand-1 + label:after {
  border-color: #009ba9; }

.is-checkradio[type="checkbox"]:indeterminate.is-primary-brand-2 + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-primary-brand-2 + label:after {
  border-color: #95c11f; }

.is-checkradio[type="checkbox"]:indeterminate.is-blue-bg-1 + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-blue-bg-1 + label:after {
  border-color: #78cbd0; }

.is-checkradio[type="checkbox"]:indeterminate.is-blue-bg-2 + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-blue-bg-2 + label:after {
  border-color: #c0eaee; }

.is-checkradio[type="checkbox"]:indeterminate.is-green-bg + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-green-bg + label:after {
  border-color: #bed974; }

.is-checkradio[type="checkbox"]:indeterminate.is-green-light + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-green-light + label:after {
  border-color: #eef8d3; }

.is-checkradio[type="checkbox"]:indeterminate.is-darker + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-darker + label:after {
  border-color: #273d3f; }

.is-checkradio[type="checkbox"]:indeterminate.is-dark-mild + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-dark-mild + label:after {
  border-color: #789497; }

.is-checkradio[type="checkbox"]:indeterminate.is-dark-light + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-dark-light + label:after {
  border-color: #a8c1c3; }

.is-checkradio[type="checkbox"]:indeterminate.is-dark-lightest + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-dark-lightest + label:after {
  border-color: #eff3f3; }

.is-checkradio[type="radio"] + label::before, .is-checkradio[type="radio"] + label:before {
  border-radius: 50%; }

.is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label:after {
  border-radius: 50%;
  background: #009ba9;
  left: 0;
  transform: scale(0.5); }

.is-checkradio[type="radio"]:checked.has-background-color + label::before, .is-checkradio[type="radio"]:checked.has-background-color + label:before {
  border-color: #4a4a4a !important;
  background-color: #4a4a4a !important; }

.is-checkradio[type="radio"]:checked.has-background-color + label::after, .is-checkradio[type="radio"]:checked.has-background-color + label:after {
  border-color: #4a4a4a !important;
  background-color: #4a4a4a !important; }

.is-checkradio[type="radio"].is-rtl + label {
  padding-left: 0; }
  .is-checkradio[type="radio"].is-rtl + label::after, .is-checkradio[type="radio"].is-rtl + label:after {
    left: auto;
    right: 0; }

.is-checkradio[type="radio"] + label {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 2rem; }
  .is-checkradio[type="radio"] + label::before, .is-checkradio[type="radio"] + label:before, .is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label:after {
    width: 1.5rem;
    height: 1.5rem; }

.is-checkradio[type="radio"].is-rtl + label {
  padding-right: 2rem; }

.is-checkradio[type="radio"].is-small + label {
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding-left: 1.5rem; }
  .is-checkradio[type="radio"].is-small + label::before, .is-checkradio[type="radio"].is-small + label:before, .is-checkradio[type="radio"].is-small + label::after, .is-checkradio[type="radio"].is-small + label:after {
    width: 1.125rem;
    height: 1.125rem; }

.is-checkradio[type="radio"].is-small.is-rtl + label {
  padding-right: 1.5rem; }

.is-checkradio[type="radio"].is-medium + label {
  font-size: 1.333rem;
  line-height: 1.9995rem;
  padding-left: 2.666rem; }
  .is-checkradio[type="radio"].is-medium + label::before, .is-checkradio[type="radio"].is-medium + label:before, .is-checkradio[type="radio"].is-medium + label::after, .is-checkradio[type="radio"].is-medium + label:after {
    width: 1.9995rem;
    height: 1.9995rem; }

.is-checkradio[type="radio"].is-medium.is-rtl + label {
  padding-right: 2.666rem; }

.is-checkradio[type="radio"].is-large + label {
  font-size: 1.777rem;
  line-height: 2.6655rem;
  padding-left: 3.554rem; }
  .is-checkradio[type="radio"].is-large + label::before, .is-checkradio[type="radio"].is-large + label:before, .is-checkradio[type="radio"].is-large + label::after, .is-checkradio[type="radio"].is-large + label:after {
    width: 2.6655rem;
    height: 2.6655rem; }

.is-checkradio[type="radio"].is-large.is-rtl + label {
  padding-right: 3.554rem; }

.is-checkradio[type="radio"].is-white.has-background-color + label::before, .is-checkradio[type="radio"].is-white.has-background-color + label:before {
  border-color: white !important;
  background-color: white !important; }

.is-checkradio[type="radio"].is-white:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-white:hover:not([disabled]) + label:before {
  border-color: white !important; }

.is-checkradio[type="radio"].is-white:checked + label::after, .is-checkradio[type="radio"].is-white:checked + label:after {
  border-color: white !important;
  background-color: white !important; }

.is-checkradio[type="radio"].is-white:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-white:checked.has-background-color + label:before {
  border-color: white !important;
  background-color: white !important; }

.is-checkradio[type="radio"].is-white:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-white:checked.has-background-color + label:after {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black.has-background-color + label::before, .is-checkradio[type="radio"].is-black.has-background-color + label:before {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-black:hover:not([disabled]) + label:before {
  border-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black:checked + label::after, .is-checkradio[type="radio"].is-black:checked + label:after {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-black:checked.has-background-color + label:before {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-black:checked.has-background-color + label:after {
  border-color: white !important;
  background-color: white !important; }

.is-checkradio[type="radio"].is-light.has-background-color + label::before, .is-checkradio[type="radio"].is-light.has-background-color + label:before {
  border-color: whitesmoke !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="radio"].is-light:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-light:hover:not([disabled]) + label:before {
  border-color: whitesmoke !important; }

.is-checkradio[type="radio"].is-light:checked + label::after, .is-checkradio[type="radio"].is-light:checked + label:after {
  border-color: whitesmoke !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="radio"].is-light:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-light:checked.has-background-color + label:before {
  border-color: whitesmoke !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="radio"].is-light:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-dark.has-background-color + label::before, .is-checkradio[type="radio"].is-dark.has-background-color + label:before {
  border-color: #4f6567 !important;
  background-color: #4f6567 !important; }

.is-checkradio[type="radio"].is-dark:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-dark:hover:not([disabled]) + label:before {
  border-color: #4f6567 !important; }

.is-checkradio[type="radio"].is-dark:checked + label::after, .is-checkradio[type="radio"].is-dark:checked + label:after {
  border-color: #4f6567 !important;
  background-color: #4f6567 !important; }

.is-checkradio[type="radio"].is-dark:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-dark:checked.has-background-color + label:before {
  border-color: #4f6567 !important;
  background-color: #4f6567 !important; }

.is-checkradio[type="radio"].is-dark:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-dark:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-primary.has-background-color + label::before, .is-checkradio[type="radio"].is-primary.has-background-color + label:before {
  border-color: #009ba9 !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="radio"].is-primary:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-primary:hover:not([disabled]) + label:before {
  border-color: #009ba9 !important; }

.is-checkradio[type="radio"].is-primary:checked + label::after, .is-checkradio[type="radio"].is-primary:checked + label:after {
  border-color: #009ba9 !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="radio"].is-primary:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-primary:checked.has-background-color + label:before {
  border-color: #009ba9 !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="radio"].is-primary:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-primary:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-link.has-background-color + label::before, .is-checkradio[type="radio"].is-link.has-background-color + label:before {
  border-color: #485fc7 !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="radio"].is-link:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-link:hover:not([disabled]) + label:before {
  border-color: #485fc7 !important; }

.is-checkradio[type="radio"].is-link:checked + label::after, .is-checkradio[type="radio"].is-link:checked + label:after {
  border-color: #485fc7 !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="radio"].is-link:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-link:checked.has-background-color + label:before {
  border-color: #485fc7 !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="radio"].is-link:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-link:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-info.has-background-color + label::before, .is-checkradio[type="radio"].is-info.has-background-color + label:before {
  border-color: #3e8ed0 !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="radio"].is-info:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-info:hover:not([disabled]) + label:before {
  border-color: #3e8ed0 !important; }

.is-checkradio[type="radio"].is-info:checked + label::after, .is-checkradio[type="radio"].is-info:checked + label:after {
  border-color: #3e8ed0 !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="radio"].is-info:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-info:checked.has-background-color + label:before {
  border-color: #3e8ed0 !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="radio"].is-info:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-info:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-success.has-background-color + label::before, .is-checkradio[type="radio"].is-success.has-background-color + label:before {
  border-color: #48c78e !important;
  background-color: #48c78e !important; }

.is-checkradio[type="radio"].is-success:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-success:hover:not([disabled]) + label:before {
  border-color: #48c78e !important; }

.is-checkradio[type="radio"].is-success:checked + label::after, .is-checkradio[type="radio"].is-success:checked + label:after {
  border-color: #48c78e !important;
  background-color: #48c78e !important; }

.is-checkradio[type="radio"].is-success:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-success:checked.has-background-color + label:before {
  border-color: #48c78e !important;
  background-color: #48c78e !important; }

.is-checkradio[type="radio"].is-success:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-success:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-warning.has-background-color + label::before, .is-checkradio[type="radio"].is-warning.has-background-color + label:before {
  border-color: #ffe08a !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="radio"].is-warning:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-warning:hover:not([disabled]) + label:before {
  border-color: #ffe08a !important; }

.is-checkradio[type="radio"].is-warning:checked + label::after, .is-checkradio[type="radio"].is-warning:checked + label:after {
  border-color: #ffe08a !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="radio"].is-warning:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-warning:checked.has-background-color + label:before {
  border-color: #ffe08a !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="radio"].is-warning:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-warning:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-danger.has-background-color + label::before, .is-checkradio[type="radio"].is-danger.has-background-color + label:before {
  border-color: #f14668 !important;
  background-color: #f14668 !important; }

.is-checkradio[type="radio"].is-danger:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-danger:hover:not([disabled]) + label:before {
  border-color: #f14668 !important; }

.is-checkradio[type="radio"].is-danger:checked + label::after, .is-checkradio[type="radio"].is-danger:checked + label:after {
  border-color: #f14668 !important;
  background-color: #f14668 !important; }

.is-checkradio[type="radio"].is-danger:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-danger:checked.has-background-color + label:before {
  border-color: #f14668 !important;
  background-color: #f14668 !important; }

.is-checkradio[type="radio"].is-danger:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-danger:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-primary-brand-1.has-background-color + label::before, .is-checkradio[type="radio"].is-primary-brand-1.has-background-color + label:before {
  border-color: #009ba9 !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="radio"].is-primary-brand-1:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-primary-brand-1:hover:not([disabled]) + label:before {
  border-color: #009ba9 !important; }

.is-checkradio[type="radio"].is-primary-brand-1:checked + label::after, .is-checkradio[type="radio"].is-primary-brand-1:checked + label:after {
  border-color: #009ba9 !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="radio"].is-primary-brand-1:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-primary-brand-1:checked.has-background-color + label:before {
  border-color: #009ba9 !important;
  background-color: #009ba9 !important; }

.is-checkradio[type="radio"].is-primary-brand-1:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-primary-brand-1:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-primary-brand-2.has-background-color + label::before, .is-checkradio[type="radio"].is-primary-brand-2.has-background-color + label:before {
  border-color: #95c11f !important;
  background-color: #95c11f !important; }

.is-checkradio[type="radio"].is-primary-brand-2:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-primary-brand-2:hover:not([disabled]) + label:before {
  border-color: #95c11f !important; }

.is-checkradio[type="radio"].is-primary-brand-2:checked + label::after, .is-checkradio[type="radio"].is-primary-brand-2:checked + label:after {
  border-color: #95c11f !important;
  background-color: #95c11f !important; }

.is-checkradio[type="radio"].is-primary-brand-2:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-primary-brand-2:checked.has-background-color + label:before {
  border-color: #95c11f !important;
  background-color: #95c11f !important; }

.is-checkradio[type="radio"].is-primary-brand-2:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-primary-brand-2:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-blue-bg-1.has-background-color + label::before, .is-checkradio[type="radio"].is-blue-bg-1.has-background-color + label:before {
  border-color: #78cbd0 !important;
  background-color: #78cbd0 !important; }

.is-checkradio[type="radio"].is-blue-bg-1:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-blue-bg-1:hover:not([disabled]) + label:before {
  border-color: #78cbd0 !important; }

.is-checkradio[type="radio"].is-blue-bg-1:checked + label::after, .is-checkradio[type="radio"].is-blue-bg-1:checked + label:after {
  border-color: #78cbd0 !important;
  background-color: #78cbd0 !important; }

.is-checkradio[type="radio"].is-blue-bg-1:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-blue-bg-1:checked.has-background-color + label:before {
  border-color: #78cbd0 !important;
  background-color: #78cbd0 !important; }

.is-checkradio[type="radio"].is-blue-bg-1:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-blue-bg-1:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-blue-bg-2.has-background-color + label::before, .is-checkradio[type="radio"].is-blue-bg-2.has-background-color + label:before {
  border-color: #c0eaee !important;
  background-color: #c0eaee !important; }

.is-checkradio[type="radio"].is-blue-bg-2:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-blue-bg-2:hover:not([disabled]) + label:before {
  border-color: #c0eaee !important; }

.is-checkradio[type="radio"].is-blue-bg-2:checked + label::after, .is-checkradio[type="radio"].is-blue-bg-2:checked + label:after {
  border-color: #c0eaee !important;
  background-color: #c0eaee !important; }

.is-checkradio[type="radio"].is-blue-bg-2:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-blue-bg-2:checked.has-background-color + label:before {
  border-color: #c0eaee !important;
  background-color: #c0eaee !important; }

.is-checkradio[type="radio"].is-blue-bg-2:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-blue-bg-2:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-green-bg.has-background-color + label::before, .is-checkradio[type="radio"].is-green-bg.has-background-color + label:before {
  border-color: #bed974 !important;
  background-color: #bed974 !important; }

.is-checkradio[type="radio"].is-green-bg:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-green-bg:hover:not([disabled]) + label:before {
  border-color: #bed974 !important; }

.is-checkradio[type="radio"].is-green-bg:checked + label::after, .is-checkradio[type="radio"].is-green-bg:checked + label:after {
  border-color: #bed974 !important;
  background-color: #bed974 !important; }

.is-checkradio[type="radio"].is-green-bg:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-green-bg:checked.has-background-color + label:before {
  border-color: #bed974 !important;
  background-color: #bed974 !important; }

.is-checkradio[type="radio"].is-green-bg:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-green-bg:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-green-light.has-background-color + label::before, .is-checkradio[type="radio"].is-green-light.has-background-color + label:before {
  border-color: #eef8d3 !important;
  background-color: #eef8d3 !important; }

.is-checkradio[type="radio"].is-green-light:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-green-light:hover:not([disabled]) + label:before {
  border-color: #eef8d3 !important; }

.is-checkradio[type="radio"].is-green-light:checked + label::after, .is-checkradio[type="radio"].is-green-light:checked + label:after {
  border-color: #eef8d3 !important;
  background-color: #eef8d3 !important; }

.is-checkradio[type="radio"].is-green-light:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-green-light:checked.has-background-color + label:before {
  border-color: #eef8d3 !important;
  background-color: #eef8d3 !important; }

.is-checkradio[type="radio"].is-green-light:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-green-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-darker.has-background-color + label::before, .is-checkradio[type="radio"].is-darker.has-background-color + label:before {
  border-color: #273d3f !important;
  background-color: #273d3f !important; }

.is-checkradio[type="radio"].is-darker:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-darker:hover:not([disabled]) + label:before {
  border-color: #273d3f !important; }

.is-checkradio[type="radio"].is-darker:checked + label::after, .is-checkradio[type="radio"].is-darker:checked + label:after {
  border-color: #273d3f !important;
  background-color: #273d3f !important; }

.is-checkradio[type="radio"].is-darker:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-darker:checked.has-background-color + label:before {
  border-color: #273d3f !important;
  background-color: #273d3f !important; }

.is-checkradio[type="radio"].is-darker:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-darker:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-dark-mild.has-background-color + label::before, .is-checkradio[type="radio"].is-dark-mild.has-background-color + label:before {
  border-color: #789497 !important;
  background-color: #789497 !important; }

.is-checkradio[type="radio"].is-dark-mild:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-dark-mild:hover:not([disabled]) + label:before {
  border-color: #789497 !important; }

.is-checkradio[type="radio"].is-dark-mild:checked + label::after, .is-checkradio[type="radio"].is-dark-mild:checked + label:after {
  border-color: #789497 !important;
  background-color: #789497 !important; }

.is-checkradio[type="radio"].is-dark-mild:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-dark-mild:checked.has-background-color + label:before {
  border-color: #789497 !important;
  background-color: #789497 !important; }

.is-checkradio[type="radio"].is-dark-mild:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-dark-mild:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-dark-light.has-background-color + label::before, .is-checkradio[type="radio"].is-dark-light.has-background-color + label:before {
  border-color: #a8c1c3 !important;
  background-color: #a8c1c3 !important; }

.is-checkradio[type="radio"].is-dark-light:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-dark-light:hover:not([disabled]) + label:before {
  border-color: #a8c1c3 !important; }

.is-checkradio[type="radio"].is-dark-light:checked + label::after, .is-checkradio[type="radio"].is-dark-light:checked + label:after {
  border-color: #a8c1c3 !important;
  background-color: #a8c1c3 !important; }

.is-checkradio[type="radio"].is-dark-light:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-dark-light:checked.has-background-color + label:before {
  border-color: #a8c1c3 !important;
  background-color: #a8c1c3 !important; }

.is-checkradio[type="radio"].is-dark-light:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-dark-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-dark-lightest.has-background-color + label::before, .is-checkradio[type="radio"].is-dark-lightest.has-background-color + label:before {
  border-color: #eff3f3 !important;
  background-color: #eff3f3 !important; }

.is-checkradio[type="radio"].is-dark-lightest:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-dark-lightest:hover:not([disabled]) + label:before {
  border-color: #eff3f3 !important; }

.is-checkradio[type="radio"].is-dark-lightest:checked + label::after, .is-checkradio[type="radio"].is-dark-lightest:checked + label:after {
  border-color: #eff3f3 !important;
  background-color: #eff3f3 !important; }

.is-checkradio[type="radio"].is-dark-lightest:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-dark-lightest:checked.has-background-color + label:before {
  border-color: #eff3f3 !important;
  background-color: #eff3f3 !important; }

.is-checkradio[type="radio"].is-dark-lightest:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-dark-lightest:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

html {
  scroll-behavior: smooth; }

body {
  line-height: 1.5;
  overflow-x: hidden; }

:root {
  font-variant-ligatures: normal; }

ah1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem;
  font-weight: 300; }

h3 {
  font-size: 1.5rem;
  font-weight: normal; }

h4 {
  font-size: 1.125rem;
  margin-top: 1rem; }

h5 {
  font-size: 1.125rem;
  font-weight: normal;
  margin-bottom: 0; }

h6 {
  font-size: 1rem;
  margin-bottom: 0; }

ul,
ol {
  margin: 0; }

ul {
  list-style-type: square; }

ol {
  list-style: none;
  counter-reset: adhoc-custom; }
  ol li {
    counter-increment: adhoc-custom; }
    ol li::before {
      content: counter(adhoc-custom) ". ";
      font-weight: bold;
      font-size: 1.25rem; }
  .collapsible ol {
    background-color: #eff3f3;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem; }

table {
  border-collapse: collapse; }

strong {
  font-weight: 500; }

a {
  color: inherit;
  transition: -webkit-text-decoration-color 0.1s ease-in;
  transition: text-decoration-color 0.1s ease-in;
  transition: text-decoration-color 0.1s ease-in, -webkit-text-decoration-color 0.1s ease-in;
  word-break: break-word; }
  a:hover {
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent; }

hr {
  border: 0;
  border-top: 1px solid #dbdbdb; }

abbr {
  text-decoration: none; }

*:focus {
  outline: 2px dotted #4f6567 !important;
  outline-offset: 1px !important;
  box-shadow: none !important; }
  .has-background-primary-dark *:focus {
    outline-color: #000 !important; }
  .has-background-darker *:focus {
    outline-color: #fff !important; }

*:focus:not(:focus-visible) {
  /* undo all the above focused button styles if the button has focus but the browser wouldn't normally show default focus styles */
  outline: none !important; }

*:focus-visible {
  outline: 2px dotted #4f6567 !important;
  outline-offset: 1px !important;
  box-shadow: none !important; }
  .has-background-primary-dark *:focus-visible {
    outline-color: #000 !important; }
  .has-background-darker *:focus-visible {
    outline-color: #fff !important; }

figcaption {
  font-size: 0.875rem;
  margin-top: 0.875rem; }

img {
  height: auto; }

fieldset {
  border: 0;
  padding: 0;
  margin-left: 0;
  margin-right: 0; }

legend {
  font-size: 1.5rem; }

[aria-hidden="true"],
[data-a11y-toggle]:not([aria-controls]) {
  display: none; }

/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.gm-style [aria-hidden="true"],
.slider [aria-hidden="true"],
.icon[aria-hidden="true"] {
  /* 1 */
  display: block;
  /* 2 */
  display: initial; }

.columns2 > .column > * + *,
.columns3 > .column > * + * {
  margin-top: 1.125rem; }

.columns:not(:last-child) {
  margin-bottom: 0; }

.sidebar-left {
  display: flex;
  flex-wrap: wrap;
  gap: 1.125rem; }
  .sidebar-left > :first-child {
    flex-grow: 1;
    flex-basis: 25rem; }
  .sidebar-left > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%; }
    .sidebar-left > :last-child > * + * {
      margin-top: 1rem; }

.sidebar-right {
  display: flex;
  flex-wrap: wrap;
  gap: 1.125rem; }
  .sidebar-right > :first-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%; }
    .sidebar-right > :first-child > * + * {
      margin-top: 1rem; }
  .sidebar-right > :last-child {
    flex-grow: 1;
    flex-basis: 25rem; }

.flow > * + * {
  margin-top: 1rem; }

.flow--1 > * + * {
  margin-top: 0.875rem; }

.flow--3 > * + * {
  margin-top: 1.125rem; }

.flow--4 > * + * {
  margin-top: 1.5rem; }

.flow--5 > * + * {
  margin-top: 2rem; }

.flow--6 > * + * {
  margin-top: 2.5rem; }

.flow--7 > * + * {
  margin-top: 3rem; }

.back-arrow {
  display: inline-block; }

.carrec {
  font-size: 0.875rem;
  font-style: italic;
  display: inline-block; }

.clic {
  cursor: pointer; }

.highlighted-p-1 {
  background: #c0eaee;
  padding: 1.5rem;
  font-size: 1.125rem; }

ul.highlighted-p-1 {
  padding-left: 3rem; }

.highlighted-p-2 {
  padding: 1.5rem;
  background-color: #bed974;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center; }
  @media screen and (min-width: 900px), print {
    .highlighted-p-2 {
      padding: 1.5rem 24%; } }

.highlighted-p-3 {
  padding-left: 1.5rem;
  border-left: solid 4px #78cbd0;
  font-size: 1.25rem;
  font-weight: 500; }

.link-dn {
  text-decoration-color: transparent; }
  .link-dn:hover {
    text-decoration-color: inherit; }
  .link-dn a {
    text-decoration-color: transparent; }
    .link-dn a:hover {
      text-decoration-color: inherit; }

.radius {
  border-radius: 4px; }

.rounded {
  border-radius: 50%; }

@media screen and (min-width: 900px), print {
  .text-columns {
    column-count: 2;
    column-gap: 1.5rem; } }

.text-columns > * + * {
  margin-top: 1rem; }

.no-list-decoration {
  list-style: none;
  padding-left: 0; }

.wrapper {
  max-width: 105rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  .wrapper--narrow {
    max-width: 81rem; }
  .wrapper--narrowest {
    max-width: 56.5625rem; }

.title-smaller {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 2.125rem;
  font-weight: 300; }

.title-0 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px; }

.title-1 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 1.375rem;
  font-weight: 700; }

.title-2 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 700; }

.title-3 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500; }

.title-4 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 500; }

.headline-1 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 3.750rem;
  font-weight: 600; }

.headline-1-alt {
  font-family: Barlow, Helvetica, Arial, sans-serif;
  font-size: 4.375rem;
  font-weight: 600; }

.headline-2 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 2.5rem;
  font-weight: 300; }

.headline-2-alt {
  font-family: Barlow, Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 600; }

.headline-3 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 1.875rem;
  font-weight: 400; }

.headline-3-alt {
  font-family: Barlow, Helvetica, Arial, sans-serif;
  font-size: 1.625rem;
  font-weight: 600; }

.headline-4 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 500; }

.headline-4-alt {
  font-family: Barlow, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400; }

span.headline-4-alt {
  line-height: 1.2; }

.headline-5 {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 2.125rem;
  font-weight: 300; }

.headline-5-alt {
  font-family: Barlow, Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 500; }

.is-size-8 {
  font-size: .75rem; }

.tancar {
  border: none;
  background-color: transparent;
  cursor: pointer; }

.box-shadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1); }

.p-dni {
  position: relative;
  font-weight: 500 !important;
  padding-left: 2rem; }
  .p-dni:before {
    content: '';
    display: block;
    background-image: url("../img/sprites-svg/dni.svg");
    width: 20px;
    height: 22px;
    position: absolute;
    left: 0; }

.pp-5 {
  padding: 5% !important; }

.pm-5 {
  margin: 5% !important; }

@media screen and (min-width: 900px), print {
  .pp-5-tablet {
    padding: 5% !important; }
  .pm-5-tablet {
    margin: 5% !important; } }

.pp-top-5 {
  padding-top: 5% !important; }

.pm-top-5 {
  margin-top: 5% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-5-tablet {
    padding-top: 5% !important; }
  .pm-top-5-tablet {
    margin-top: 5% !important; } }

.pp-left-5 {
  padding-left: 5% !important; }

.pm-left-5 {
  margin-left: 5% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-5-tablet {
    padding-left: 5% !important; }
  .pm-left-5-tablet {
    margin-left: 5% !important; } }

.pp-bottom-5 {
  padding-bottom: 5% !important; }

.pm-bottom-5 {
  margin-bottom: 5% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-5-tablet {
    padding-bottom: 5% !important; }
  .pm-bottom-5-tablet {
    margin-bottom: 5% !important; } }

.pp-right-5 {
  padding-right: 5% !important; }

.pm-right-5 {
  margin-right: 5% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-5-tablet {
    padding-right: 5% !important; }
  .pm-right-5-tablet {
    margin-right: 5% !important; } }

.pp-10 {
  padding: 10% !important; }

.pm-10 {
  margin: 10% !important; }

@media screen and (min-width: 900px), print {
  .pp-10-tablet {
    padding: 10% !important; }
  .pm-10-tablet {
    margin: 10% !important; } }

.pp-top-10 {
  padding-top: 10% !important; }

.pm-top-10 {
  margin-top: 10% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-10-tablet {
    padding-top: 10% !important; }
  .pm-top-10-tablet {
    margin-top: 10% !important; } }

.pp-left-10 {
  padding-left: 10% !important; }

.pm-left-10 {
  margin-left: 10% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-10-tablet {
    padding-left: 10% !important; }
  .pm-left-10-tablet {
    margin-left: 10% !important; } }

.pp-bottom-10 {
  padding-bottom: 10% !important; }

.pm-bottom-10 {
  margin-bottom: 10% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-10-tablet {
    padding-bottom: 10% !important; }
  .pm-bottom-10-tablet {
    margin-bottom: 10% !important; } }

.pp-right-10 {
  padding-right: 10% !important; }

.pm-right-10 {
  margin-right: 10% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-10-tablet {
    padding-right: 10% !important; }
  .pm-right-10-tablet {
    margin-right: 10% !important; } }

.pp-15 {
  padding: 15% !important; }

.pm-15 {
  margin: 15% !important; }

@media screen and (min-width: 900px), print {
  .pp-15-tablet {
    padding: 15% !important; }
  .pm-15-tablet {
    margin: 15% !important; } }

.pp-top-15 {
  padding-top: 15% !important; }

.pm-top-15 {
  margin-top: 15% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-15-tablet {
    padding-top: 15% !important; }
  .pm-top-15-tablet {
    margin-top: 15% !important; } }

.pp-left-15 {
  padding-left: 15% !important; }

.pm-left-15 {
  margin-left: 15% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-15-tablet {
    padding-left: 15% !important; }
  .pm-left-15-tablet {
    margin-left: 15% !important; } }

.pp-bottom-15 {
  padding-bottom: 15% !important; }

.pm-bottom-15 {
  margin-bottom: 15% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-15-tablet {
    padding-bottom: 15% !important; }
  .pm-bottom-15-tablet {
    margin-bottom: 15% !important; } }

.pp-right-15 {
  padding-right: 15% !important; }

.pm-right-15 {
  margin-right: 15% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-15-tablet {
    padding-right: 15% !important; }
  .pm-right-15-tablet {
    margin-right: 15% !important; } }

.pp-20 {
  padding: 20% !important; }

.pm-20 {
  margin: 20% !important; }

@media screen and (min-width: 900px), print {
  .pp-20-tablet {
    padding: 20% !important; }
  .pm-20-tablet {
    margin: 20% !important; } }

.pp-top-20 {
  padding-top: 20% !important; }

.pm-top-20 {
  margin-top: 20% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-20-tablet {
    padding-top: 20% !important; }
  .pm-top-20-tablet {
    margin-top: 20% !important; } }

.pp-left-20 {
  padding-left: 20% !important; }

.pm-left-20 {
  margin-left: 20% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-20-tablet {
    padding-left: 20% !important; }
  .pm-left-20-tablet {
    margin-left: 20% !important; } }

.pp-bottom-20 {
  padding-bottom: 20% !important; }

.pm-bottom-20 {
  margin-bottom: 20% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-20-tablet {
    padding-bottom: 20% !important; }
  .pm-bottom-20-tablet {
    margin-bottom: 20% !important; } }

.pp-right-20 {
  padding-right: 20% !important; }

.pm-right-20 {
  margin-right: 20% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-20-tablet {
    padding-right: 20% !important; }
  .pm-right-20-tablet {
    margin-right: 20% !important; } }

.pp-25 {
  padding: 25% !important; }

.pm-25 {
  margin: 25% !important; }

@media screen and (min-width: 900px), print {
  .pp-25-tablet {
    padding: 25% !important; }
  .pm-25-tablet {
    margin: 25% !important; } }

.pp-top-25 {
  padding-top: 25% !important; }

.pm-top-25 {
  margin-top: 25% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-25-tablet {
    padding-top: 25% !important; }
  .pm-top-25-tablet {
    margin-top: 25% !important; } }

.pp-left-25 {
  padding-left: 25% !important; }

.pm-left-25 {
  margin-left: 25% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-25-tablet {
    padding-left: 25% !important; }
  .pm-left-25-tablet {
    margin-left: 25% !important; } }

.pp-bottom-25 {
  padding-bottom: 25% !important; }

.pm-bottom-25 {
  margin-bottom: 25% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-25-tablet {
    padding-bottom: 25% !important; }
  .pm-bottom-25-tablet {
    margin-bottom: 25% !important; } }

.pp-right-25 {
  padding-right: 25% !important; }

.pm-right-25 {
  margin-right: 25% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-25-tablet {
    padding-right: 25% !important; }
  .pm-right-25-tablet {
    margin-right: 25% !important; } }

.pp-30 {
  padding: 30% !important; }

.pm-30 {
  margin: 30% !important; }

@media screen and (min-width: 900px), print {
  .pp-30-tablet {
    padding: 30% !important; }
  .pm-30-tablet {
    margin: 30% !important; } }

.pp-top-30 {
  padding-top: 30% !important; }

.pm-top-30 {
  margin-top: 30% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-30-tablet {
    padding-top: 30% !important; }
  .pm-top-30-tablet {
    margin-top: 30% !important; } }

.pp-left-30 {
  padding-left: 30% !important; }

.pm-left-30 {
  margin-left: 30% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-30-tablet {
    padding-left: 30% !important; }
  .pm-left-30-tablet {
    margin-left: 30% !important; } }

.pp-bottom-30 {
  padding-bottom: 30% !important; }

.pm-bottom-30 {
  margin-bottom: 30% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-30-tablet {
    padding-bottom: 30% !important; }
  .pm-bottom-30-tablet {
    margin-bottom: 30% !important; } }

.pp-right-30 {
  padding-right: 30% !important; }

.pm-right-30 {
  margin-right: 30% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-30-tablet {
    padding-right: 30% !important; }
  .pm-right-30-tablet {
    margin-right: 30% !important; } }

.pp-35 {
  padding: 35% !important; }

.pm-35 {
  margin: 35% !important; }

@media screen and (min-width: 900px), print {
  .pp-35-tablet {
    padding: 35% !important; }
  .pm-35-tablet {
    margin: 35% !important; } }

.pp-top-35 {
  padding-top: 35% !important; }

.pm-top-35 {
  margin-top: 35% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-35-tablet {
    padding-top: 35% !important; }
  .pm-top-35-tablet {
    margin-top: 35% !important; } }

.pp-left-35 {
  padding-left: 35% !important; }

.pm-left-35 {
  margin-left: 35% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-35-tablet {
    padding-left: 35% !important; }
  .pm-left-35-tablet {
    margin-left: 35% !important; } }

.pp-bottom-35 {
  padding-bottom: 35% !important; }

.pm-bottom-35 {
  margin-bottom: 35% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-35-tablet {
    padding-bottom: 35% !important; }
  .pm-bottom-35-tablet {
    margin-bottom: 35% !important; } }

.pp-right-35 {
  padding-right: 35% !important; }

.pm-right-35 {
  margin-right: 35% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-35-tablet {
    padding-right: 35% !important; }
  .pm-right-35-tablet {
    margin-right: 35% !important; } }

.pp-40 {
  padding: 40% !important; }

.pm-40 {
  margin: 40% !important; }

@media screen and (min-width: 900px), print {
  .pp-40-tablet {
    padding: 40% !important; }
  .pm-40-tablet {
    margin: 40% !important; } }

.pp-top-40 {
  padding-top: 40% !important; }

.pm-top-40 {
  margin-top: 40% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-40-tablet {
    padding-top: 40% !important; }
  .pm-top-40-tablet {
    margin-top: 40% !important; } }

.pp-left-40 {
  padding-left: 40% !important; }

.pm-left-40 {
  margin-left: 40% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-40-tablet {
    padding-left: 40% !important; }
  .pm-left-40-tablet {
    margin-left: 40% !important; } }

.pp-bottom-40 {
  padding-bottom: 40% !important; }

.pm-bottom-40 {
  margin-bottom: 40% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-40-tablet {
    padding-bottom: 40% !important; }
  .pm-bottom-40-tablet {
    margin-bottom: 40% !important; } }

.pp-right-40 {
  padding-right: 40% !important; }

.pm-right-40 {
  margin-right: 40% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-40-tablet {
    padding-right: 40% !important; }
  .pm-right-40-tablet {
    margin-right: 40% !important; } }

.pp-45 {
  padding: 45% !important; }

.pm-45 {
  margin: 45% !important; }

@media screen and (min-width: 900px), print {
  .pp-45-tablet {
    padding: 45% !important; }
  .pm-45-tablet {
    margin: 45% !important; } }

.pp-top-45 {
  padding-top: 45% !important; }

.pm-top-45 {
  margin-top: 45% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-45-tablet {
    padding-top: 45% !important; }
  .pm-top-45-tablet {
    margin-top: 45% !important; } }

.pp-left-45 {
  padding-left: 45% !important; }

.pm-left-45 {
  margin-left: 45% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-45-tablet {
    padding-left: 45% !important; }
  .pm-left-45-tablet {
    margin-left: 45% !important; } }

.pp-bottom-45 {
  padding-bottom: 45% !important; }

.pm-bottom-45 {
  margin-bottom: 45% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-45-tablet {
    padding-bottom: 45% !important; }
  .pm-bottom-45-tablet {
    margin-bottom: 45% !important; } }

.pp-right-45 {
  padding-right: 45% !important; }

.pm-right-45 {
  margin-right: 45% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-45-tablet {
    padding-right: 45% !important; }
  .pm-right-45-tablet {
    margin-right: 45% !important; } }

.pp-50 {
  padding: 50% !important; }

.pm-50 {
  margin: 50% !important; }

@media screen and (min-width: 900px), print {
  .pp-50-tablet {
    padding: 50% !important; }
  .pm-50-tablet {
    margin: 50% !important; } }

.pp-top-50 {
  padding-top: 50% !important; }

.pm-top-50 {
  margin-top: 50% !important; }

@media screen and (min-width: 900px), print {
  .pp-top-50-tablet {
    padding-top: 50% !important; }
  .pm-top-50-tablet {
    margin-top: 50% !important; } }

.pp-left-50 {
  padding-left: 50% !important; }

.pm-left-50 {
  margin-left: 50% !important; }

@media screen and (min-width: 900px), print {
  .pp-left-50-tablet {
    padding-left: 50% !important; }
  .pm-left-50-tablet {
    margin-left: 50% !important; } }

.pp-bottom-50 {
  padding-bottom: 50% !important; }

.pm-bottom-50 {
  margin-bottom: 50% !important; }

@media screen and (min-width: 900px), print {
  .pp-bottom-50-tablet {
    padding-bottom: 50% !important; }
  .pm-bottom-50-tablet {
    margin-bottom: 50% !important; } }

.pp-right-50 {
  padding-right: 50% !important; }

.pm-right-50 {
  margin-right: 50% !important; }

@media screen and (min-width: 900px), print {
  .pp-right-50-tablet {
    padding-right: 50% !important; }
  .pm-right-50-tablet {
    margin-right: 50% !important; } }

.nm-1 {
  margin: -1rem !important; }

@media screen and (min-width: 900px), print {
  .nm-1-tablet {
    margin: -1rem !important; } }

.nm-top-1 {
  margin-top: -1rem !important; }

@media screen and (min-width: 900px), print {
  .nm-top-1-tablet {
    margin-top: -1rem !important; } }

.nm-left-1 {
  margin-left: -1rem !important; }

@media screen and (min-width: 900px), print {
  .nm-left-1-tablet {
    margin-left: -1rem !important; } }

.nm-bottom-1 {
  margin-bottom: -1rem !important; }

@media screen and (min-width: 900px), print {
  .nm-bottom-1-tablet {
    margin-bottom: -1rem !important; } }

.nm-right-1 {
  margin-right: -1rem !important; }

@media screen and (min-width: 900px), print {
  .nm-right-1-tablet {
    margin-right: -1rem !important; } }

.nm-2 {
  margin: -1.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-2-tablet {
    margin: -1.5rem !important; } }

.nm-top-2 {
  margin-top: -1.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-top-2-tablet {
    margin-top: -1.5rem !important; } }

.nm-left-2 {
  margin-left: -1.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-left-2-tablet {
    margin-left: -1.5rem !important; } }

.nm-bottom-2 {
  margin-bottom: -1.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-bottom-2-tablet {
    margin-bottom: -1.5rem !important; } }

.nm-right-2 {
  margin-right: -1.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-right-2-tablet {
    margin-right: -1.5rem !important; } }

.nm-3 {
  margin: -2rem !important; }

@media screen and (min-width: 900px), print {
  .nm-3-tablet {
    margin: -2rem !important; } }

.nm-top-3 {
  margin-top: -2rem !important; }

@media screen and (min-width: 900px), print {
  .nm-top-3-tablet {
    margin-top: -2rem !important; } }

.nm-left-3 {
  margin-left: -2rem !important; }

@media screen and (min-width: 900px), print {
  .nm-left-3-tablet {
    margin-left: -2rem !important; } }

.nm-bottom-3 {
  margin-bottom: -2rem !important; }

@media screen and (min-width: 900px), print {
  .nm-bottom-3-tablet {
    margin-bottom: -2rem !important; } }

.nm-right-3 {
  margin-right: -2rem !important; }

@media screen and (min-width: 900px), print {
  .nm-right-3-tablet {
    margin-right: -2rem !important; } }

.nm-4 {
  margin: -2.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-4-tablet {
    margin: -2.5rem !important; } }

.nm-top-4 {
  margin-top: -2.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-top-4-tablet {
    margin-top: -2.5rem !important; } }

.nm-left-4 {
  margin-left: -2.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-left-4-tablet {
    margin-left: -2.5rem !important; } }

.nm-bottom-4 {
  margin-bottom: -2.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-bottom-4-tablet {
    margin-bottom: -2.5rem !important; } }

.nm-right-4 {
  margin-right: -2.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-right-4-tablet {
    margin-right: -2.5rem !important; } }

.nm-5 {
  margin: -3rem !important; }

@media screen and (min-width: 900px), print {
  .nm-5-tablet {
    margin: -3rem !important; } }

.nm-top-5 {
  margin-top: -3rem !important; }

@media screen and (min-width: 900px), print {
  .nm-top-5-tablet {
    margin-top: -3rem !important; } }

.nm-left-5 {
  margin-left: -3rem !important; }

@media screen and (min-width: 900px), print {
  .nm-left-5-tablet {
    margin-left: -3rem !important; } }

.nm-bottom-5 {
  margin-bottom: -3rem !important; }

@media screen and (min-width: 900px), print {
  .nm-bottom-5-tablet {
    margin-bottom: -3rem !important; } }

.nm-right-5 {
  margin-right: -3rem !important; }

@media screen and (min-width: 900px), print {
  .nm-right-5-tablet {
    margin-right: -3rem !important; } }

.nm-6 {
  margin: -3.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-6-tablet {
    margin: -3.5rem !important; } }

.nm-top-6 {
  margin-top: -3.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-top-6-tablet {
    margin-top: -3.5rem !important; } }

.nm-left-6 {
  margin-left: -3.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-left-6-tablet {
    margin-left: -3.5rem !important; } }

.nm-bottom-6 {
  margin-bottom: -3.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-bottom-6-tablet {
    margin-bottom: -3.5rem !important; } }

.nm-right-6 {
  margin-right: -3.5rem !important; }

@media screen and (min-width: 900px), print {
  .nm-right-6-tablet {
    margin-right: -3.5rem !important; } }

.nm-7 {
  margin: -4rem !important; }

@media screen and (min-width: 900px), print {
  .nm-7-tablet {
    margin: -4rem !important; } }

.nm-top-7 {
  margin-top: -4rem !important; }

@media screen and (min-width: 900px), print {
  .nm-top-7-tablet {
    margin-top: -4rem !important; } }

.nm-left-7 {
  margin-left: -4rem !important; }

@media screen and (min-width: 900px), print {
  .nm-left-7-tablet {
    margin-left: -4rem !important; } }

.nm-bottom-7 {
  margin-bottom: -4rem !important; }

@media screen and (min-width: 900px), print {
  .nm-bottom-7-tablet {
    margin-bottom: -4rem !important; } }

.nm-right-7 {
  margin-right: -4rem !important; }

@media screen and (min-width: 900px), print {
  .nm-right-7-tablet {
    margin-right: -4rem !important; } }

.nowrap {
  white-space: nowrap; }

.fluid-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; }
  .fluid-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0; }
  .fluid-iframe[data-state="fixed-height"] {
    padding-top: 28.125rem; }

/* Override Bulma icon margin styles */
.button .icon:first-child {
  margin: 0 0.5em 0 0 !important; }

.button {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  background-color: transparent;
  height: 3rem;
  border: 0.125rem solid #273d3f;
  border-radius: 0; }
  .button:hover {
    color: white;
    background-color: #273d3f;
    border-color: #273d3f; }
  .button .icon:last-child:not(:first-child) {
    margin-left: 0.5rem;
    margin-right: -0.25rem; }
  .button.is-secondary {
    text-transform: initial;
    height: 2.375rem;
    padding: 0.25rem 0.875rem; }
  .button.is-search {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px 1px 1px 0; }
  .button.fletxa {
    padding-right: 2rem; }
    .button.fletxa::after {
      content: '';
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMSAyMCI+CiAgICAgICAgICAgIDxkZWZzIC8+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik05LjMzMDIgMS44MjNjLjIzNC0uMjA4NC40NTQ4LS4yMDg0LjY2MjcgMGw4LjE0NzEgOC4yMDNjLjIzMzkuMjA4NC4yMzM5LjQxNjcgMCAuNjI1bC04LjE0NyA4LjIwMzJjLS4yMDguMjA4My0uNDI4OS4yMDgzLS42NjI4IDBsLS43Nzk2LS43ODEzYy0uMDc4LS4wNzgxLS4xMTctLjE4MjMtLjExNy0uMzEyNXMuMDM5LS4yNDc0LjExNy0uMzUxNWw2LjA0MjEtNi4wNTQ3SDEuM2MtLjMxMTggMC0uNDY3Ny0uMTU2My0uNDY3Ny0uNDY4OFY5Ljc5MTdjMC0uMzEyNS4xNTU5LS40Njg4LjQ2NzctLjQ2ODhoMTMuMjkyN0w4LjU1MDYgMy4yNjgyYy0uMjA3OS0uMjM0My0uMjA3OS0uNDU1NyAwLS42NjRsLjc3OTYtLjc4MTN6IiAvPgo8L3N2Zz4K");
      height: 16px;
      width: 16px;
      position: absolute;
      right: 8px; }
    .button.fletxa:hover::after {
      filter: brightness(0) invert(1); }
  .button a {
    text-decoration: none; }

.cal {
  width: 100%;
  border-spacing: 7px;
  border-collapse: separate; }
  @media screen and (max-width: 899px) {
    .cal {
      border-spacing: 3px; } }
  .cal tbody a,
  .cal .today {
    width: 2rem;
    height: 2rem; }
  .cal .today {
    border: 1px solid #fff;
    margin-top: 2px; }
    .cal .today a {
      position: absolute;
      margin-left: -4px; }
  .cal tbody a:hover {
    background: #eef8d3 !important; }
  .cal .has-text-dark-light {
    opacity: .6; }

.card {
  padding: 1.375rem;
  background-color: white; }
  .card dl dt {
    margin-bottom: 1rem; }

.js .collapsible .collapsible__title {
  font-size: 1.125rem; }
  .js .collapsible .collapsible__title button {
    all: inherit;
    border: 0;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0.3125rem;
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    background-color: #bed974;
    cursor: pointer; }
    .js .collapsible .collapsible__title button:hover, .js .collapsible .collapsible__title button:active, .js .collapsible .collapsible__title button[aria-expanded="true"] {
      background-color: #273d3f;
      color: white; }
    .js .collapsible .collapsible__title button:focus {
      outline: 2px dotted #4f6567 !important;
      outline-offset: 1px; }
    .js .collapsible .collapsible__title button svg {
      margin-top: 5px;
      display: block; }
  .js .collapsible .collapsible__title + div {
    margin-top: -0.3125rem !important;
    margin-bottom: 0.3125rem;
    padding: 1rem; }
    .js .collapsible .collapsible__title + div > * + * {
      margin-top: 1rem; }
    .js .collapsible .collapsible__title + div:not(hidden) {
      background-color: white; }

.js .collapsible[data-state="reversed"] .collapsible__title button {
  background-color: #273d3f;
  color: white; }
  .js .collapsible[data-state="reversed"] .collapsible__title button:hover, .js .collapsible[data-state="reversed"] .collapsible__title button:active, .js .collapsible[data-state="reversed"] .collapsible__title button[aria-expanded="true"] {
    background-color: #bed974;
    color: #273d3f; }

.collapsible[data-state="reversed"] button svg {
  width: 1rem;
  margin-left: 2.5rem;
  display: initial; }

.collapsible [aria-expanded="true"] svg {
  transform: rotate(0.5turn);
  margin-right: 0;
  margin-left: 2.5rem; }

[class^="cols-list-"] {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    [class^="cols-list-"] {
      /* IE10+ CSS styles */
      list-style: none; } }
  [class^="cols-list-"] li {
    flex: none;
    padding: 0.75rem;
    width: 100%; }
    [class^="cols-list-"] li p, [class^="cols-list-"] li .box, [class^="cols-list-"] li a {
      height: 100%;
      background-color: white;
      padding: 1.125rem;
      font-weight: 600;
      border: 1px solid #dbdbdb; }
      [class^="cols-list-"] li p:hover, [class^="cols-list-"] li .box:hover, [class^="cols-list-"] li a:hover {
        background-color: #78cbd0;
        cursor: pointer;
        border-color: #78cbd0; }
    [class^="cols-list-"] li a {
      display: block;
      text-decoration: none; }
    .events-list [class^="cols-list-"] li p {
      height: auto;
      background-color: transparent;
      font-weight: 400;
      border: none;
      padding: 0; }
  [class^="cols-list-"] ::marker {
    content: "";
    font-size: 0;
    /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */ }

@media screen and (min-width: 900px), print {
  .cols-list-2 li {
    width: 50%; } }

@media screen and (min-width: 900px), print {
  .cols-list-3 li {
    width: 33.333%; } }

@media screen and (min-width: 900px), print {
  .cols-list-4 li {
    width: 33.333%; } }

@media screen and (min-width: 1024px) {
  .cols-list-4 li {
    width: 25%; } }

@media screen and (min-width: 1024px) {
  .cols-list-5 li {
    width: 20%; } }

.list-subportada li a {
  height: 100%;
  min-height: 95px;
  display: block;
  background-color: white;
  padding: 1.125rem;
  font-weight: 600;
  border: 1px solid #dbdbdb; }
  .list-subportada li a:hover {
    background-color: #78cbd0;
    cursor: pointer;
    border-color: #78cbd0; }

[class^="a-cols-list"] {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    [class^="a-cols-list"] {
      /* IE10+ CSS styles */
      list-style: none; } }
  [class^="a-cols-list"] li {
    flex: none;
    padding: 0.75rem;
    width: 100%; }
    [class^="a-cols-list"] li p, [class^="a-cols-list"] li .box {
      height: 100%;
      background-color: white;
      padding: 1.125rem;
      font-weight: 600;
      border: 1px solid #dbdbdb; }
      [class^="a-cols-list"] li p:hover, [class^="a-cols-list"] li .box:hover {
        background-color: #78cbd0;
        cursor: pointer;
        border-color: #78cbd0; }
    [class^="a-cols-list"] li a {
      display: block;
      text-decoration: none; }
    .events-list [class^="a-cols-list"] li p {
      height: auto;
      background-color: transparent;
      font-weight: 400;
      border: none;
      padding: 0; }
    .events-list [class^="a-cols-list"] li a {
      display: inline-flex; }
  [class^="a-cols-list"] ::marker {
    content: "";
    font-size: 0;
    /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */ }

@media screen and (min-width: 900px), print {
  .a-cols-list-2 li {
    width: 50%; } }

@media screen and (min-width: 900px), print {
  .a-cols-list-3 li {
    width: 33.333%; } }

@media screen and (min-width: 900px), print {
  .a-cols-list-4 li {
    width: 33.333%; } }

@media screen and (min-width: 1024px) {
  .a-cols-list-4 li {
    width: 25%; } }

@media screen and (min-width: 1024px) {
  .a-cols-list-5 li {
    width: 20%; } }

.has-submenu > ul li {
  position: relative; }
  .has-submenu > ul li::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: white;
    bottom: 0;
    left: 0; }
  .has-submenu > ul li:last-child::after {
    height: 0; }

.has-submenu > ul > li > a {
  display: block;
  padding: .5rem 0 .25rem 0; }
  .has-submenu > ul > li > a:hover {
    color: #78cbd0; }

.has-submenu > ul ul > * + * {
  margin-top: 0; }

.has-submenu > ul ul li {
  padding-left: 0; }
  .has-submenu > ul ul li::before {
    display: none; }
  .has-submenu > ul ul li:first-child a {
    padding-top: 0; }

@media screen and (max-width: 899px) {
  .has-submenu button {
    display: none; } }

@media screen and (min-width: 900px), print {
  .has-submenu {
    position: relative;
    transition-duration: 0.5s; }
    .has-submenu > ul {
      opacity: 0;
      min-width: 15rem;
      transition: all 0.5s ease;
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%; }
      .has-submenu > ul::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: inset 0.5rem;
        border-color: transparent transparent #273d3f transparent;
        border-bottom-style: solid;
        position: absolute;
        top: -1rem;
        left: 2.3125rem; }
      .has-submenu > ul ul {
        margin-left: 0; }
      .has-submenu > ul > li > a {
        padding-top: 1rem; }
    .has-submenu.doble.open > ul {
      width: 38rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .has-submenu.doble.open > ul li {
        width: 47%; }
        .has-submenu.doble.open > ul li:last-child::after {
          height: 1px; }
    .has-submenu.open > ul {
      display: block;
      z-index: 1;
      opacity: 1; }
    .has-submenu button {
      background: transparent;
      border: 0;
      padding: 0;
      vertical-align: middle;
      cursor: pointer; }
      .has-submenu button > span {
        padding: 10px;
        width: 12px;
        height: 12px;
        display: block;
        box-sizing: content-box; }
        .has-submenu button > span::after {
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          background-image: url(../img/sprites-svg/avall.svg);
          background-repeat: no-repeat; }
          .green-header .has-submenu button > span::after {
            background-image: url(../img/sprites-svg/ico-desplegable.svg); }
      .has-submenu button[aria-expanded="true"] span::after {
        transform: rotate(0.5turn); } }

@media screen and (min-width: 1024px) {
  .has-submenu > ul {
    margin-top: 1.5rem; } }

.equip {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  .equip h2 {
    background-color: #273D3F;
    width: fit-content;
    text-transform: uppercase; }
  .equip .block-equip {
    gap: 4rem; }
    .equip .block-equip .prof {
      margin-bottom: 15px; }
      .equip .block-equip .prof .other-prof {
        border: 2px solid #273D3F;
        padding: 8px; }
      .equip .block-equip .prof .first-prof {
        border: 10px solid #273D3F; }

.full-bleed {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  background-color: #ebfdff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  .full-bleed--img {
    padding-top: 0;
    padding-bottom: 0; }
    .full-bleed--img img {
      width: 100vw;
      margin-left: calc(50% - 50vw);
      max-width: none; }

.green-header {
  background-color: #98B726; }

.full-green {
  position: relative; }
  @media screen and (min-width: 1024px) {
    .full-green:before {
      content: '';
      display: block;
      height: 78px;
      width: 1000%;
      position: absolute;
      top: 0;
      left: -1000%;
      background-color: #98B726; } }

.logo-csapg {
  margin-left: 30px;
  margin-right: 50px; }

.green-banner {
  margin-bottom: 80px;
  position: relative; }
  .green-banner .logo-gran {
    position: absolute;
    bottom: -80px; }

.site-title {
  display: inline-block; }

@media screen and (max-width: 1023px) {
  .header__tools {
    justify-content: center !important;
    flex-direction: column; }
    .header__tools > * {
      padding: 0 !important;
      margin: .25rem; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .header__tools {
    /* IE10+ CSS styles */
    align-items: flex-end; } }

@media screen and (max-width: 899px) {
  .connected-toggles {
    margin: 0 auto; } }

/**
 * Default icon style
 */
.icon {
  /* Use the current text color as the icon’s fill color. */
  fill: currentColor;
  /* Inherit the text’s size too. Also allows sizing the icon by changing its font-size. */
  width: 32px;
  height: 32px;
  /* The default vertical-align is `baseline`, which leaves a few pixels of space below the icon. Using `center` prevents this. For icons shown alongside text, you may want to use a more precise value, e.g. `vertical-align: -4px` or `vertical-align: -0.15em`. */
  vertical-align: middle;
  /* Paths and strokes that overflow the viewBox can show in IE11. */
  overflow: hidden;
  /*
    &--outline-thin,
    &--outline-normal,
    &--outline-thick {
        fill: none;
        stroke: currentColor;
    }
    &--outline-thin {
        stroke-width: 16px;
    }
    &--outline-normal {
        stroke-width: 32px;
    }
    &--outline-thick {
        stroke-width: 24px;
    }
      */ }
  .icon--small {
    width: 16px !important;
    height: 16px !important; }
  .icon--medium {
    width: 22px !important;
    height: 22px !important; }
  .icon--large {
    width: 64px !important;
    height: 64px !important; }

.navigation {
  flex-grow: 1; }
  @media screen and (min-width: 900px), print {
    .navigation {
      flex-grow: initial; } }

.main-nav {
  padding-left: 0;
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem; }
  @media screen and (min-width: 900px), print {
    .main-nav li {
      margin-right: 1rem; } }
  .main-nav a {
    text-decoration: none; }
  @media screen and (min-width: 900px), print {
    .main-nav {
      display: flex;
      flex-wrap: wrap; } }
  @media screen and (min-width: 1216px) {
    .main-nav {
      font-size: 1rem; } }
  @media screen and (min-width: 900px), print {
    .main-nav > li + li {
      margin-left: 0; } }
  @media screen and (min-width: 1024px) {
    .main-nav > li + li {
      margin-left: 0; } }
  @media screen and (min-width: 1216px) {
    .main-nav > li + li {
      margin-left: 1rem; } }
  @media screen and (min-width: 900px), print {
    .main-nav > li > a {
      padding-bottom: 1.125rem;
      color: #273d3f;
      position: relative; }
      .main-nav > li > a::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0.875rem;
        height: 0;
        width: 0;
        background-color: #78cbd0;
        transition: all 200ms ease-in-out; }
        .green-header .main-nav > li > a::after {
          background-color: #fff; }
      .main-nav > li > a:hover::after {
        height: 0.25rem;
        width: 100%; } }
  .green-header .main-nav > li > a {
    color: #fff; }

[aria-current="page"] {
  font-weight: bold; }
  [aria-current="page"]::after {
    height: 0.25rem !important;
    width: 100% !important;
    background-color: #273d3f !important; }
    .green-header [aria-current="page"]::after {
      background-color: #fff !important; }

@media screen and (max-width: 899px) {
  .navigation {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .main-nav > li {
    padding-right: 0 !important; }
    .main-nav > li > a {
      font-size: 1.125rem;
      font-weight: 600;
      display: block;
      background-color: #eff3f3;
      border-bottom: 1px solid #a8c1c3;
      padding: 1.125rem 1.5rem; }
      .green-header .main-nav > li > a {
        background-color: #98B726;
        border-bottom: 1px solid #fff; }
      .main-nav > li > a + ul {
        margin-top: 0 !important;
        margin-bottom: 0;
        padding: 0 !important; }
        .main-nav > li > a + ul a {
          display: block;
          padding: 1.125rem 1.5rem;
          font-weight: normal;
          font-size: 0.875rem; } }

.hamburger {
  cursor: default;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0; }
  @media screen and (min-width: 900px), print {
    .hamburger {
      display: none; } }

@media screen and (min-width: 900px), print {
  #menu {
    display: block; } }

.skip-link {
  position: absolute;
  top: 1.5rem;
  right: 100%;
  /* moves off screen */
  background-color: #fff;
  padding: 1rem;
  font-size: 1rem;
  text-decoration: none;
  color: #000 !important;
  font-weight: normal; }
  .skip-link:focus {
    right: auto;
    left: 1.5rem;
    z-index: 10; }

.site-footer {
  padding-top: 12.5rem; }

.js .tab-menu {
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  list-style: none; }
  .js .tab-menu li {
    flex-grow: 1;
    padding: 0;
    margin: 0; }
    .js .tab-menu li:last-child {
      margin-right: 0; }
  .js .tab-menu a {
    text-decoration: none;
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    display: block;
    padding: 1.5625rem 1rem;
    background-color: #273d3f;
    color: white;
    font-size: 0.875rem; }
    .js .tab-menu a:hover, .js .tab-menu a[aria-selected] {
      background-color: #bed974;
      color: #273d3f; }
    .js .tab-menu a:focus {
      outline: 2px dotted #4f6567 !important;
      outline-offset: 1px; }
    @media screen and (min-width: 1024px) {
      .js .tab-menu a {
        font-size: 1.125rem; } }

.js .tab-panel {
  margin-top: 0; }

.tab-panel h2 {
  font-size: 1.5rem; }

@media screen and (max-width: 899px) {
  .js .tab-menu {
    display: block; }
    .js .tab-menu li {
      display: block;
      margin-right: 0;
      margin-top: 1px; }
      .js .tab-menu li:first-child {
        margin-top: 0; } }

@media screen and (min-width: 900px), print {
  .js .tabbed--vertical {
    display: flex; }
    .js .tabbed--vertical .tab-menu {
      display: block;
      flex: 1 0 25%; }
      .js .tabbed--vertical .tab-menu li {
        display: block;
        margin: 0 0 1rem 0; }
      .js .tabbed--vertical .tab-menu a {
        display: block; } }

.tabs-jornades::after {
  content: '';
  clear: both;
  display: table; }

.at-accordion-or-tabs {
  margin: 0; }
  .at-accordion-or-tabs section {
    margin-top: 77px; }
    .at-accordion-or-tabs section h2 {
      font-size: 1.5rem; }

.at-accordion-or-tabs:not(.at-tabs) > li {
  border: 0;
  margin-bottom: 0; }
  .at-accordion-or-tabs:not(.at-tabs) > li > a {
    padding: 1.5625rem 1rem;
    position: relative;
    border-bottom: 1px solid #fff;
    font-size: .875rem;
    font-weight: 500;
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif; }
    .at-accordion-or-tabs:not(.at-tabs) > li > a::before, .at-accordion-or-tabs:not(.at-tabs) > li > a::after {
      content: '';
      background-color: #78cbd0;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 200%; }
    .at-accordion-or-tabs:not(.at-tabs) > li > a::before {
      display: block;
      left: -200%; }
    .at-accordion-or-tabs:not(.at-tabs) > li > a::after {
      display: block;
      right: -200%; }
    .at-accordion-or-tabs:not(.at-tabs) > li > a:focus {
      background-color: #bed974; }

.at-accordion-or-tabs:not(.at-tabs) > li > a {
  background-color: #78cbd0; }

.at-accordion-or-tabs:not(.at-tabs) > li > a.active,
.at-accordion-or-tabs:not(.at-tabs) > li > a:hover {
  color: #fff;
  background: #273d3f; }
  .at-accordion-or-tabs:not(.at-tabs) > li > a.active::before, .at-accordion-or-tabs:not(.at-tabs) > li > a.active::after,
  .at-accordion-or-tabs:not(.at-tabs) > li > a:hover::before,
  .at-accordion-or-tabs:not(.at-tabs) > li > a:hover::after {
    background: inherit; }

.at-accordion-or-tabs > li > section {
  padding: 0;
  background: transparent; }

.at-accordion-or-tabs.at-tabs > li {
  margin-left: -4px; }
  .at-accordion-or-tabs.at-tabs > li > a {
    position: relative;
    padding: 1.5625rem 5rem 1.5625rem 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif; }
  .at-accordion-or-tabs.at-tabs > li:first-child > a::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    left: -3000px;
    width: 3000px;
    background-color: #78cbd0; }
  .at-accordion-or-tabs.at-tabs > li:last-child > a::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -3000px;
    left: 100%;
    width: 3000px;
    background-color: #78cbd0; }

.at-accordion-or-tabs.at-tabs > li > section {
  border: 0; }

.at-accordion-or-tabs.at-tabs > li > a {
  border: none;
  color: inherit;
  background-color: #78cbd0; }
  .at-accordion-or-tabs.at-tabs > li > a:focus {
    background-color: #bed974; }

.at-accordion-or-tabs.at-tabs > li > a:hover,
.at-accordion-or-tabs.at-tabs > li > a.active {
  color: #fff;
  background: #273d3f; }

.at-accordion-or-tabs.at-tabs .at-tab-one-pixel-fix-left,
.at-accordion-or-tabs.at-tabs .at-tab-one-pixel-fix-right {
  display: none; }

.download-doc {
  display: inline-block;
  position: relative;
  font-size: 1rem;
  text-decoration: none;
  color: #273d3f;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }
  .download-doc:hover span {
    text-decoration: underline; }
  .download-doc .icon {
    position: relative;
    top: -.13rem; }
  .download-doc[data-state="reversed"] {
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 600; }
    .download-doc[data-state="reversed"] .icon {
      position: relative;
      top: -.25rem; }
  .download-doc a {
    text-decoration: none;
    position: relative;
    padding-left: 1.5rem; }
    .download-doc a:before {
      content: '';
      display: block;
      position: absolute;
      width: 18px;
      height: 18px;
      background-image: url("../img/sprites-svg/pdf.svg");
      background-repeat: no-repeat; }

.related-docs {
  font-size: 1rem;
  padding: 1.125rem;
  padding-left: 2.5rem;
  padding-right: 4.5rem;
  background-image: url(../img/card-descarrega-bg.png);
  background-repeat: no-repeat;
  background-position: right 20px top 20px;
  background-color: white; }
  .card-icons .related-docs {
    background-image: none;
    padding-left: 1.125rem; }
  .collapsible .related-docs {
    background-color: #eff3f3; }
  .related-docs > * + * {
    margin-top: 0.875rem; }
  .related-docs li::marker {
    color: transparent; }
  .related-docs a {
    text-decoration: none;
    position: relative; }
    .related-docs a:hover {
      text-decoration: underline; }
    .related-docs a::before {
      /*content: "";
            display: inline-block;
            background-image: url("../img/sprites-svg/doc.svg");
            vertical-align: sub;
            width: 20px;
            height: 22px;
            margin-right: 0.5rem;
            background: no-repeat left center;*/ }
    .related-docs a:before {
      content: '';
      display: block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: -22px;
      background-image: url("../img/sprites-svg/link.svg");
      background-repeat: no-repeat; }
    .related-docs a[href$=".pdf"]::before {
      background-image: url("../img/sprites-svg/pdf.svg"); }
    .related-docs a[href$=".doc"], .related-docs a[href$=".docx"] {
      background-image: url("../img/sprites-svg/docu.svg"); }

table {
  width: 100%;
  background-color: #fff;
  color: #363636; }
  table thead {
    background-color: transparent; }
  table th, table td {
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    border: 1px solid #dbdbdb;
    color: #363636;
    padding: .5em .75em;
    vertical-align: top;
    text-align: left; }

.cal {
  background-color: transparent;
  color: #fff !important; }
  .cal th, .cal td {
    border: 0;
    color: #fff !important;
    padding: 3px; }

.input, .select select, .textarea {
  border-radius: 0;
  box-shadow: none;
  border-width: 1px;
  border-color: #b5b5b5; }
  .input:hover, .select select:hover, .textarea:hover {
    border-color: #ddd; }

.input, .select select {
  height: 3rem; }

fieldset {
  margin-bottom: 1rem; }

.label {
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  margin-bottom: 0.125rem; }
  .label:not(:last-child) {
    margin: 0.125rem; }

.select {
  width: 100%; }
  .select select {
    width: 100%; }

.file-cta {
  border-radius: 0;
  background-color: white;
  white-space: normal;
  height: auto;
  align-items: first baseline;
  border-color: #b5b5b5; }
  .file-cta:hover {
    border-color: #ddd; }

.file-name {
  border-radius: 0; }

ul.field {
  list-style: none;
  padding-left: 0; }

.is-checkradio + label::before {
  background-color: #fff; }

.is-checkradio[type=checkbox] + label::before {
  border-color: #b5b5b5; }
  .is-checkradio[type=checkbox] + label::before:hover {
    border-color: #ddd; }

legend {
  margin-bottom: 1.125rem;
  font-weight: bold;
  font-size: 1.125rem; }

.is-checkradio:focus + label::before {
  outline: 2px dotted #4f6567 !important;
  outline-offset: 1px; }

::placeholder {
  color: #273d3f !important; }

.form-alert,
label.error {
  color: #a01212; }

label.error {
  font-size: 1rem; }

input.error {
  border: 1px #a01212 solid; }

.select-others:not(.is-multiple) {
  height: auto; }

input.input-others {
  margin-top: 1.125rem; }

.select:not(.is-multiple):not(.is-loading)::after {
  top: 25px; }

.dropdown-lang {
  position: relative;
  border-right: 1px solid #eff3f3;
  padding-right: 0.5rem;
  margin-right: 0.5rem; }
  .dropdown-lang .dropdown-trigger {
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 1.125rem;
    white-space: nowrap; }
    .dropdown-lang .dropdown-trigger .icon {
      width: 10px; }
    .dropdown-lang .dropdown-trigger button {
      background-color: transparent;
      border: 0; }
      .dropdown-lang .dropdown-trigger button::after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url("../img/sprites-svg/avall.svg");
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-left: 5px; }
  .dropdown-lang .dropdown-menu {
    position: absolute;
    z-index: 100;
    right: -150px;
    top: 2.85rem;
    background-color: #78cbd0;
    padding: 1.5rem;
    min-width: 25rem; }
    @media screen and (min-width: 900px), print {
      .dropdown-lang .dropdown-menu {
        top: 3.05rem;
        right: 0; } }
    @media screen and (min-width: 1024px) {
      .dropdown-lang .dropdown-menu {
        top: 3.4rem; } }

.dropdown-cerca {
  position: relative; }
  .dropdown-cerca .dropdown-trigger {
    cursor: pointer;
    background-color: transparent;
    border: none; }
    .dropdown-cerca .dropdown-trigger .icon {
      width: 29px; }
    .dropdown-cerca .dropdown-trigger button {
      background-color: transparent;
      border: 0;
      background-image: url("../img/sprites-svg/cerca.svg");
      background-repeat: no-repeat;
      color: transparent;
      width: 27px;
      height: 27px; }
  .dropdown-cerca .dropdown-menu {
    position: absolute;
    z-index: 100;
    right: -120px;
    top: 2.8rem;
    background-color: #78cbd0;
    padding: 2rem;
    min-width: 25rem; }
    @media screen and (min-width: 900px), print {
      .dropdown-cerca .dropdown-menu {
        right: 0;
        top: 2.85rem; } }
    @media screen and (min-width: 1024px) {
      .dropdown-cerca .dropdown-menu {
        top: 3.15rem; } }
    .dropdown-cerca .dropdown-menu button {
      margin-left: 0.875rem;
      background-color: transparent;
      border-radius: 0; }

.header-media {
  white-space: nowrap; }
  @media screen and (max-width: 899px) {
    .header-media {
      text-align: center;
      margin-bottom: 1rem; } }
  @media screen and (min-width: 900px), print {
    .header-media {
      border-right: 1px solid #eff3f3;
      padding-right: .75rem;
      margin-right: .5rem; } }
  .header-media a {
    text-decoration: none; }
    .header-media a .icon {
      width: 20px;
      height: 20px; }
  .header-media .header-xxss {
    list-style: none; }
    .header-media .header-xxss li {
      display: inline-block; }

.footer-media {
  white-space: nowrap;
  padding: 1.25rem 0 2.5625rem 0; }
  .footer-media a {
    text-decoration: none; }
    .footer-media a .icon {
      width: 20px;
      height: 20px; }
  .footer-media .footer-xxss {
    padding-left: 0;
    list-style: none; }
    .footer-media .footer-xxss li {
      display: inline-block;
      padding-right: 0.875rem; }

.logos-footer {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 670px; }
  .logos-footer a {
    display: block;
    padding: .5rem; }
  .logos-footer ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-left: 0; }
    .logos-footer ul li a {
      display: block;
      padding: .5rem; }

.scrolltop {
  opacity: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #bed974;
  width: 12rem;
  display: flex;
  justify-content: center;
  transition: opacity 400ms ease-in-out; }
  .scrolltop.visible {
    opacity: 1; }
  .scrolltop:focus, .scrolltop:focus-visible {
    outline-color: #fff !important; }

.footer-dark {
  padding-bottom: 110px !important; }
  @media screen and (min-width: 900px), print {
    .footer-dark {
      padding-bottom: 2.5rem !important; } }

.main-slider {
  margin-bottom: 0 !important;
  overflow: hidden; }
  .js .main-slider {
    visibility: hidden; }
    .js .main-slider.slick-initialized {
      visibility: visible; }
  .main-slider .slick-slide img {
    height: 20rem;
    margin: 0 auto; }
    @supports (object-fit: cover) {
      .main-slider .slick-slide img {
        object-fit: cover;
        width: 100%;
        margin: 0; } }
    @media screen and (min-width: 900px), print {
      .main-slider .slick-slide img {
        height: 30rem; } }
    @media screen and (min-width: 1024px) {
      .main-slider .slick-slide img {
        height: 35rem; } }
  .main-slider h2 {
    font-size: 3vw; }
  @media (max-width: 1000px) {
    .main-slider .column,
    .main-slider .columns {
      display: block !important;
      width: 100%;
      margin: 0;
      padding: 0; }
    .main-slider h2 {
      font-size: 1.375rem; } }
  @media (min-width: 1500px) {
    .main-slider h2 {
      font-size: 2.5rem; } }
  .main-slider .slick-dots {
    bottom: 3.5rem; }
    @media screen and (min-width: 1024px) {
      .main-slider .slick-dots {
        text-align: left;
        padding-left: 2rem; } }
    .main-slider .slick-dots li {
      width: 1.875rem;
      height: 0.375rem;
      border: 1px solid #273d3f; }
      .main-slider .slick-dots li.slick-active {
        background-color: #273d3f; }
    @media screen and (max-width: 899px) {
      .main-slider .slick-dots {
        position: relative;
        z-index: 10; } }
    @media screen and (min-width: 900px) and (max-width: 1023px) {
      .main-slider .slick-dots {
        position: relative; } }
    @media (max-width: 1000px) {
      .main-slider .slick-dots {
        bottom: 0; } }
    .main-slider .slick-dots button {
      width: 1.875rem;
      margin: 0;
      padding: 0; }
  .main-slider .slick-next,
  .main-slider .slick-prev {
    z-index: 10;
    height: 30px;
    width: 30px;
    top: auto;
    bottom: 2.5rem; }
    .main-slider .slick-next-icon,
    .main-slider .slick-prev-icon {
      font-size: 30px; }
  .main-slider .slick-next {
    right: 1rem; }
  .main-slider .slick-prev {
    left: 1rem; }

.submenu-home h2 {
  background-color: #273d3f;
  background-repeat: no-repeat;
  background-position: 96% center;
  color: white;
  padding: 1.125rem 1.625rem; }
  @media screen and (min-width: 1024px) {
    .submenu-home h2 {
      background-image: url(../img/que-necessites.png);
      background-size: 26px auto; } }
  @media screen and (min-width: 1216px) {
    .submenu-home h2 {
      background-size: 36px auto; } }
  @media (max-width: 1200px) and (min-width: 769px) {
    .submenu-home h2 {
      font-size: 1.25rem !important; } }

@media screen and (min-width: 1024px) {
  .submenu-home .column + .column h2 {
    background-image: url(../img/logo-inici.png); } }

@media screen and (min-width: 1024px) {
  .submenu-home {
    position: relative;
    z-index: 10; } }

@media (max-width: 769px) {
  .submenu-home .column.has-background-white {
    margin: 0 .75rem; } }

@media screen and (min-width: 1024px) {
  .home .submenu-home {
    margin-top: -5.5rem;
    margin-bottom: 6.875rem !important; } }

.submenu {
  margin-top: 0;
  font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  list-style: none;
  padding: 0; }
  .submenu li a {
    display: block;
    padding: 10px;
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent;
    background-color: #78cbd0 !important;
    color: #273d3f !important; }
    .submenu li a:hover, .submenu li a[aria-selected] {
      background-color: #273d3f !important;
      color: #fff !important;
      -webkit-text-decoration-color: inherit !important;
      text-decoration-color: inherit !important; }
  .submenu li[data-state="selected"] a {
    -webkit-text-decoration-color: inherit;
    text-decoration-color: inherit; }
  @media screen and (min-width: 900px), print {
    .submenu {
      display: flex;
      justify-content: space-between; }
      .submenu li {
        flex-grow: 1;
        cursor: pointer; }
        .submenu li[data-state="selected"], .submenu li:hover {
          background-color: #273d3f;
          color: white;
          -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent; }
        .submenu li a {
          padding: 1.625rem; } }

.submenu-tabs .tab-menu {
  position: relative; }
  .submenu-tabs .tab-menu li:last-child {
    margin-right: -1px; }
  .submenu-tabs .tab-menu:after {
    content: '';
    background-color: #78cbd0;
    display: block;
    height: 100%;
    width: 1000%;
    position: absolute;
    top: 0;
    left: -1000%; }
  .submenu-tabs .tab-menu:before {
    content: '';
    background-color: #78cbd0;
    display: block;
    height: 100%;
    width: 1000%;
    position: absolute;
    top: 0;
    right: -1000%; }

.submenu-tabs .tabbed :target {
  display: block;
  position: relative;
  top: -77px; }

.js .submenu-tabs .tab-panel {
  margin-top: 77px; }

.submenu-basic {
  background-color: #78cbd0;
  margin-top: 0; }
  .submenu-basic .submenu {
    /*&:after {
      content:'';
      background-color: map-get($custom-colors, blue-bg-1);
      display: block;
      height:100%;
      width: 1000%;
      position: absolute;
      top: 0;
      left: -1000%;
  }
  &:before {
    content:'';
    background-color: map-get($custom-colors, blue-bg-1);
      display: block;
      height:100%;
      width: 1000%;
      position: absolute;
      top: 0;
      right: -1000%;
    }*/ }
    .submenu-basic .submenu li a.active {
      background-color: #273d3f !important;
      color: #fff !important;
      -webkit-text-decoration-color: inherit !important;
      text-decoration-color: none !important; }

.side-menu {
  list-style: none;
  padding: 0; }
  .side-menu li {
    margin-bottom: .375rem; }
    .side-menu li a {
      display: block;
      padding: 1.25rem;
      background-color: #273d3f;
      color: white;
      font-size: 1.375rem;
      font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
      font-weight: 600;
      -webkit-text-decoration-color: transparent;
      text-decoration-color: transparent; }
      .side-menu li a:hover {
        background-color: #78cbd0;
        color: #273d3f; }
      .side-menu li a span {
        padding-left: 1.25rem; }

.info-centre.clic:hover .info-title {
  opacity: 0.8; }

.info-centre .info-title {
  height: 10.5rem;
  position: relative;
  background-size: cover;
  background-color: #666;
  /*div {
            position: absolute;
            font-size: 1.5rem;
            font-family: $family-secondary;
            color: $white;
            top: 0.875rem;
            left: 1.125rem;
            line-height: 1.3;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

            span {
                display: block;
                font-weight: 700;
            }
        }*/ }
  .info-centre .info-title + div {
    font-size: 1.625rem;
    font-weight: 600;
    padding-top: 0.625rem; }
  .info-centre .info-title a {
    position: absolute;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 1.5rem;
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    color: white;
    padding-top: 0.875rem;
    padding-left: 1.125rem;
    line-height: 1.3;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); }
    .info-centre .info-title a span {
      font-weight: 700; }

.info-centre ul {
  padding-bottom: 2.25rem; }

.card-news {
  transition: all 300ms ease-in-out;
  padding: 1rem; }
  .card-news img {
    margin-bottom: 1.25rem; }
    .card-news img + div {
      font-size: .9375rem;
      color: #4f6567;
      margin-bottom: .625rem; }
  .card-news h3 {
    padding-bottom: 1.25rem; }
  .card-news:hover {
    background-color: white;
    cursor: pointer;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1); }

.card-destacats > div {
  height: 13.125rem;
  background-color: #666;
  background-size: cover;
  padding: 1.1875rem;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  line-height: 1; }
  @media screen and (min-width: 900px), print {
    .card-destacats > div {
      font-size: 1.5rem; } }
  @media screen and (min-width: 1024px) {
    .card-destacats > div {
      font-size: 2rem; } }

.card-destacats a {
  -webkit-text-decoration: transparent;
  text-decoration: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); }

.card-agenda {
  padding: 0.375rem; }
  .card-agenda .card-agenda-text {
    padding-left: 2.125rem; }
    .card-agenda .card-agenda-text > div:first-child {
      font-size: 4.375rem;
      font-weight: 500;
      line-height: 1.2; }
      .card-agenda .card-agenda-text > div:first-child + p {
        line-height: 1.2;
        padding-bottom: 2.4375rem; }

.card-icons {
  padding: 1.375rem;
  background-image: url(../img/card-descarrega-bg.png);
  background-repeat: no-repeat;
  background-position: right 20px top 20px;
  background-color: white; }
  .card-icons[data-state="alt"] {
    background-color: #eff3f3; }
  .card-icons[data-state="ubicacio"] {
    background-image: url(../img/card-ubicacio-bg.png);
    background-position: right 3px top 12px; }
  .card-icons[data-state="equip"] {
    background-image: url(../img/card-equip.png);
    background-position: right 10px top 15px; }

.a-cols-list-4 li .card-list-img {
  text-align: center; }
  .a-cols-list-4 li .card-list-img p {
    background: none;
    border: 0;
    font-weight: normal;
    padding: 0; }
    .a-cols-list-4 li .card-list-img p:hover {
      cursor: auto; }
  .a-cols-list-4 li .card-list-img h3 {
    padding-bottom: 1.125rem; }
  .a-cols-list-4 li .card-list-img a {
    display: inline-flex;
    margin-top: 1.125rem; }

.banner {
  background-color: #666;
  background-size: cover;
  min-height: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .banner h1 {
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    color: white;
    font-size: 1.875rem;
    font-size: 10vw;
    text-align: center;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.8); }
  @media screen and (min-width: 900px), print {
    .banner {
      min-height: 21.875rem; }
      .banner h1 {
        font-size: 2.5rem; } }
  @media screen and (min-width: 1024px) {
    .banner {
      min-height: 31.25rem; }
      .banner h1 {
        font-size: 3.75rem; } }

.banner-no-img {
  background-color: #273d3f;
  min-height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .banner-no-img h1 {
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    color: white;
    font-size: 1.875rem;
    font-size: 10vw;
    text-align: center;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.25); }
  @media screen and (min-width: 900px), print {
    .banner-no-img {
      min-height: 8rem; }
      .banner-no-img h1 {
        font-size: 2.5rem; } }
  @media screen and (min-width: 1024px) {
    .banner-no-img {
      min-height: 10rem; }
      .banner-no-img h1 {
        font-size: 3.75rem; } }

.banner-no-img-no-text {
  background-color: #273d3f;
  min-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; }

body.usuaris-visitar .banner {
  background-image: url(../img/banner-visitar.jpg); }

body.usuaris-prova .banner {
  background-image: url(../img/banner-prova.jpg); }

body.usuaris-urgencies .banner {
  background-image: url(../img/banner-urgencies.jpg); }

body.usuaris-operar .banner {
  background-image: url(../img/banner-operar-bg.jpg); }

body.serveis .banner {
  background-image: url(../img/banner-serveis.jpg); }

body.serveis-anestesia .banner {
  background-image: url(../img/banner-anestesia.jpg); }

body.serveis-diagnostic-imatge .banner {
  background-image: url(../img/banner-diagnostic-imatge.jpg); }

body.serveis-dialisi .banner {
  background-image: url(../img/banner-dialisi.jpg); }

body.professionals .banner {
  background-image: url(../img/banner-professionals.jpg); }

body.professionals-treballa .banner,
body.professionals-curriculum .banner {
  background-image: url(../img/banner-treballa.jpg); }

body.professionals-docencia .banner {
  background-image: url(../img/banner-docencia.jpg); }

body.professionals-recerca .banner {
  background-image: url(../img/banner-recerca.jpg); }

body.consorci .banner {
  background-image: url(../img/banner-consorci.jpg); }

body.consorci-missio .banner,
body.consorci-els-centres .banner {
  background-image: url(../img/banner-missio.jpg); }

body.consorci-centre .banner {
  background-image: url(../img/banner-centre.jpg); }

body.consorci-portal-transparencia .banner {
  background-image: url(../img/banner-portal.jpg); }

body.consorci-perfil-contractant .banner {
  background-image: url(../img/banner-contractant.jpg); }

body.consorci-comunicacio .banner {
  background-image: url(../img/banner-comunicacio.jpg); }

body.consorci-memoria .banner {
  background-image: url(../img/banner-memoria.jpg); }

.banner-jornades {
  overflow: hidden; }
  .banner-jornades .column div {
    padding: 1rem; }
  .banner-jornades h1 {
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 6vw;
    margin-bottom: 1.6875rem; }
  @media screen and (min-width: 900px), print {
    .banner-jornades h1 {
      font-size: 3vw; } }

.file.is-fullwidth {
  width: 100%; }
  .file.is-fullwidth .file-cta {
    width: 100%;
    justify-content: center;
    height: 3rem;
    border: .125rem solid;
    background: transparent; }
    .file.is-fullwidth .file-cta .file-label {
      width: auto;
      text-transform: uppercase;
      font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
      font-size: .875rem;
      font-weight: bold;
      padding-right: .5rem; }

.fil-ariadna {
  list-style: none;
  padding: .3125rem 1.5rem; }
  .fil-ariadna li {
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    background: url(../img/ariadna-bg.png) no-repeat left center;
    padding-left: .9375rem; }
    .fil-ariadna li:last-child a:hover {
      text-decoration: none;
      cursor: default; }
  @media (min-width: 768px) {
    .fil-ariadna li {
      display: inline-block;
      background: transparent;
      padding-left: 0; }
    .fil-ariadna li + li {
      padding-left: 15px; }
      .fil-ariadna li + li::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: .9375rem;
        height: 1.125rem;
        background: url(../img/ariadna-bg.png); } }
  .cercador .fil-ariadna {
    padding-left: 0;
    margin-top: 1rem; }

.custom-list-1 {
  list-style: none;
  padding: 0; }
  .custom-list-1 > * + * {
    margin-top: 1rem; }
  .custom-list-1 li {
    position: relative;
    padding-left: 1.25rem; }
    .custom-list-1 li::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 12px;
      width: .6875rem;
      height: .25rem;
      background-color: #009ba9; }
  .custom-list-1 strong:first-of-type {
    display: block; }

@media screen and (min-width: 900px), print {
  .custom-list-1-4col {
    column-count: 2; } }

@media screen and (min-width: 1024px) {
  .custom-list-1-4col {
    column-count: 4; } }

.custom-list-2 {
  list-style: none;
  padding: 0; }
  .custom-list-2 li {
    position: relative;
    background-color: white;
    padding: .875rem 1.5625rem .875rem 2.1875rem; }
    .custom-list-2 li::before {
      content: '';
      display: block;
      position: absolute;
      left: .9375rem;
      top: .9375rem;
      width: .5625rem;
      height: 1.358125rem;
      background: url(../img/custom-list-2-bg.png); }
    .custom-list-2 li.clic {
      cursor: pointer; }
    .custom-list-2 li p {
      font-size: .9375rem; }

.ofertes a span {
  display: block;
  max-width: 700px; }

.headline-list {
  list-style: none;
  padding: 0; }
  .headline-list li {
    border-bottom: 1px solid #a8c1c3;
    padding-bottom: 1rem; }
    .headline-list li span {
      display: block;
      font-size: .9375rem; }

.highlighted-list {
  padding-left: 0; }
  .highlighted-list li {
    display: flex;
    background-color: #fff;
    padding: 1.125rem;
    font-weight: 500; }
  .highlighted-list > * + * {
    margin-top: 0.875rem; }
  .highlighted-list strong:first-child {
    flex: 0 0 11rem;
    background-color: #78cbd0;
    padding: 1.125rem;
    font-size: 1.375rem;
    font-weight: 700;
    margin: -1.125rem;
    margin-right: 1.125rem;
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif; }

/* Cookie Dialog */
#gdpr-cookie-message {
  position: fixed;
  right: 0;
  bottom: 60px;
  max-width: 450px;
  background-color: #fff;
  opacity: 0.9;
  padding: 30px 35px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  margin-left: 30px;
  z-index: 9999; }

#gdpr-cookie-message hr {
  margin: 10px 0; }

#gdpr-cookie-message h4 {
  color: #1C1C1C;
  font-size: 1.125rem;
  font-weight: bolder;
  margin-bottom: 10px; }

#gdpr-cookie-message h5 {
  color: #1C1C1C;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: none; }

#gdpr-cookie-message p,
#gdpr-cookie-message ul {
  color: #1C1C1C;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0 0 1.125rem;
  padding: 0; }

#gdpr-cookie-message ul li:before {
  display: none; }

#gdpr-cookie-message p:last-child {
  margin-bottom: 0;
  text-align: right; }

#gdpr-cookie-message li {
  width: 49%;
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-left: 0; }

#gdpr-cookie-message label {
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: inherit !important; }

#gdpr-cookie-message a {
  color: #29855B !important;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bolder;
  padding-bottom: 2px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.75);
  transition: all 0.3s ease-in; }

#gdpr-cookie-message a:hover {
  color: white;
  border-bottom-color: #29855B;
  transition: all 0.3s ease-in; }

#gpdr-cookie-message a:focus {
  outline: none !important; }

#gdpr-cookie-message button,
button#ihavecookiesBtn {
  font-size: 14px;
  padding: 7px 20px;
  margin-left: 0;
  cursor: pointer;
  transition: all 0.3s ease-in;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 8px; }

#gdpr-cookie-message button#gdpr-cookie-accept,
#gdpr-cookie-message button#gdpr-cookie-reject {
  background-color: #29855B;
  color: #FFF;
  border: none; }

#gdpr-cookie-message button#gdpr-cookie-accept:hover,
#gdpr-cookie-message button#gdpr-cookie-reject:hover {
  transition: all 0.3s ease-in;
  opacity: 0.7; }

#gdpr-cookie-message button#gdpr-cookie-advanced {
  background: none;
  border: 1px solid #1C1C1C;
  color: #1C1C1C; }

#gdpr-cookie-message button:disabled {
  opacity: 0.3; }

#gdpr-cookie-message input[type="checkbox"] {
  float: none;
  margin-top: 0;
  margin-right: 5px;
  background: #e0e0e0;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  opacity: 0.9;
  border: none;
  vertical-align: middle;
  margin-bottom: 0; }

#gdpr-cookie-message input[type="checkbox"]:focus {
  outline: none !important; }

#gdpr-cookie-message input[type="checkbox"]:hover {
  background: #1C1C1C;
  cursor: pointer;
  border: 0; }

#gdpr-cookie-message input[type="checkbox"]:checked {
  background: #1C1C1C; }

#gdpr-cookie-message input[type="checkbox"]:disabled {
  background: #29855B; }

#gdpr-cookie-message input[type="checkbox"]:disabled:hover {
  background: #29855B;
  cursor: default !important; }

#gdpr-cookie-message input[type="checkbox"]:checked:after {
  content: "";
  background: url(img/check-ico-cookies.png) no-repeat 4px 5px;
  width: 24px;
  height: 24px;
  position: relative;
  top: 0px;
  left: 0px;
  display: block;
  background-color: #29855B; }

@media (max-width: 490px) {
  #gdpr-cookie-message {
    opacity: 1; }
  #gdpr-cookie-message button {
    float: left; }
  #gdpr-cookie-message li {
    font-size: 14px; }
  .isiOS.isiPhone #gdpr-cookie-message {
    padding: 10px 20px; } }

.pagination-list {
  margin-left: 0; }
  .pagination-list > * + * {
    margin-top: 0 !important; }
  .pagination-list li {
    border-bottom: none !important;
    padding-bottom: 0 !important; }
    .pagination-list li:before {
      content: none !important; }

.pagination-previous,
.pagination-next,
.pagination-link {
  border-color: #000;
  color: #000;
  font-size: 0.875rem;
  text-decoration: none;
  border-radius: 0; }
  .pagination-previous:hover,
  .pagination-next:hover,
  .pagination-link:hover {
    border-color: #009ba9; }

.pagination-link.is-current {
  background-color: #273d3f;
  border-color: #273d3f;
  color: #fff; }

.mosaic {
  position: relative; }
  .mosaic > div {
    font-family: Noto Sans JP, Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    background-color: #bed974;
    line-height: 1.3;
    padding: 1.875rem; }
  @media screen and (min-width: 1024px) {
    .mosaic img {
      width: 80%; }
    .mosaic > div {
      width: 48%;
      padding: 3.75rem;
      position: relative;
      margin-left: auto;
      margin-top: -9rem; }
    .mosaic[data-state="reversed"] {
      display: flex;
      justify-content: flex-end; }
      .mosaic[data-state="reversed"] > div {
        right: auto;
        left: 0; } }
  @media screen and (min-width: 1216px) {
    .mosaic > div {
      font-size: 2.125rem; } }

.menufooter {
  list-style: none;
  padding-left: 0; }
  .menufooter li {
    display: inline;
    margin-right: 1rem; }

.patrocinadors {
  transform: translateY(12.5rem); }

.list-arrow {
  list-style: none;
  padding-left: 0; }
  .list-arrow li {
    position: relative;
    padding-left: 1.125rem; }
    .list-arrow li:before {
      content: '';
      display: block;
      background-image: url("../img/sprites-svg/fletxa.svg");
      width: 14px;
      height: 13px;
      position: absolute;
      left: 0;
      top: 6px; }
    .list-arrow li a {
      text-decoration: none; }
      .list-arrow li a:hover {
        text-decoration: underline; }

.ofertes__estat {
  position: relative;
  color: #273d3f;
  font-size: 0.875rem !important;
  border-radius: 8px;
  margin-left: 30px;
  margin-top: 5px; }
  .ofertes__estat:before {
    content: '';
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    left: -30px;
    top: -3px; }
  @media screen and (min-width: 900px), print {
    .ofertes__estat {
      position: absolute;
      top: 1rem;
      right: 1.5rem; } }

.ofertes__estat--1:before {
  background-color: #c00; }

.ofertes__estat--2:before {
  background-color: #d5692a; }

.ofertes__estat--0:before {
  background-color: #76ac37; }

.ofertes__estat--3:before {
  background-color: #0bc !important; }

.ofertes li {
  position: relative; }

@media screen and (max-width: 899px) {
  .xarxes-home {
    display: none; } }

.card-xxss {
  padding: 1.375rem;
  background-image: url(../img/share.svg);
  background-repeat: no-repeat;
  background-position: right 20px top 20px;
  background-color: white; }
  .card-xxss ul {
    padding-left: 0;
    margin-top: 2.5rem; }
    .card-xxss ul li {
      display: inline-block;
      padding-right: 2.5rem; }
      .card-xxss ul li a {
        opacity: .8; }

.seccio li {
  margin-bottom: 1rem; }
  .seccio li h3 {
    margin: 2.5rem 0 1.5rem 0; }
  .seccio li img {
    float: left;
    margin-right: 2rem;
    margin-bottom: 1.5rem; }

div.seccio.exercicis li {
  display: inline-block; }
