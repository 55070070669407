.link-dn {
    text-decoration-color: transparent;
    &:hover {
        text-decoration-color: inherit;
    }
    a {
        text-decoration-color: transparent;
        &:hover {
            text-decoration-color: inherit;
        }
    }
}

