.side-menu {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: .375rem;

    a {
      display: block;
      padding: 1.25rem;
      background-color: map-get($custom-colors, darker);
      color: $white;
      font-size: 1.375rem;
      font-family: $family-secondary;
      font-weight: 600;
      -webkit-text-decoration-color: transparent;
      text-decoration-color: transparent;
      &:hover {
        background-color: map-get($custom-colors, blue-bg-1);
        color: map-get($custom-colors, darker);
      }
      span {
        padding-left: 1.25rem;
      }
    }
  }
}