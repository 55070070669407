.has-submenu {
    // subemnú mòbil
    > ul {
        li {
          position: relative;
          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $white;
            bottom: 0;
            left: 0;
          }
          &:last-child::after {
            height: 0;
          }
        }
        > li > a {
            display: block;
            padding: .5rem 0 .25rem 0;
            &:hover {
              color: map-get($custom-colors, blue-bg-1);
            }
        }
        ul {
            > * + * {
                margin-top: 0;
            }
            li {
                padding-left: 0;
                &::before {
                    display: none;
                }
                &:first-child a {
                    padding-top: 0;
                }
            }
        }
    }

    @include mobile {
      button {
        display: none;
      }
    }

    // submenú desktop
    @include tablet {
        position: relative;
        transition-duration: 0.5s;
        > ul {
            opacity: 0;
            min-width: 15rem;
            transition: all 0.5s ease;
            display: none;
            position:absolute;
            left:0;
            right:0;
            top:100%;
            &::before {
                @include triangle(0.5rem, map-get($custom-colors, darker), bottom);
                position: absolute;
                top: -1rem;
                left: 2.3125rem;
            }
            ul {
                margin-left: 0;
            }
            > li > a {
              padding-top: 1rem
          }
        }
        &.doble.open {
          > ul {
            width: 38rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              width: 47%;
              &:last-child::after {
                height: 1px;
              }
            }
          }
        }
        &.open {
          > ul {
            display:block;
            z-index: 1;
            opacity: 1;
          }
        }
        button {
          background: transparent;
          border: 0;
          padding: 0;
          vertical-align: middle;
          cursor: pointer;
          > span {
            padding: 10px;
            width: 12px;
            height: 12px;
            display: block;
            box-sizing: content-box;
            &::after {
              content: '';
              display: block;
              width: 12px;
              height: 12px;
              background-image: url(../img/sprites-svg/avall.svg);
              background-repeat: no-repeat;
              .green-header & {
                background-image: url(../img/sprites-svg/ico-desplegable.svg);
              }
            }
          }
          &[aria-expanded="true"] {
            span::after {
              transform: rotate(.5turn);
            }
          }
        }
    }
    @include desktop {
      > ul {
        margin-top: 1.5rem;
      }
    }
}
