.main-slider {
    margin-bottom: 0 !important;
    overflow: hidden;
    .js & {
        // visualització al carregar
        visibility: hidden;
        &.slick-initialized {
            visibility: visible;
        }
    }
    .slick-slide img {
        height: 20rem;
        margin: 0 auto;
        @supports (object-fit: cover) {
            object-fit: cover;
            width: 100%;
            margin: 0;
        }
        @include tablet {
            height: 30rem;
        }
        @include desktop {
            height: 35rem;
        }
    }
    h2 {
        font-size: 3vw;
    }
    //   .is-4 {
    //     padding: 2rem;
    //   }
    @media (max-width: 1000px) {
        .column,
        .columns {
            display: block !important;
            width: 100%;
            margin: 0;
            padding: 0;
        }
        h2 {
            font-size: 1.375rem;
        }
    }
    @media (min-width: 1500px) {
        h2 {
            font-size: 2.5rem;
        }
    }

    .slick-dots {
        bottom: 3.5rem;
        @include desktop {
            text-align: left;
            padding-left: 2rem;
        }
        li {
            width: 1.875rem;
            height: 0.375rem;
            // background-color: map-get($custom-colors, blue-bg-1);
            border: 1px solid map-get($custom-colors, darker);
            &.slick-active {
                background-color: map-get($custom-colors, darker);
            }
        }
        @include mobile {
            position: relative;
            z-index: 10;
        }
        @include tablet-only {
            position: relative;
        }
        @media (max-width: 1000px) {
            bottom: 0;
        }
        button {
            width: 1.875rem;
            margin: 0;
            padding: 0;
        }
    }

    .slick-next,
    .slick-prev {
        z-index: 10;
        height: 30px;
        width: 30px;
        top: auto;
        bottom: 2.5rem;
        &-icon {
            font-size: 30px;
        }
    }

    .slick-next {
        right: $spacing-2;
    }
    .slick-prev {
        left: $spacing-2;
    }
}
