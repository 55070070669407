.headline-list {
  list-style: none;
  padding: 0;

  li {
    border-bottom: 1px solid map-get($custom-colors, dark-light);
    padding-bottom: 1rem;
    span {
      display: block;
      font-size: .9375rem;
    }
  }
}