.banner {
    background-color: #666;
    //background-image: url(../img/banner-usuaris.jpg);
    background-size: cover;
    min-height: 12.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        font-family: $family-secondary;
        color: $white;
        font-size: 1.875rem;
        font-size: 10vw;
        text-align: center;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
    }
    @include tablet {
        min-height: 21.875rem;
        h1 {
            font-size: 2.5rem;
        }
    }
    @include desktop {
        min-height: 31.25rem;
        h1 {
            font-size: 3.75rem;
        }
    }
}
.banner-no-img {
    background-color: #273d3f;
    min-height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        font-family: $family-secondary;
        color: $white;
        font-size: 1.875rem;
        font-size: 10vw;
        text-align: center;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    }
    @include tablet {
        min-height: 8rem;
        h1 {
            font-size: 2.5rem;
        }
    }
    @include desktop {
        min-height: 10rem;
        h1 {
            font-size: 3.75rem;
        }
    }
}
.banner-no-img-no-text {
    background-color: #273d3f;
    min-height: $spacing-2;
    display: flex;
    justify-content: center;
    align-items: center;
 
}
body.usuaris-visitar .banner {
    background-image: url(../img/banner-visitar.jpg);
}
body.usuaris-prova .banner {
    background-image: url(../img/banner-prova.jpg);
}
body.usuaris-urgencies .banner {
    background-image: url(../img/banner-urgencies.jpg);
}
body.usuaris-operar .banner {
    background-image: url(../img/banner-operar-bg.jpg);
}
body.serveis .banner {
    background-image: url(../img/banner-serveis.jpg);
}
body.serveis-anestesia .banner {
    background-image: url(../img/banner-anestesia.jpg);
}
body.serveis-diagnostic-imatge .banner {
    background-image: url(../img/banner-diagnostic-imatge.jpg);
}
body.serveis-dialisi .banner {
    background-image: url(../img/banner-dialisi.jpg);
}
body.professionals .banner {
    background-image: url(../img/banner-professionals.jpg);
}
body.professionals-treballa .banner,
body.professionals-curriculum .banner {
    background-image: url(../img/banner-treballa.jpg);
}
body.professionals-docencia .banner {
    background-image: url(../img/banner-docencia.jpg);
}
body.professionals-recerca .banner {
    background-image: url(../img/banner-recerca.jpg);
}
body.consorci .banner {
    background-image: url(../img/banner-consorci.jpg);
}
body.consorci-missio .banner,
body.consorci-els-centres .banner {
    background-image: url(../img/banner-missio.jpg);
}
body.consorci-centre .banner {
    background-image: url(../img/banner-centre.jpg);
}
body.consorci-portal-transparencia .banner {
    background-image: url(../img/banner-portal.jpg);
}
body.consorci-perfil-contractant .banner {
    background-image: url(../img/banner-contractant.jpg);
}
body.consorci-comunicacio .banner {
    background-image: url(../img/banner-comunicacio.jpg);
}
body.consorci-memoria .banner {
    background-image: url(../img/banner-memoria.jpg);
}
