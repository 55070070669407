
.pagination-list {
    margin-left: 0;
    > * + * {
        margin-top: 0 !important;
      }
    li {
        border-bottom: none !important;
        padding-bottom: 0 !important;
        &:before {
            content: none !important;
        }
    }
}
.pagination-previous, .pagination-next, .pagination-link {
    border-color: #000;
    color: #000;
    font-size: $size-7;
    text-decoration: none;
    border-radius: 0;
    &:hover {
        border-color: $primary;
    }
}
.pagination-link.is-current {
    background-color: #273d3f;
    border-color: #273d3f;
    color: #fff;
}
