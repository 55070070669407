.highlighted-p-2 {
    padding: $size-4;
    background-color: map-get($custom-colors, green-bg);
    //max-width: 50ch;
    font-size: $size-medium-small;
    font-weight: 500;
    text-align: center;
    @include tablet {
      padding: $size-4 24%;
    }
}
