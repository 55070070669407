// mòbil
.hamburger {
    cursor: default;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    @include tablet {
        display: none;
    }
}

#menu {
    @include tablet {
        display: block;
    }
}
