// ample de punta a punta
.full-bleed {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    background-color: $primary-light;
    padding-top: $spacing-4;
    padding-bottom: $spacing-4;
    &--img {
        padding-top: 0;
        padding-bottom: 0;
        img {
            width: 100vw;
            margin-left: calc(50% - 50vw);
            max-width: none;
        }
    }
}
