.mosaic {
  position: relative;
  > div {
    font-family: $family-secondary;
    font-weight: 300;
    font-size: 1.5rem;
    background-color: map-get($custom-colors, green-bg);
    line-height: 1.3;
    padding: 1.875rem;
  }
  @include desktop {
    img {
      width: 80%;
    }
    > div {
      // position: absolute;
      width: 48%;
      padding: 3.75rem;
      // right: 0;
      // bottom: -20%;
      position: relative;
      margin-left: auto;
      margin-top: -9rem;
    }
    &[data-state="reversed"] {
      display: flex;
      justify-content: flex-end;
      > div {
        right: auto;
        left: 0;
      }
    }
  }
  @include widescreen {
    > div {
      font-size: 2.125rem;
    }
  }
}