.highlighted-p-3 {
    padding-left: 1.5rem;
    border-left: solid 4px map-get($custom-colors, blue-bg-1);
    //max-width: 50ch;
    font-size: 1.25rem;
    font-weight: 500;
    @include tablet {
      
    }
}
