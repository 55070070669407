@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Noto-Sans-JP-normal-400.eot);
  src: local('Noto Sans JP'), url(../../fonts/Noto-Sans-JP-normal-400.woff) format('woff'), url(../../fonts/Noto-Sans-JP-normal-400.eot?#iefix) format('embedded-opentype'), url(../../fonts/Noto-Sans-JP-normal-400.svg#NotoSansJP) format('svg'), url(../../fonts/Noto-Sans-JP-normal-400.otf) format('opentype'), url(../../fonts/Noto-Sans-JP-normal-400.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url(../../fonts/Noto-Sans-JP-normal-500.woff) format('woff'), url(../../fonts/Noto-Sans-JP-normal-500.otf) format('opentype'), url(../../fonts/Noto-Sans-JP-normal-500.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Noto-Sans-JP-normal-700.woff) format('woff'), url(../../fonts/Noto-Sans-JP-normal-700.otf) format('opentype'), url(../../fonts/Noto-Sans-JP-normal-700.woff2) format('woff2');
  font-display: swap;
}

