.custom-list-2 {
  list-style: none;
  padding: 0;
  
  li {
    position: relative;
    background-color: $white;
    padding: .875rem 1.5625rem .875rem 2.1875rem;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: .9375rem;
      top: .9375rem;
      width: .5625rem;
      height: 1.358125rem;
      background: url(../img/custom-list-2-bg.png);
    }
    &.clic {
      cursor: pointer;
    }
    p {
      font-size: .9375rem;
    }
  }
}
.ofertes {
  a {
    span {
      display: block;
      max-width: 700px;
    }
  }
}