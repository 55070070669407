.dropdown-cerca {
    position: relative;
    .dropdown-trigger {
        cursor: pointer;
        background-color: transparent;
        border: none;
        .icon {
            width: 29px;
        }
        button {
            background-color: transparent;
            border: 0;
            background-image: url('../img/sprites-svg/cerca.svg');
            background-repeat: no-repeat;
            color: transparent;
            width: 27px;
            height: 27px;
        }
    }

    .dropdown-menu {
        // display: none;
        position: absolute;
        z-index: 100;
        right: -120px;
        top: 2.8rem;
        background-color: map-get($custom-colors, blue-bg-1);
        // padding: 4rem 2rem 2rem;
        padding: 2rem;
        min-width: 25rem;
        @include tablet {
            right: 0;
            top: 2.85rem;    
        }
        @include desktop {
            top: 3.15rem;    
        }
        // .tancar {
        //     position: absolute;
        //     right: 1.5rem;
        //     top: 1rem;
        // }
        button {
            margin-left: $spacing-1;
            background-color: transparent;
            border-radius: 0;
        }
    }
}

// .dropdown-cerca.is-active {
//   .dropdown-menu {
//     display: block;
//   }
// }
