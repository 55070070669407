.ofertes__estat {
    position: relative;
    //padding: 5px;
    color: #273d3f;
    font-size: $size-7 !important;
    border-radius: 8px;
    margin-left: 30px;
    margin-top: 5px;
    &:before {
        content: '';
        height: 25px;
        width: 25px;
        border-radius: 50%;
        position: absolute;
        display: inline-block;
        left: -30px;
        top: -3px;
    }
    @include tablet {
        position: absolute;
        top: $spacing-2;
        right: $spacing-4;
        
    }
}
.ofertes__estat--1 {
    &:before {
    background-color: #c00;
    }
}
.ofertes__estat--2 {
    &:before {
    background-color: #d5692a;
    }
}
.ofertes__estat--0 {
    &:before {
    background-color: #76ac37;
    }
}
.ofertes__estat--3 {
    &:before {
    background-color: #0bc!important;
    }
}
.ofertes {
    li {
        position: relative;
    }
}
