.card-xxss {
    padding: 1.375rem;
    background-image: url(../img/share.svg);
    background-repeat: no-repeat;
    background-position: right 20px top 20px;
    background-color: $white;
    ul {
        padding-left: 0;
        margin-top: $size-2;
        li {
            display: inline-block;
            padding-right: $size-2;
            a {
                opacity: .8;
            }
        }
    }
  }
