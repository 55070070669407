// Ho fem servir quan necessitem paddings o margins percentuals
$sizes: (
  5,
	10,
  15,
	20,
  25,
	30,
  35,
	40,
  45,
	50
);
$positions: (
	"top",
	"left",
	"bottom",
	"right"
);
$percentSizes: (
  5%,
	10%,
  15%,
	20%,
  25%,
  30%,
  35%,
  40%,
  45%,
  50%
);
$i: 1;
@each $size in $sizes {
	$sizee: nth($percentSizes, $i);
	$i: $i+1;
	.pp-#{$size} {
		padding: $sizee !important;
	}
	.pm-#{$size} {
		margin: $sizee !important;
	}
  @include tablet {
    .pp-#{$size}-tablet {
      padding: $sizee !important;
    }
    .pm-#{$size}-tablet {
      margin: $sizee !important;
    }
  }
	@each $position in $positions { 
		.pp-#{$position}-#{$size} {
			padding-#{$position}: $sizee !important;
		}
		.pm-#{$position}-#{$size} {
			margin-#{$position}: $sizee !important;
		}
    @include tablet {
      .pp-#{$position}-#{$size}-tablet {
        padding-#{$position}: $sizee !important;
      }
      .pm-#{$position}-#{$size}-tablet {
        margin-#{$position}: $sizee !important;
      }
    }
	}
}