.p-dni {
    position: relative;
    font-weight: 500!important;
    padding-left: $spacing-5;
    &:before {
        content: '';
            display: block;
            background-image: url("../img/sprites-svg/dni.svg");
            width: 20px;
            height: 22px;
            position:absolute;
            left: 0;
    }
}
