.wrapper {
  max-width: 105rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing-4;
  padding-right: $spacing-4;

  &--narrow {
    max-width: 81rem;
  }

  &--narrowest {
    max-width: 56.5625rem;
  }
}