.banner-jornades {
  overflow: hidden;
  .column div {
    padding: 1rem;
  }
  h1 {
    font-family: $family-secondary;
    font-weight: 300;
    font-size: 6vw;
    margin-bottom: 1.6875rem;
  }
  @include tablet {
    h1 {
      font-size: 3vw;
    }
  }
  
}