.fil-ariadna {
  list-style: none;
  padding: .3125rem 1.5rem;
  li {
    font-family: $family-secondary;
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    background: url(../img/ariadna-bg.png) no-repeat left center;
    padding-left: .9375rem;
    &:last-child a:hover {
      text-decoration: none;
      cursor: default;
    }
  }
  @media (min-width: 768px) {
    li {
      display: inline-block;
      background: transparent;
      padding-left: 0;
    }
    li + li {
      padding-left: 15px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: .9375rem;
        height: 1.125rem;
        background: url(../img/ariadna-bg.png);
      }
    }
  }
  .cercador & {
    padding-left: 0;
    margin-top: $spacing-2;
  }
}