.card-news {
  transition: all 300ms ease-in-out;
  padding: 1rem;
  img {
    margin-bottom: 1.25rem;
    & + div {
      font-size: .9375rem;
      color: map-get($custom-colors, dark);
      margin-bottom: .625rem;
    }
  }
  h3 {
    padding-bottom: 1.25rem;
  }
  &:hover {
    background-color: $white;
    cursor: pointer;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
  }
  
}