.logos-footer {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 670px;
  a {
    display: block;
    padding: .5rem
  }
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
    li {
      a {
        display: block;
        padding: .5rem
      }
    }
  }
}
