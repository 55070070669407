.list-arrow {
    list-style: none;
    padding-left: 0;
    li {
        position: relative;
        padding-left: $spacing-3;
        &:before {
            content: '';
            display: block;
            background-image: url("../img/sprites-svg/fletxa.svg");
            width: 14px;
            height: 13px;
            position:absolute;
            left: 0;
            top:6px;
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
