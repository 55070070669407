table {
  width: 100%;
  background-color: #fff;
  color: #363636;
  thead {
    background-color: transparent;
  }
  th, td {
    font-family: $family-secondary;
    font-size: $size-6;
    border: 1px solid #dbdbdb;
    color: #363636;
    padding: .5em .75em;
    vertical-align: top;
    text-align: left;
  }
}
.cal {
  background-color: transparent;
  color: #fff !important;
  th, td {
    border: 0;
    color: #fff !important;
    padding: 3px;
  }
}
