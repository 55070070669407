.tab-menu {
  .js & {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      flex-grow: 1;
      padding: 0;
      margin: 0;
      &:last-child {
        margin-right: 0;
      }
    }

    a {
      text-decoration: none;
      font-family: $family-secondary;
      display: block;
      padding: 1.5625rem 1rem;
      background-color: map-get($custom-colors, darker);
      color: $white;
      font-size: $size-7;

      &:hover,
      &[aria-selected] {
        // outline: 0;
        background-color: map-get($custom-colors, green-bg);
        color: map-get($custom-colors, darker);
      }
      &:focus {
        outline: 2px dotted map-get($custom-colors, dark) !important;
        outline-offset: 1px;
      }
      @include desktop {
        font-size: $size-5;
      }
    }
  }
}

.tab-panel {
  .js & {
    margin-top: 0;
  }

  // Estils pels elements de text dins el panel
  h2 {
    font-size: $size-4;
  }

  p,
  ul,
  ol {}
}

// Pestanyes en mòbil
@include mobile {
  .js .tab-menu {
    display: block;

    li {
      display: block;
      margin-right: 0;
      margin-top: 1px;

      &:first-child {
        margin-top: 0;
      }
    }

    // [aria-selected] {
    //     outline: 0;
    // }
  }
}

// Pestanyes verticals
.js .tabbed--vertical {
  @include tablet {
    display: flex;

    .tab-menu {
      display: block;
      flex: 1 0 25%;

      li {
        display: block;
        margin: 0 0 $spacing-2 0;
      }

      a {
        display: block;
      }
    }
  }
}
