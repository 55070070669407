.custom-list-1 {
  list-style: none;
  padding: 0;
>*+* {
    margin-top: #{get-size("2")};
    }
  li {
    position: relative;
    padding-left: 1.25rem;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 12px;
      width: .6875rem;
      height: .25rem;
      background-color: map-get($custom-colors, primary-brand-1);
    }
  }
  strong:first-of-type {
    display: block;
  }
}
.custom-list-1-4col {
  
  @include tablet {
    column-count: 2;
  }
  @include desktop {
      column-count: 4;
  }
}