[class^="a-cols-list"] {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles */
        list-style: none;    
    }
    li {
        flex: none;
        padding: 0.75rem;
        width: 100%;
        p, .box {
            height: 100%;
            background-color: $white;
            padding: $size-5;
            font-weight: 600;
            border: 1px solid #dbdbdb;
            &:hover {
                background-color: map-get($custom-colors, blue-bg-1);
                cursor: pointer;
                border-color: map-get($custom-colors, blue-bg-1);
            }
        }
        a {
            display: block;
            text-decoration: none;
        }
        .events-list & {
            p {
                height: auto;
                background-color: transparent;
                font-weight: 400;
                border: none;
                padding: 0;
            }
            a {
                display: inline-flex;
            }
        }
        
    }
    ::marker {
        content: "";
        font-size: 0; /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */
    }
}
// 2 columnes
.a-cols-list-2 li {
    @include tablet {
        width: 50%;
    }
}
// 3 columnes
.a-cols-list-3 li {
    @include tablet {
        width: 33.333%;
    }
}
// 4 columnes
.a-cols-list-4 li {
    @include tablet {
        width: 33.333%;
    }
    @include desktop {
        width: 25%;
    }
}
// 5 columnes
.a-cols-list-5 li {
    @include desktop {
        width: 20%;
    }
}
