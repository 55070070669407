.a-cols-list-4 li .card-list-img {
  text-align: center;
  p {
    background: none;
    border: 0;
    font-weight: normal;
    padding: 0;
    &:hover {
      cursor: auto;
    }
  }
  h3 {
    padding-bottom: 1.125rem;
  }
  a {
    display: inline-flex;
    margin-top: 1.125rem;
  }
}
