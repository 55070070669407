.card-destacats {
    & > div {
        height: 13.125rem;
        //background-image: url(../img/destacat-portal.jpg);
        background-color: #666;
        background-size: cover;
        padding: 1.1875rem;
        font-size: 2rem; 
        font-weight: bold;
        color: $white;
        line-height: 1;
        @include tablet {
            font-size: $size-4;    
        }
        @include desktop {
            font-size: 2rem;
        }
    }
    a {
        -webkit-text-decoration: transparent;
        text-decoration: transparent;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
}
