.highlighted-list {
    padding-left: 0;
  li {
    display: flex;
    background-color: #fff;
    padding: $size-5;
    font-weight: 500;
  }
  >*+* {
    margin-top: #{get-size("1")};
    }
  strong:first-child {
      flex: 0 0 11rem;
      background-color: map-get($custom-colors, blue-bg-1);
      padding: $size-5;
      font-size: 1.375rem;
      font-weight: 700;
      margin: -$size-5;
      margin-right: $size-5;
      font-family: $family-secondary;
  }
}
