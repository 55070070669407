html {
    scroll-behavior: smooth;
}
body {
    line-height: 1.5;
    overflow-x: hidden;
}
:root {
    font-variant-ligatures: normal;
  }
ah1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    font-family: $family-secondary;
}

h1 {
    font-size: $size-2;
    // color: $secondary;
    // font-family: $family-secondary;
}

h2 {
    font-size: $size-3;
    font-weight: 300;
    // &:not(:first-child) {
    //   margin-top: $size-3 !important;
    // }
}

h3 {
    font-size: $size-4;
    font-weight: normal;
}

h4 {
    font-size: $size-5;
    margin-top: $spacing-2;
}
h5 {
    font-size: $size-5;
    font-weight: normal;
    margin-bottom: 0;
}
h6 {
    font-size: $size-6;
    margin-bottom: 0;
}

ul,
ol {
    margin: 0;
}
ul {
    list-style-type: square;
}

ol {
    list-style: none;
    counter-reset: adhoc-custom;
    li {
        counter-increment: adhoc-custom;
        &::before {
            content: counter(adhoc-custom) ". ";
            font-weight: bold;
            font-size: 1.25rem;
        }
    }
    .collapsible & {
        background-color: #eff3f3;
        padding-top: $spacing-3;
        padding-bottom: $spacing-3;
    }
}

table {
    border-collapse: collapse;
}

strong {
    font-weight: 500;
}

// ul:not([role]),
// ol:not([role]) {
//   padding-left: 0;
//   margin-left: $size-4;
//   > * + * {
//     margin-top: $size-5;
//   }
// }

// ul:not([role]) {
//   list-style-position: inside;
//   ::marker {
//     content: "";
//     font-size: 0; /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */
//   }
//   li {
//     padding-left: $size-6;
//     position: relative;
//     &::before {
//       content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgNWg1VjBIMHY1em01IDVoNVY1SDV2NXptNSAwdjVINXY1SDB2NWg1di01aDV2LTVoNXYtNWgtNXoiIGZpbGw9IiMxMTc5NzkiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==");
//       display: block;
//       position: absolute;
//       left: 0;
//       top: 0;
//       height: 25px;
//       width: 15px;
//     }
//   }
//   ul {
//     margin-top: get-size("4");
//     margin-left: 0;
//     font-size: get-size("3");
//     li {
//       padding-left: get-size("5");
//       &::before {
//         content: "";
//         top: 0.75em;
//         width: get-size("3");
//         height: 1px;
//         background-color: #000;
//       }
//     }
//     > * + * {
//       margin-top: get-size("3");
//     }
//   }
// }

// ol:not([role]) {
//   list-style: decimal;
//   list-style-position: inside;
// li {
// border-bottom: 1px solid get-color("grey2");
// padding-bottom: get-size("300");
// &::marker {
//     @include apply-utility("weight", "bold");
//     font-size: get-size("600");
// }
// }
// }

a {
    // color: currentColor;
    // color: $link-dark;
    color: inherit;
    transition: -webkit-text-decoration-color 0.1s ease-in;
    transition: text-decoration-color 0.1s ease-in;
    transition: text-decoration-color 0.1s ease-in, -webkit-text-decoration-color 0.1s ease-in;
    word-break: break-word;
    &:hover {
        -webkit-text-decoration-color: transparent;
        text-decoration-color: transparent;
    }
}

hr {
    border: 0;
    border-top: 1px solid $border;
}

// address {
//   font-style: normal;
// }

abbr {
    text-decoration: none;
}

//  :focus {
//   /*https://matthiasott.com/notes/focus-visible-is-here*/
//    outline: 2px dotted;
//    outline-offset: 0.25rem;
//    &:not(:focus-visible) {
//      outline: 0;
//    }
//    &:focus-visible {
//      outline: 2px dotted map-get($custom-colors, dark);
//      outline-offset: 0.25rem;
//    }
//  }


*:focus { 
    outline: 2px dotted map-get($custom-colors, dark) !important;
    outline-offset: 1px !important;
    box-shadow: none !important;
    .has-background-primary-dark & {
        outline-color: #000 !important;
    }
    .has-background-darker & {
        outline-color: #fff !important;
    }
}
*:focus:not(:focus-visible) {
 /* undo all the above focused button styles if the button has focus but the browser wouldn't normally show default focus styles */
 outline: none !important;
}
*:focus-visible { 
    outline: 2px dotted map-get($custom-colors, dark) !important;
    outline-offset: 1px !important;
    box-shadow: none !important;
    .has-background-primary-dark & {
        outline-color: #000 !important;
    }
    .has-background-darker & {
        outline-color: #fff !important;
    }
}

// strong {
//   @include apply-utility("weight", "bold");
// }
// em {
//   @include apply-utility("style", "italic");
// }
// em > strong,
// strong > em {
//   @include apply-utility("weight", "bold");
//   @include apply-utility("style", "italic");
// }

figcaption {
    font-size: $size-7;
    margin-top: $spacing-1;
}

img {
    height: auto;
}

fieldset {
    border: 0;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
}
legend {
    font-size: $size-4;
}

// summary {
//   letter-spacing: 1px;
//   padding: get-size("300") * 0.75 get-size("400");
//   position: relative;
//   &::marker {
//     content: "";
//   }
//   /*Safari: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details#customizing_the_disclosure_widget*/
//   &::-webkit-details-marker {
//     display: none;
//   }
//   &::after {
//     content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDIwIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNOS45OTggMTEuNzU0TDEwLjAwMSAxMS43NTYgMTIuNDMxIDkuMzI2IDEyLjk2MyA4Ljc4OSAxOS4xMzEgMi42MjEgMTYuNyAwLjE5IDEwLjAwMiA2Ljg4OCAzLjMwNCAwLjE5IDAuODczIDIuNjIxIDcuNTY5IDkuMzIyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExNDggLTIzMDQpIHRyYW5zbGF0ZSgxMTQ4IDIzMDQpIi8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
//     position: absolute;
//     right: get-size("400");
//     top: 50%;
//     transform: translateY(-50%);
//   }
//   + div {
//     padding: get-size("300") get-size("400") get-size("400");
//     > ul:not([role]) {
//       margin-left: 0;
//       > li {
//         padding-left: get-size("400");
//         &::before {
//           content: "";
//           width: get-size("300") * 0.5;
//           height: get-size("300") * 0.5;
//           background-color: get-color("primary");
//           top: 0.4em;
//         }
//       }
//     }
//   }
// }
// details {
//   border: 2px solid get-color("black");
//   &[open] {
//     summary::after {
//       content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDIwIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNOS45OTggMTEuNzU0TDEwLjAwMSAxMS43NTYgMTIuNDMxIDkuMzI2IDEyLjk2MyA4Ljc4OSAxOS4xMzEgMi42MjEgMTYuNyAwLjE5IDEwLjAwMiA2Ljg4OCAzLjMwNCAwLjE5IDAuODczIDIuNjIxIDcuNTY5IDkuMzIyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExNDggLTIzNzkpIHJvdGF0ZSgtMTgwIDU4NCAxMTk1LjUpIi8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
//     }
//     * {
//       font-size: get-size("300");
//     }
//   }
// }

// A11y toggle (http://edenspiekermann.github.io/a11y-toggle/)
[aria-hidden="true"],
[data-a11y-toggle]:not([aria-controls]) {
    display: none;
}

// Excepcions (https://github.com/edenspiekermann/a11y-toggle/commit/74d9d7ff3fe98dd78f8ae96f08595691ea52c329)
/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.gm-style [aria-hidden="true"],
.slider [aria-hidden="true"],
.icon[aria-hidden="true"] {
    /* 1 */
    display: block; /* 2 */
    display: initial;
}
