.flow {
    > * + * {
        margin-top: #{get-size("2")};
    }

    // variants d'espai
    &--1 > * + * {
        margin-top: #{get-size("1")};
    }
    &--3 > * + * {
        margin-top: #{get-size("3")};
    }
    &--4 > * + * {
        margin-top: #{get-size("4")};
    }
    &--5 > * + * {
        margin-top: #{get-size("5")};
    }
    &--6 > * + * {
        margin-top: #{get-size("6")};
    }
    &--7 > * + * {
        margin-top: #{get-size("7")};
    }
}
